import AppConfig from '../AppSettings';  
import { DecryptString } from '../components/GeneralFunctions';

export const GetRestaurantInfobyName = async (name) => {
    try {  
        const cachedData = sessionStorage.getItem(`restaurantInfo_${name}`);
        if (cachedData) {
            // Return cached data if available
            return JSON.parse(cachedData);
        }
        const response = await fetch(AppConfig.apiUrl + `/Rest/GetAllInfoMenu?argStrRestName=`+name, { 
            method: 'POST',  
             headers: { 
                 'Content-Type': 'application/json'  
             },
         }); 
          
        const result = await DecryptString(await response.text()); 
        const jsonData = JSON.parse(result); 

        sessionStorage.setItem(`restaurantInfo_${name}`, jsonData);
        return jsonData; 

    } catch (error) {
        console.log("error", error)
        return error
    }
}

export const UpdateMyRestaurant = async (data) => { 
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Rest/UpdateRest`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    }
    catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const GetMyRestaurant = async (data) => { 
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Rest/GetMyRestaurant`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json(); 
        return result;
    }
    catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}