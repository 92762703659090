import AppConfig from '../AppSettings';

export const GetOwnlookUp = async (data) => {
    const token = localStorage.getItem("jwt");
    try { 
        const response = await fetch(AppConfig.apiUrl + `/OwnLookUp/GetOwnlookUp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
} 

export const GetCodeSymbol = async (data) => { 
    try { 
        const cacheKey = `GetCodeSymbol_${JSON.stringify(data)}`;

        // Check if the data is already in the cache
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
            // Return cached data if available
            return JSON.parse(cachedData);
        }

        const response = await fetch(AppConfig.apiUrl + `/OwnLookUp/GetCodeSymbol`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // Store the result in the sessionStorage cache
        sessionStorage.setItem(cacheKey, JSON.stringify(result));
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        } 
        return result;
    }
}

export const UpdateOwnlookUp = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/OwnLookUp/UpdateOwnlookUp`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
} 

