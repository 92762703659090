import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState, useRef, memo, forwardRef } from 'react';
import AppConfig from '../AppSettings';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export default function ModalImage(props) {

    const Transition = memo(forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    }));
    const [ImageDesc, setImageDesc] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const imageRef = useRef(null);
    const CloseImgPopUp = () => {
        props.HandleCloseImage();
    };
    const [zoomButtonPressed, setZoomButtonPressed] = useState(false);

    //const handleCloseModal = () => {
    //    setModalOpen(false);
    //    setZoomLevel(1);
    //}; 
    const handleZoomIn = () => {
        setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 2)); // Limit zoom to 2x
    };

    const handleZoomOut = () => {
        setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); // Limit zoom to 1x
    };


    const calculateMaxZoom = () => {
        const container = imageRef.current.parentNode;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const imageWidth = imageRef.current.width;
        const imageHeight = imageRef.current.height;

        const maxZoomWidth = containerWidth / imageWidth;
        const maxZoomHeight = containerHeight / imageHeight;

        return Math.min(maxZoomWidth, maxZoomHeight);
    };

    useEffect(() => {
        setModalOpen(props?.OpenModal);
        setImageDesc(props?.ImageDesc)
    }, [])

    const ZoomControls = memo(({ onZoomIn, onZoomOut, zoomLevel, onCloseImgPopUp }) => (
        <div className="zoom-controls">
            <Button onClick={onZoomIn} color="primary" disabled={zoomLevel >= 2}>
                <ZoomInIcon />
            </Button>
            <Button onClick={onZoomOut} color="primary" disabled={zoomLevel <= 1}>
                <ZoomOutIcon />
            </Button>
            <Button onClick={onCloseImgPopUp} color="secondary" endIcon={<CloseOutlinedIcon />}>
                Close
            </Button>
        </div>
    ));

    const EnterStringStyle = {
        whiteSpace: 'pre-line',
        textAlign: 'center',
    };
    return (
        <Dialog
            TransitionComponent={Transition}
            open={isModalOpen}
            onClose={CloseImgPopUp} >
            <br/>
            <p style={EnterStringStyle} className="ModalImageText">{ImageDesc} </p>
            <DialogContent className="modal-content" >
                {/* Your zoomable content goes here */}
                <img
                    ref={imageRef}
                    src={props?.ImageURL}
                    alt="Your Image"
                    className="CoverImageMax"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left' }} />
            </DialogContent>
            <ZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} zoomLevel={zoomLevel} onCloseImgPopUp={CloseImgPopUp} />


        </Dialog>

    )
}