import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@material-ui/data-grid';
import { GetComments, DeleteComments } from '../apis/CommentsAPI';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { Helmet } from 'react-helmet'; 
import { Link, useHistory, useLocation } from "react-router-dom"; 
import { Select_Cat_SubCat_Item_COUNT, GetStopDate } from '../apis/DashboardAPI';
import moment from 'moment';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import RestaurantOutlinedIcon from '@material-ui/icons/RestaurantOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import DetailsOutlinedIcon from '@material-ui/icons/DetailsOutlined';
import ScatterPlotOutlinedIcon from '@material-ui/icons/ScatterPlotOutlined';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

export default function BasicTable() {
     
    const [Cat_SubCat_Item_COUNT, setCat_SubCat_Item_COUNT] = useState([])
    const [remainingDays, setRemainingDays] = useState(null);
    const [targetDate, settargetDate] = useState(null);

    const history = useHistory();
     

    //moving the logic to calculate remainingDays inside the useEffect cause sometimes it return null . so by 2 useEffect the second one will run./ don't merge them in one useeffect
    useEffect(() => {
        async function fetchData() {
            const CountsReposnse = await Select_Cat_SubCat_Item_COUNT(localStorage.getItem("RestaurantId"));
            const DateReposnse = await GetStopDate(localStorage.getItem("RestaurantId"));

            setCat_SubCat_Item_COUNT(CountsReposnse);
            settargetDate(DateReposnse);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (!targetDate) return; // Ensure targetDate is set

        const parsedTargetDate = moment(targetDate);
        const today = moment();
        const daysDiff = parsedTargetDate.diff(today, 'days');
        setRemainingDays(daysDiff);
    }, [targetDate]); // Calculate remainingDays when targetDate changes


    // Function to determine CSS class based on remaining days
    const getLabelClassName = () => {
        if (remainingDays !== null && remainingDays < 20) {
            return "remaining-days-label remaining-days-label-red";
        }
        return "remaining-days-label remaining-days-label-green";
    };

    // Function to determine CSS class based on remaining days
    const getDaysCountClassName = () => {
        if (remainingDays !== null && remainingDays < 20) {
            return "days-count days-count-red";
        }
        return "days-count days-count-green";
    }; 

    const Square = ({ value, label, color, onClick ,icon: Icon }) => (
        <div style={{ ...styles.square, backgroundColor: color }}
            onClick={onClick}>  
            <div style={styles.value}>    {Icon && <Icon style={styles.icon} />} {/* Render the icon if provided */}{value} </div>
            <div style={styles.label}>{label}</div>
        </div>
    );

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            marginTop: '20px',
        },
        square: {
            width: 'calc(20% - 80px)', // Adjust size based on the number of squares per row
            minWidth: '150px',
            maxWidth: '250px',
            minHeight:'100px',
            margin: '10px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            cursor: 'pointer', // Change cursor to pointer to indicate clickable element
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0)', // Initial shadow

        },
        value: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        label: {
            fontSize: '16px',
        },
        icon: { 
            color: '#fff', // Set icon color to white
        },
    };

    // Add hover effect styles
    styles.square['&:hover'] = {
        backgroundColor: '#555', // Change background color on hover
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', // Add shadow effect on hover
    };

    const RedirectFct = (route) => { 
        history.push(route); 
    };
    return (
        <div>
            <Helmet>
                <title>Dashboard</title>
            </Helmet> 
            <div style={styles.container}>
                <Square value="Views" label={Cat_SubCat_Item_COUNT?.ViewsCount} color="#4CAF50" icon={VisibilityOutlinedIcon} onClick={() => RedirectFct('/DashboardViews')} />
                <Square value={"Categories"} icon={CategoryOutlinedIcon} label={Cat_SubCat_Item_COUNT?.CatCount} color="#2196F3" onClick={() => RedirectFct('/Categories')} ></Square>
                <Square value={"Sub-Categories"} icon={ScatterPlotOutlinedIcon} label={Cat_SubCat_Item_COUNT?.SubCatCount} color="#FF9800" onClick={() => RedirectFct('/Sub-Categories')} ></Square>
                <Square value={"Items"} icon={DragIndicatorOutlinedIcon}  label={Cat_SubCat_Item_COUNT?.ItemCount} color="#E91E63" onClick={() => RedirectFct('/ItemsUI')} ></Square>
                <Square value={"Comments"} icon={FeedbackOutlinedIcon} label={Cat_SubCat_Item_COUNT?.CommentsCount} color="#9C27B0" onClick={() => RedirectFct('/DisplayComments')} ></Square>
            </div> 
            <div className="remaining-days-container"> 
                {remainingDays !== null && (
                    <div className="remaining-days">
                        <TimerOutlinedIcon className="duration-icon" />
                        <span className={getLabelClassName()}>
                            Days remaining until license renewal {moment(targetDate).format('YYYY-MM-DD')}
                        </span>
                        <span className={getDaysCountClassName()}>{remainingDays} Days</span>
                    </div>
                )}
            </div> 
        </div>
    );
}