import axios from 'axios'
import AppConfig from '../AppSettings';


export const InsertDashboard = async (data) => { 
    try {
        const response = await fetch(AppConfig.apiUrl + `/DashboardViews/InsertDashboard`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const SelectViewsPerDay = async (data) => {
    try {

        const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl + `/DashboardViews/SelectViewsPerDay`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
} 