import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
    gridCheckboxSelectionColDef,
} from '@material-ui/data-grid';
import { GetCategorys, DeleteImage } from '../apis/CategoriesApis';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import DialogRestInfo from '../components/DialogRestInfo';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import AppConfig from '../AppSettings';
import TextField from '@material-ui/core/TextField';
import { GetRestInfoCMS, InsertRestInfo, UpdateRestInfo, DeleteRestInfo } from '../apis/RestInfoAPI';
import { DeleteRestInfoDesc, GetRestInfoDescCMS, InsertRestInfoDesc } from '../apis/RestInfoDescAPI';
import { DeleteRestInfoWifi, GetRestInfoWifiCMS, InsertRestInfoWifi } from '../apis/RestInfoWifiAPI';
import { GetRestInfoDayTimeCMS, InsertRestInfoDayTime, UpdateRestInfoDayTime, DeleteRestInfoDayTime } from '../apis/RestInfoDayTimeAPI';
import DialogForRestInfoDayTime from '../components/DialogForRestInfoDayTime';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { UpdateOwnlookUp } from '../apis/OwnLookUpAPI';
import { GetCodeSymbol } from '../apis/OwnLookUpAPI';

const restructureData = (data) => {
    return data?.map((structure) => {
        structure.Image = {
            id: (structure.RestInfoFileName ? structure.RestInfoId : null),
            uri: AppConfig.ImageUrl + "/" + structure.RestInfoFileName
        };
        structure.edit = structure.RestInfoId;
        structure.delete = structure.RestInfoId;
        structure.deleteimage = structure.RestInfoId;
        return structure;
    })
}
const restructureDataRestInfoDayTime = (data) => {
    return data?.map((structure) => {
        structure.edit = structure.RestInfoDayTimeId;
        structure.delete = structure.RestInfoDayTimeId;
        structure.deleteimage = structure.RestInfoDayTimeId;
        return structure;
    })
}
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const useToolBarStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function BasicTable() {
    const classes = useStyles();
    const ToolBarStylesClasses = useToolBarStyles();

    function CustomToolbar({ ArrayOfIds }) {
        return (
            <GridToolbarContainer className={ToolBarStylesClasses.root}>
                <Button style={{ color: green[500] }} startIcon={<AddIcon />} onClick={handleClickOpen}>Add</Button>
                {/* <GridToolbarColumnsButton /> */}
                <GridToolbarFilterButton />
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }
    function CustomToolbarDayTime({ ArrayOfIds }) {
        return (
            <GridToolbarContainer className={ToolBarStylesClasses.root}>
                <Button style={{ color: green[500] }} startIcon={<AddIcon />} onClick={handleClickOpenDayTime}>Add</Button>
                {/* <GridToolbarColumnsButton /> */}
                <GridToolbarFilterButton />
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const [dataImages, setData] = useState([]);
    const [selectedRestinfo, setselectedRestinfo] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSnackbar, setopenSnackbar] = useState(false);
    const [RestInfoId_Edit, setRestInfoId_Edit] = useState("")
    const [Msg, setMsg] = useState("")
    const [ErrMsg, setErrMsg] = useState("")
    const [SnackBarColor, setSnackBarColor] = useState("green")
    const [OpenEditModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(true);
    const [RestInfoDesc, setRestInfoDesc] = useState("")
    const [RestInfoWifiInfo, setRestInfoWifiInfo] = useState("")
    const [RestInfoDayTime, setRestInfoDayTime] = useState([])

    const [openingHours, setOpeningHours] = useState([]);
    const [day, setDay] = useState('');
    const [openingHour, setOpeningHour] = useState('');
    const [closingHour, setClosingHour] = useState('');
    const [RestInfoDayTimeId_Edit, setRestInfoDayTimeId_Edit] = useState("")
    const [selectedRestinfoDayTime, setselectedRestinfoDayTime] = useState([]);
    const [openDayTime, setOpenDayTime] = useState(false);
    const [openSnackbarDayTime, setopenSnackbarDayTime] = useState(false);
    const [OpenEditModalDayTime, setOpenModalDayTime] = useState(false)
    const [checkboxState, setCheckboxState] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
    });

    const handleCloseEditModal = () => {
        setOpenModal(false);
    };
    const handleClickOpenEditModal = () => {
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseSnackbar = () => {
        setopenSnackbar(false);
    };

    const handleOpenSnackbar = (strMsg) => {
        setopenSnackbar(true);
        setMsg(strMsg)
    };


    const handleCloseEditModalDayTime = () => {
        setOpenModalDayTime(false);
    };
    const handleClickOpenEditModalDayTime = () => {
        setOpenModalDayTime(true);
    };
    const handleCloseDayTime = () => {
        setOpenDayTime(false);
    };
    const handleClickOpenDayTime = () => {
        setOpenDayTime(true);
    };

    const handleCloseSnackbarDayTime = () => {
        setopenSnackbarDayTime(false);
    };

    const handleOpenSnackbarDayTime = (strMsg) => {
        setopenSnackbarDayTime(true);
        setMsg(strMsg)
    };

    useEffect(() => {
        getRestInfoAPI({});
        getRestInfoDescWifi({});
        getRestInfoDescAPI({});
        getRestInfoDayTimeAPI({});
        GetSettingsOwnLookUpToShowAndhideComponents();
    }, [])


    const getRestInfoDayTimeAPI = async () => {
        var result = await GetRestInfoDayTimeCMS({
            "RestId": localStorage.getItem("RestaurantId")
        });
        const CnstErrorMsg = result?.Error?.Message;
        if (CnstErrorMsg)
            handleOpenSnackbarDayTime("Failed to load data, " + result?.Error?.Message)

        setErrMsg(result?.Error?.Message)

        if (result?.Dt) {
            const StructuredData = await restructureDataRestInfoDayTime(result?.Dt);
            setRestInfoDayTime(StructuredData);

        }
        setLoading(false);

    }
    const getRestInfoDescWifi = async () => {
        var result = await GetRestInfoWifiCMS({
            "RestId": localStorage.getItem("RestaurantId")
        }); 
        if (result?.Dt && result?.Dt[0]) {  
            setRestInfoWifiInfo(result?.Dt[0]?.RestInfoWifiText)
        }
    }
    const getRestInfoDescAPI = async () => {
        var result = await GetRestInfoDescCMS({
            "RestId": localStorage.getItem("RestaurantId")
        });
        if (result?.Dt && result?.Dt[0]) {
            setRestInfoDesc(result?.Dt[0]?.RestInfoDescText)
        }
    }


    const getRestInfoAPI = async () => {
        var result = await GetRestInfoCMS({
            "RestId": localStorage.getItem("RestaurantId")
        });


        const CnstErrorMsg = result?.Error?.Message;
        if (CnstErrorMsg)
            handleOpenSnackbar("Failed to load data, " + result?.Error?.Message)

        setErrMsg(result?.Error?.Message)
        if (result?.Dt)
            setData(restructureData(result?.Dt));
        setLoading(false);
    }
    const DeleteRestinfoImageFromGird = async RestInfoId => {
        const Confirmation = window.confirm("Do you want to Delete image?");
        if (Confirmation === true) {
            setLoading(true);
            const ImgId = (dataImages.find(o => o.RestInfoId === RestInfoId)?.Image.id);
            if (ImgId) {
                await DeleteImage({ RelatedId: ImgId, RelatedType: "RestInfo" });
                getRestInfoAPI();
                handleOpenSnackbar("RestInfo Image Deleted");
            }
            setLoading(false);
        }
    }
    const ButtonUpdateRestInfoImages = async RestInfoID => {
        handleClickOpenEditModal();
        setRestInfoId_Edit(RestInfoID);
    }

    const ButtonUpdateRestInfoDayTime = async RestInfoID => {
        handleClickOpenEditModalDayTime();
        setRestInfoDayTimeId_Edit(RestInfoID);
    }
    const columnsInfoDayTime = [
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 80,
            renderCell: (params) => {
                return <Button size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => ButtonUpdateRestInfoDayTime(params.value)} ></Button>
            }
        },

        { field: 'RestInfoDayTimeId', headerName: 'RestInfoDayTimeId', width: 110, hide: true },
        { field: 'Day', headerName: 'Day', width: 220 },
        { field: 'OpeningHour', headerName: 'OpeningHour', width: 180 },
        { field: 'ClosingHour', headerName: 'ClosingHour', width: 180 },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />}

                    onClick={() => { RemoveRestInfoDayTime(params.value) }} >
                </Button>
            }
        },
    ];

    const columnsImages = [
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 80,
            renderCell: (params) => {
                return <Button size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => ButtonUpdateRestInfoImages(params.value)} ></Button>
            }
        },
        { field: 'RestInfoOrderNbr', headerName: 'order', width: 110 },
        { field: 'RestInfoImgDesc', headerName: 'Image Desc', width: 250 },
        {
            field: 'Image',
            headerName: 'Image',
            sortable: false,
            width: 250,
            renderCell: (params) => {
                const divStyle = {
                    backgroundColor: 'rgb(36,90,118)', // Set the background color 
                };
                return <div style={divStyle}>
                    {params.value?.uri?.includes('null') && !params.value?.uri?.includes('.')
                        ? <></>
                        : <img src={`${params.value.uri}`} alt="No IMG" width={50} height={50} />}
                </div>
            }
        },

        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />}

                    onClick={() => { RemoveRestInfo(params.value) }} >
                </Button>
            }
        },
        {
            field: 'deleteimage',
            headerName: 'Delete Image',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return <Button size="small" style={{ backgroundColor: 'orange', color: 'white' }} startIcon={<DeleteForeverTwoToneIcon />}
                    onClick={() => { DeleteRestinfoImageFromGird(params.value) }} >
                </Button>
            }
        }
    ];

    const RemoveRestInfo = async (id) => {
        const Confirmation = window.confirm("Do you want to Delete?");
        if (Confirmation === true) {
            const ResDel = await DeleteRestInfo({ RestInfoId: id });
            const CnstErrorMsg = ResDel?.Error?.Message;
            if (CnstErrorMsg) {
                handleOpenSnackbar("Failed to Delete RestInf, " + ResDel?.Error?.Message)
                setLoading(false);
            }
            else {
                setLoading(true);
                // const ImageId = (data.find(o => o.CatId === id)?.Image.id);
                // if (ImageId) {
                //   await (DeleteImage(({ RelatedId: ImageId,RelatedType:"Categories"  })))
                // }
                getRestInfoAPI();
                handleOpenSnackbar("RestInfo Deleted");
            }
        }
    }
    const RemoveRestInfoDayTime = async (id) => {
        const Confirmation = window.confirm("Do you want to Delete?");
        if (Confirmation === true) {
            const ResDel = await DeleteRestInfoDayTime({ RestInfoDayTimeId: id });
            const CnstErrorMsg = ResDel?.Error?.Message;
            if (CnstErrorMsg) {
                handleOpenSnackbarDayTime("Failed to Delete Day/Hour, " + ResDel?.Error?.Message)
                setLoading(false);
            }
            else {
                setLoading(true);
                // const ImageId = (data.find(o => o.CatId === id)?.Image.id);
                // if (ImageId) {
                //   await (DeleteImage(({ RelatedId: ImageId,RelatedType:"Categories"  })))
                // }
                getRestInfoDayTimeAPI();
                handleOpenSnackbar("Day/Hour Deleted");
            }
        }
    }
    const RemoveRestInfoWifi= async (id) => {
        const ResDel = await DeleteRestInfoWifi({ RestId: id });
        const CnstErrorMsg = ResDel?.Error?.Message;
        if (CnstErrorMsg) {
            handleOpenSnackbar("Failed to Delete RestInfWifi, " + ResDel?.Error?.Message)
            setLoading(false);
        }
    }
    const RemoveRestInfoDesc = async (id) => {
        const ResDel = await DeleteRestInfoDesc({ RestId: id });
        const CnstErrorMsg = ResDel?.Error?.Message;
        if (CnstErrorMsg) {
            handleOpenSnackbar("Failed to Delete RestInfDesc, " + ResDel?.Error?.Message)
            setLoading(false);
        }
    }
    const SaveSettingsAbout = async () => {
        await UpdateOwnlookUp({
            RestId: localStorage.getItem("RestaurantId"),
            TABID: "ShowTextDescriptionAboutRest",
            CodeSymbol: checkboxState.checkbox1.toString(),
        })
        await UpdateOwnlookUp({
            RestId: localStorage.getItem("RestaurantId"),
            TABID: "ShowImageAboutRest",
            CodeSymbol: checkboxState.checkbox2.toString(),
        })
        await UpdateOwnlookUp({
            RestId: localStorage.getItem("RestaurantId"),
            TABID: "ShowDaysHoursAboutRest",
            CodeSymbol: checkboxState.checkbox3.toString(),
        })
        await UpdateOwnlookUp({
            RestId: localStorage.getItem("RestaurantId"),
            TABID: "ShowWifiInfoAboutRest",
            CodeSymbol: checkboxState.checkbox4.toString(),
        })

        handleOpenSnackbar("Settings Saved")
    }
    const GetSettingsOwnLookUpToShowAndhideComponents = async () => {
        const Res1 = await GetCodeSymbol({
            "RestId": localStorage.getItem("RestaurantId"),
            TABID: "ShowTextDescriptionAboutRest",
        });
        const Res2 = await GetCodeSymbol({
            "RestId": localStorage.getItem("RestaurantId"),
            TABID: "ShowImageAboutRest",
        });
        const Res3 = await GetCodeSymbol({
            "RestId": localStorage.getItem("RestaurantId"),
            TABID: "ShowDaysHoursAboutRest",
        });
        const Res4 = await GetCodeSymbol({
            "RestId": localStorage.getItem("RestaurantId"),
            TABID: "ShowWifiInfoAboutRest",
        });
        if (Res1)
            handleCheckboxChange('checkbox1')

        if (Res2)
            handleCheckboxChange('checkbox2')

        if (Res3)
            handleCheckboxChange('checkbox3')

        if (Res4)
            handleCheckboxChange('checkbox4')
    }


    const SaveTextDesc = async () => {
        if (RestInfoDesc) {
            await RemoveRestInfoDesc(localStorage.getItem("RestaurantId"));
            await InsertRestInfoDesc({
                RestId: localStorage.getItem("RestaurantId"),
                RestInfoDescText: RestInfoDesc,
            });
            getRestInfoDescAPI();
            handleOpenSnackbar("Description Added successfully")
        }
        else
            alert('Please fill Descriptions')
    }
    const SaveWifiInfo= async () => {
        if (RestInfoWifiInfo) {
            await RemoveRestInfoWifi(localStorage.getItem("RestaurantId"));
            await InsertRestInfoWifi({
                RestId: localStorage.getItem("RestaurantId"),
                RestInfoWifiText: RestInfoWifiInfo,
            });
           await getRestInfoDescWifi();
            handleOpenSnackbar("Wifi Info Added successfully")
        }
        else
            alert('Please fill Wifi info')
    }

    const getRowIdImages = (row) => row.RestInfoId;
    const getRowIdDayTime = (row) => row.RestInfoDayTimeId;
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1.3,
                width: '100%'

            }}
        />
    );
    const CheckBoxChild = (
        <div>
            <h4>Control About Rest Info Tab</h4>
            <div>
                <label>
                    <Checkbox
                        checked={checkboxState.checkbox1}
                        onChange={() => handleCheckboxChange('checkbox1')}
                    />
                    Text Descriptions
                </label>
            </div>
            <div>
                <label>
                    <Checkbox
                        checked={checkboxState.checkbox2}
                        onChange={() => handleCheckboxChange('checkbox2')}
                    />
                    Images
                </label>
            </div>
            <div>
                <label>
                    <Checkbox
                        checked={checkboxState.checkbox3}
                        onChange={() => handleCheckboxChange('checkbox3')}
                    />
                    Opening Days & hours
                </label>
            </div>
            <div>
                <label>
                    <Checkbox
                        checked={checkboxState.checkbox4}
                        onChange={() => handleCheckboxChange('checkbox4')}
                    />
                    Wifi Info
                </label>
            </div>

            <Button onClick={(e) => SaveSettingsAbout()} color="primary" type="submit" style={{ 'color': 'Black', 'border': '1px solid Black' }}>
                Save Settigns
            </Button>
        </div>
    );


    const handleDayChange = (event) => {
        setDay(event.target.value);
    };

    const handleOpeningHourChange = (event) => {
        setOpeningHour(event.target.value);
    };

    const handleClosingHourChange = (event) => {
        setClosingHour(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if the day already exists in the list
        const existingDayIndex = openingHours.findIndex((entry) => entry.day === day);

        if (existingDayIndex !== -1) {
            // Update the existing entry
            const updatedOpeningHours = [...openingHours];
            updatedOpeningHours[existingDayIndex] = { day, openingHour, closingHour };
            setOpeningHours(updatedOpeningHours);
        } else {
            // Add a new entry to the list
            setOpeningHours([...openingHours, { day, openingHour, closingHour }]);
        }

        // Clear the form fields
        setDay('');
        setOpeningHour('');
        setClosingHour('');
    };

    const handleEdit = (index) => {
        // Populate the form fields with the selected entry for editing
        const selectedEntry = openingHours[index];
        setDay(selectedEntry.day);
        setOpeningHour(selectedEntry.openingHour);
        setClosingHour(selectedEntry.closingHour);

        // Remove the selected entry from the list
        const updatedOpeningHours = [...openingHours];
        updatedOpeningHours.splice(index, 1);
        setOpeningHours(updatedOpeningHours);
    };
    const handleCheckboxChange = (checkboxName) => {
        setCheckboxState((prevState) => ({
            ...prevState,
            [checkboxName]: !prevState[checkboxName],
        }));
    };

    return (
        <div style={{ height: 500, width: '100%' }}>
            <Helmet>
                <title>Rest info</title>
            </Helmet>

            <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                {loading && <CircularProgress size={75} thickness={5} style={{ 'color': 'black' }} />}
            </div>

            <br />
            <br />
            <div>
                {CheckBoxChild}
            </div>

            <br />

            <ColoredLine color="Black" />
            <h5>Text Descriptions</h5>
            <TextField
                style={{ width: '80%' }}
                className="MultiLinesTextBox"
                margin="dense"
                id="txtRestInfo"
                label="Restaurant Descirption information"
                multiline
                value={RestInfoDesc}
                onChange={(event) => setRestInfoDesc(event.target.value)}
                required
            />
            <br />
            <Button onClick={(e) => SaveTextDesc()} color="primary" type="submit" style={{ 'color': 'Black', 'border': '1px solid Black' }}>
                Save Info
            </Button>


            <br />
            <br />
            <br />

            <ColoredLine color="Black" />
            <h5>Images</h5>
            <DataGrid
                getRowId={getRowIdImages}
                rows={dataImages}
                columns={columnsImages}
                pageSize={20}
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                    setselectedRestinfo(newSelectionModel);
                }}
                components={{
                    Toolbar: () => <CustomToolbar ArrayOfIds={selectedRestinfo} />,
                }}
            />


            <DialogRestInfo
                openModal={open}
                handleCloseModal={handleClose}
                getData={getRestInfoAPI}
                isUpdate={false}
                OpenSnackBarForMsg={handleOpenSnackbar}
            />

            {dataImages[0] ?
                <DialogRestInfo
                    openModal={OpenEditModal}
                    handleCloseModal={handleCloseEditModal}
                    RestInfoRow={dataImages.find(o => o.RestInfoId === RestInfoId_Edit)}
                    getData={getRestInfoAPI}
                    isUpdate={true}
                    OpenSnackBarForMsg={handleOpenSnackbar}
                /> : <></>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
                message={Msg}
                theme={{ colors: { surface: 'blue', accent: 'red' }, }}
            />


            <br />
            <br />
            <br />

            <ColoredLine color="Black" />
            <h5>Opening Days & hours</h5>
            <DataGrid
                getRowId={getRowIdDayTime}
                rows={RestInfoDayTime}
                columns={columnsInfoDayTime}
                pageSize={10}
                disableSelectionOnClick
                components={{
                    Toolbar: () => <CustomToolbarDayTime ArrayOfIds={selectedRestinfo} />,
                }}
            />
            <DialogForRestInfoDayTime
                openModal={openDayTime}
                handleCloseModal={handleCloseDayTime}
                getData={getRestInfoDayTimeAPI}
                isUpdate={false}
                OpenSnackBarForMsg={handleOpenSnackbarDayTime}
            />

            {RestInfoDayTime[0] ?
                <DialogForRestInfoDayTime
                    openModal={OpenEditModalDayTime}
                    handleCloseModal={handleCloseEditModalDayTime}
                    RestInfoDayTimeRow={RestInfoDayTime.find(o => o.RestInfoDayTimeId === RestInfoDayTimeId_Edit)}
                    getData={getRestInfoDayTimeAPI}
                    isUpdate={true}
                    OpenSnackBarForMsg={handleOpenSnackbarDayTime}
                /> : <></>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbarDayTime}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
                message={Msg}
                theme={{ colors: { surface: 'blue', accent: 'red' }, }}
            />

            <br />
            <br />
            <ColoredLine color="Black" />
            <h5>Wifi</h5>
            <TextField
                style={{ width: '40%' }} 
                className="MultiLinesTextBox"
                margin="dense"
                id="txtRestInfoWifi"
                label="WIFI Info / password, name ..."
                multiline
                value={RestInfoWifiInfo}
                onChange={(event) => setRestInfoWifiInfo(event.target.value)} 
            />
            <br/>
            <Button onClick={(e) => SaveWifiInfo()} color="primary" type="submit" style={{ 'color': 'Black', 'border': '1px solid Black' }}>
                Save Wifi Info
            </Button>
        </div>
    );
} 