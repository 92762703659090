import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { login } from '../apis/authentication';
import { Grid, Paper, Avatar } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';

import React from 'react';
import AppConfig from '../AppSettings';
import {EncryptString} from '../components/GeneralFunctions';

function LogIn({ setIsAuthorized, handleClick }) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false);

    let history = useHistory(); 

    const auth = async () => {
        if (username && password) {
            setLoading(true);
            const result = await login({
                UserName: await EncryptString(username),
                Password: await EncryptString(password)
            })
            if (result.JWT) {
                setIsAuthorized(true);
                handleClick();
                history.push('/Dashboard');
            }
            else {
                setLoading(false);
                alert("Please Check username and password or contact admin")
            }
        }
        else {
            alert("Please fill username and password ")
        }
    }

    const paperStyle = { padding: 70 }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    return <div>
        <Helmet>
            <title>Login</title>
        </Helmet>
        <div
            style={{
                position: 'absolute', left: '50%', top: '30%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            {loading && <CircularProgress size={75} thickness={5} style={{ 'color': 'black' }} />}
        </div>
        <Grid>
            <Paper elevation={2} style={paperStyle}>
                <Grid align='left'>
                    <Avatar style={avatarStyle}><LockIcon /> </Avatar>
                    <h2>Sign in</h2>
                    <TextField required
                        id="standard-basic" variant="standard"
                        label="UserName"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <br />
                    <TextField id="standard-basic" required
                        label="Password" type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <br />
                    <br />
                    <Button variant="contained" color="primary" onClick={auth}>
                        Login
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    </div>
}
export default LogIn;
