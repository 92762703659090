import AppConfig from '../AppSettings';
 
export const GetComments = async (data) => {
    try{   
    const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl+`/Comments/GetComments`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json(); 
         
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}


export const InsertComent = async (data) => { 
    try{ 
        const response = await fetch(AppConfig.apiUrl+`/Comments/InsertComments`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result =await response.json(); 
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}
 
export const DeleteComments = async (data) => {
 
    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Comments/DeleteComments`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}  