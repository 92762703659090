import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Drawer, Divider
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import RestaurantOutlinedIcon from '@material-ui/icons/RestaurantOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import DetailsOutlinedIcon from '@material-ui/icons/DetailsOutlined';
import ScatterPlotOutlinedIcon from '@material-ui/icons/ScatterPlotOutlined';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TabOutlinedIcon from '@material-ui/icons/TabOutlined'; 
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined'; 

const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: "auto",

    },
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,

    },
    selected: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
});

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {
        backgroundColor: `${theme.palette.action.selected} !important`, // Override background color for selected item
        color: `${theme.palette.action.selectedText} !important`, // Override text color for selected item
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, onClick, labelIconStyle, ...other } = props;

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon style={labelIconStyle} className={classes.labelIcon} />


                    {/*h1, h2, h3, h4, h5, h6: Heading variants for different levels of headings.*/}
                    {/*subtitle1, subtitle2: Subtitle variants for secondary text.*/}
                    {/*body1, body2: Body text variants for main content.*/}
                    {/*caption: Caption variant for auxiliary text.*/}
                    {/*button: Variant for button text.*/}
                    {/*overline: Overline variant for text above other content.*/}


                    <Typography variant="subtitle2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            onClick={onClick}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    labelIconStyle: PropTypes.object, // New prop for icon style

};

const DrawerComponent = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const handleDrawerItemClick = (route) => {
        history.push(route);
        props.toggleDrawerHandler();
    };



    return (
        <Drawer open={props.open} onClose={props.toggleDrawerHandler} PropsCountsMyCat_SubCat_Item_COUNT={props.PassCounts}>
            <div
                className={classes.list}
                role="presentation"    >

                <br />
                <TreeView
                    className={classes.root}
                    defaultExpanded={['100', '200']}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    selected={location.pathname}> 

                    <StyledTreeItem
                        nodeId="1"
                        labelText="Dashboard"
                        labelIcon={DashboardOutlinedIcon}
                        color="#red"
                        bgColor="#red"
                        onClick={() => handleDrawerItemClick('/Dashboard')}
                        selected={'/Dashboard' === location.pathname}
                        labelIconStyle={{ color: 'red' }}
                    />
                    <ColoredLine color='gray'></ColoredLine> 
                    <StyledTreeItem nodeId="100" labelText="Menu" labelIcon={RestaurantOutlinedIcon} labelIconStyle={{ color: '#bb99ff' }}>
                        <StyledTreeItem
                            nodeId="3"
                            labelText="Categories"
                            labelIcon={CategoryOutlinedIcon}
                            labelInfo={props.PassCounts.CatCount}
                            bgColor="#e8f0fe"
                            color="2196F3"
                            onClick={() => handleDrawerItemClick('/Categories')}
                            selected={'/' === location.pathname}
                            labelIconStyle={{ color: '#2196F3' }}
                        />
                        <StyledTreeItem
                            nodeId="4"
                            labelText="Sub-Categories"
                            labelIcon={ScatterPlotOutlinedIcon}
                            labelInfo={props.PassCounts.SubCatCount}
                            color="#FF9800"
                            bgColor="#fcefe3"
                            onClick={() => handleDrawerItemClick('/sub-categories')}
                            selected={'/sub-categories' === location.pathname}
                            labelIconStyle={{ color: '#FF9800' }}
                        />
                        <StyledTreeItem
                            nodeId="5"
                            labelText="Items"
                            labelIcon={DragIndicatorOutlinedIcon}
                            labelInfo={props.PassCounts.ItemCount}
                            color="#E91E63"
                            bgColor="#f3e8fd"
                            onClick={() => handleDrawerItemClick('/ItemsUI')}
                            selected={'/ItemsUI' === location.pathname}
                            labelIconStyle={{ color: '#E91E63' }}
                        />
                        <StyledTreeItem nodeId="6" labelText="Info" labelIcon={InfoOutlinedIcon} onClick={() => handleDrawerItemClick('/RestInfo')} labelIconStyle={{ color: '#ace600' }} color="#ace600" />
                        <StyledTreeItem nodeId="7" labelText="Comments" labelIcon={FeedbackOutlinedIcon} onClick={() => handleDrawerItemClick('/DisplayComments')} labelInfo={props.PassCounts.CommentsCount} labelIconStyle={{ color: '#9C27B0' }} color="#9C27B0" />

                    </StyledTreeItem>
                    <ColoredLine color='gray'></ColoredLine>
                    <StyledTreeItem nodeId="200" labelText="Restaurant" labelIcon={TabOutlinedIcon} labelIconStyle={{ color: '#bb99ff' }}>
                   
                        <StyledTreeItem nodeId="8" labelText="Views Report" labelIcon={VisibilityOutlinedIcon} onClick={() => handleDrawerItemClick('/DashboardViews')} labelInfo={props.PassCounts.ViewsCount} labelIconStyle={{ color: '#4CAF50' }} color="#4CAF50" />

                        <StyledTreeItem nodeId="9" labelText="Customization Colors" labelIcon={ColorLensOutlinedIcon} onClick={() => handleDrawerItemClick('/ColorCustomization')} labelIconStyle={{ color: '#19F5BE' }} color="#19F5BE" />
                        <StyledTreeItem nodeId="10" labelText="Managment" labelIcon={TextFieldsOutlinedIcon} onClick={() => handleDrawerItemClick('/MyRestaurant')} labelIconStyle={{ color: '#F0AC38' }} color="#F0AC38" />

                        <StyledTreeItem nodeId="11" labelText="QR Code Builder" labelIcon={CropFreeOutlinedIcon} onClick={() => handleDrawerItemClick('/QRCodeBuilder')} labelIconStyle={{ color: '#F0AC38' }} color="#F0AC38" />
                    
                    </StyledTreeItem>

                    <br/>
                    <br/>
                    <div className="restaurant-visit-wrapper">
                        <a
                            href={`https://originssoftwares.com/Menu/${props.RestName}`}
                            target="_blank"
                            className="restaurant-visit-button"
                        >
                            View {props.RestName} Menu &nbsp;&#8594;  {/* Arrow to indicate external link */}
                        </a>
                    </div>

                </TreeView>
              
            </div>
           


        </Drawer>
    );
};
const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 0.1,
            width: '75%'

        }}
    />
);
export default DrawerComponent;
