import axios from 'axios'
import AppConfig from '../AppSettings';

export const GetRestInfoWifiCMS = async (data) => {
    try {  
        const response = await fetch(AppConfig.apiUrl + `/RestInfoWifiInfo/GetRestInfoWifi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
         

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const GetRestInfoWifi= async (data) => {
    try {  
        // Create a unique cache key based on the request parameters
        const cacheKey = `GetRestInfoWifi_${JSON.stringify(data)}`;

        // Check if the data is already in the cache
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
            // Return cached data if available
            return JSON.parse(cachedData);
        }


        const response = await fetch(AppConfig.apiUrl + `/RestInfoWifiInfo/GetRestInfoWifi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // Store the result in the sessionStorage cache
        sessionStorage.setItem(cacheKey, JSON.stringify(result));

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        } 
        return result;
    }
}
export const InsertRestInfoWifi = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/RestInfoWifiInfo/InsertRestInfoWifi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const UpdateRestInfoWifi = async (data) => { 
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/RestInfoWifiInfo/DeleteRestInfoWifi`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json(); 
        return result;
    }
    catch (error) { 
        const result = {
            Error: {
                Message: error
            }
        } 
        return result;
    }
}  

export const DeleteRestInfoWifi = async (data) => {

    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/RestInfoWifiInfo/DeleteRestInfoWifi`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        } 
        return result;
    }
}