import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0.5),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function Footer(RestaurantInfo) {
    RestaurantInfo = RestaurantInfo?.RestaurantInfo?.Restaurant;

    const classes = useStyles();

    const mystyle = {
        color: " rgb(36,90,118)",
        backgroundColor: 'rgba(252, 177, 3)',

    };
    const LocationStyle = {
        color: " rgb(36,90,118)",
        fontFamily: "Arial",
        fontSize: 15,
        marginLeft: 200
    };
    const logoStuffStyle = {
        backgroundColor:" rgb(36,90,118)",
        color: "rgba(252, 177, 3)",
    };
    const InstaFbBg = {
        backgroundColor: " rgb(36,90,118)",
        color: "rgba(252, 177, 3)",
    };
    const FaceboobInstaStyle = {
        marginLeft: 200
    };
    const FbIntaColorlogo = {
        color: " rgb(36,90,118)",
    }
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 0.5,
                width: '70%'

            }}
        />
    );

    return (

        <div>

            <div style={mystyle} className="footer"   >
                <div className="logosInfo">
                    <a href={RestaurantInfo?.Locationlink} rel="noreferrer" target="_blank" style={LocationStyle}>
                        <Fab size="small" color="secondary" aria-label="location" className={classes.margin} style={logoStuffStyle}>
                            <LocationOnIcon />
                        </Fab>
                        {RestaurantInfo?.Location}
                    </a>
                    <br />
                    <a href={"tel:" + RestaurantInfo?.Phone1} style={LocationStyle}>
                        <Fab size="small" color="secondary" aria-label="phone" className={classes.margin} style={logoStuffStyle}>
                            <PhoneIcon />
                        </Fab>
                        {RestaurantInfo?.Phone1}
                    </a>
                    {(RestaurantInfo?.Phone2) ?
                        <> <br />
                            <a href={"tel:" + RestaurantInfo?.Phone2} style={LocationStyle}>
                                <Fab size="small" color="secondary" aria-label="phone" className={classes.margin} style={logoStuffStyle}>
                                    <PhoneIcon />
                                </Fab>
                                {RestaurantInfo?.Phone2} </a></> : <></>}

                </div>
                <p style={logoStuffStyle} className="AllignFooter"><u>{RestaurantInfo?.Name}</u></p>
                <div className="logosInfo">
                    <p style={FaceboobInstaStyle} >Connect with us: <t></t>
                        <a href={RestaurantInfo?.FacebookLink} rel="noreferrer" style={FbIntaColorlogo} target="_blank" >
                            <FacebookIcon></FacebookIcon>  </a>
                        <a href={RestaurantInfo?.InstaLink} rel="noreferrer" style={FbIntaColorlogo} target="_blank" >
                            <InstagramIcon></InstagramIcon>  </a>  
                        {RestaurantInfo?.WhatsapNbr ?
                            
                            <a href={`https://wa.me/${RestaurantInfo?.WhatsapNbr}`} rel="noreferrer"     style={FbIntaColorlogo} target="_blank" >
                            <WhatsAppIcon></WhatsAppIcon>  </a>:<></>}
                    </p>
                </div>
                <ColoredLine color="rgb(28, 69,89)" />
                <p className="AllignFooter" style={{ fontSize: 13 }}> Developed by  <a href={"https://originssoftwares.com/OriginSoft"} rel="noreferrer"  style={logoStuffStyle} target="_blank" >
                    Origins  </a><br /> © Copyright 2022-26 All rights reserved.</p>
            </div>
        </div>
    )
}
