import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { InsertCallWaiter } from '../apis/CallWaiterAPI';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DisplayAlert from '../components/DisplayAlert'
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
export default function AddCallWaiter(RestInf) {
    const [openAlert, setopenAlert] = useState(false)
    const [AlertMessage, setAlertMessage] = useState("")

    const [TblNbr, setTblNbr] = useState("");
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setopenSnackbar] = useState(false);
    const [open, setOpen] = useState(false);
    const [OpenEditModal, setOpenModal] = useState(false) 
    const [Msg, setMsg] = useState("")


    useEffect(() => {
    }, [])

    const handleColseAlert = () => {
        setopenAlert(false);
    };

    const ClearFields = () => {
        setTblNbr("");
        setLoading(false);
    };

    const TextAreaStyle = {
        width: '73%',
        height: 65,
        maxHeight: 200,
        maxWidth: 500
    }
    const TextAreaStyleReg = {
        width: '50%',
        maxWidth: 300
    }

    const submit = async (data) => {
        if (TblNbr) {

            await insert();
        }
        else {
            setopenAlert(true);
            setAlertMessage("Table number or Area is required");
        }
    } 
    const insert = async () => {
        setLoading(true);
        const result = InsertCallWaiter(RestInf?.RestInf?.Restaurant?.RestID, TblNbr);
        if (result) 
            handleOpenSnackbar('Your request has been submitted. A waiter will assist you shortly.')

        ClearFields();
    }
    const handleCloseSnackbar = () => {
        setopenSnackbar(false);
    };
    const handleOpenSnackbar = (strMsg) => {
        setopenSnackbar(true);
        setMsg(strMsg)
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(-2),
            },
        },
    }));
    const classes = useStyles();
    return (
        <div>
            <form>

                <div className="footerRate">
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '100%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
                    </div>
                    <Box component="fieldset" mb={1} borderColor="transparent" style={{ 'color': 'White' }}>
                        <Typography component="legend" >  <h5>
                            🔔  Call Waiter Feature ! 🔔</h5> </Typography>
                    </Box>
                    <br />
                    <TextField
                        style={{ backgroundColor: 'white', color: 'white' }}
                        id="txtItemNameEng"
                        label="Table Nbr or Area"
                        aria-label="maximum height"
                        required
                        value={TblNbr}
                        onChange={(event) => setTblNbr(event.target.value)}
                    />
                    <br />
                    <br />
                    <Button onClick={(e) => submit()} color="secondary" style={{ 'color': 'White', 'border': '1px solid #ddd' }}>
                        Call Waiter
                    </Button>

                    <DisplayAlert open={openAlert} message={AlertMessage} close={handleColseAlert} />
                </div>
            </form>
            <Snackbar theme={{ colors: { surface: 'blue', accent: 'red' }, }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={Msg}
            />
        </div>
    )
}
