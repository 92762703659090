import axios from 'axios'
import AppConfig from '../AppSettings';

export const GetCategorys = async (data) => {
    try{ 
        
    const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl+`/Categories/GetCat`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        }) 
        const result =await response.json();
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}
export const InsertCategory = async (data) => { 
    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Categories/InsertCat`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();  
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}
export const UpdateCategorys = async (data) => {
    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Categories/UpdateCat`,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();
        return result;
    }
    catch(error){
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}
export const uploadImage = async (image,relatedType,relatedid,ObjectString) => {
    const formData = new FormData();
    formData.append('ImageFile', image)
    formData.append('StrRelatedType', relatedType)
    formData.append('StrRelatedId', relatedid)
    formData.append('StrObjectString', ObjectString)
    if (image){
        
    const token = localStorage.getItem("jwt");
        const headers = {
            'Authorization':`Bearer ${token}`
          };

        return await axios
        .post(AppConfig.apiUrl+`/UploadFile/UploadImage`, formData, { headers })
        // .then(res => {
        //   data.Image = res.data[0].id;
        //   callback(data);
        // })
        .catch(err => {
          console.log(err);
        });
    }
    // else{
    //     callback(data);
    // }
}


export const DeleteCategory = async (data) => {

    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Categories/DeleteCat`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}
export const DeleteImage= async (data) => { 
    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/UploadFile/DeleteFileUpload`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
} 
