import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//apis
import { GetSubCatsForddl, UpdateItem, InsertItem, GetCatFromSubCat } from '../apis/ItemsAPI';
import { GetSubCategorys } from '../apis/SubCategoriesApis';
import DisplayAlert from '../components/DisplayAlert'
import CircularProgress from '@material-ui/core/CircularProgress';
import { uploadImage, DeleteImage } from '../apis/CategoriesApis';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 


const useStylesddl = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function DialogForItem({ isUpdate, getData, ItemRow, openModal, handleCloseModal, OpenSnackBarForMsg }) {
    const [openAlert, setopenAlert] = useState(false)
    const [AlertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false);
    const [SubCategorysddl, setSubCategorysddl] = useState([])
    const [SelectedSubCategory, setSelectedSubCategory] = useState("")
    const [ItemDescriptionAr, setItemDescriptionAr] = useState("")
    const [ItemDescription, setItemDescription] = useState("")
    const [ItemPriceEdit, setItemPriceEdit] = useState("")
    const [ItemArabicNameEdit, setItemArabicNameEdit] = useState("")
    const [ItemEnglishNameEdit, setItemEnglishNameEdit] = useState("")
    const [images, setimages] = useState()
    const [OrderNumber, setOrderNumber] = useState("")
    const [IsStateItemFreePrice, setStateIsItemFreePrice] = useState(false);
    const [IsStateItemVisible, setIsStateItemVisible] = useState(true);
    const [ChoiceNameState, setChoiceNameState] = React.useState([]); 
    const [ChoiceRecState, setChoiceRecState] = useState(false)
    const [ChoiceSpicyState, setChoiceSpicyState] = useState(false)
    const [ChoicePopulaStater, setChoicePopularState] = useState(false)
    const [ChoiceNewState, setChoiceNewState] = useState(false)
    const [ChoiceVeganState, setChoiceVeganState] = useState(false)
    const [ChoiceOfferState, setChoiceOfferState] = useState(false)

    const classesddl = useStylesddl();

    const handleCheckboxChange = () => {
        setStateIsItemFreePrice(!IsStateItemFreePrice);
    };

    const handleCheckboxChangeVisibleItem = () => { 
        setIsStateItemVisible(!IsStateItemVisible);
    };

    useEffect(() => {
        getSubCategoriesforddlAPI();
        if (isUpdate && ItemRow) { 
            setSelectedSubCategory(ItemRow.SubCatId);
            setItemArabicNameEdit(ItemRow.ItemNameSecondlang);
            setItemEnglishNameEdit(ItemRow.ItemNameFirstLang)
            setItemPriceEdit(ItemRow.ItemPrice)
            setItemDescription(ItemRow.ItemDescFirstLang)
            setItemDescriptionAr(ItemRow.ItemDescSecondlang)
            setOrderNumber(ItemRow.ItemOrderNbr);
            setStateIsItemFreePrice(ItemRow.IsItemFreePrice);
            setChoiceRecState(ItemRow?.RecommendedChoice)
            setChoiceSpicyState(ItemRow?.SpicyChoice)
            setChoicePopularState(ItemRow?.PopularChoice)
            setChoiceNewState(ItemRow?.NewChoice)
            setChoiceVeganState(ItemRow?.VeganChoice)
            setChoiceOfferState(ItemRow?.OfferChoice)
            setIsStateItemVisible(ItemRow?.IsItemVisible)

            const arrayConcatedChoices = [
                ItemRow?.RecommendedChoice ? 'Rec 👑' : '',
                ItemRow?.SpicyChoice ? 'Spicy 🌶️' : '',
                ItemRow?.PopularChoice ? 'Popular ⭐' : '',
                ItemRow?.NewChoice ? 'New 🆕' : '',
                ItemRow?.VeganChoice ? 'Vegan 🌿' : '',
                ItemRow?.OfferChoice ? 'Offre 📌' : '',
            ].filter(Boolean);
            setChoiceNameState(arrayConcatedChoices); 

        }
    }, [ItemRow, getData])

    const update = async () => {
        return await callbackEdit({
            ItemId: ItemRow.ItemId,
            SubCatId: SelectedSubCategory,
            ItemOrderNbr: OrderNumber,
            ItemNameFirstLang: ItemEnglishNameEdit,
            ItemNameSecondlang: ItemArabicNameEdit,
            ItemDescFirstLang: ItemDescription,
            ItemDescSecondlang: ItemDescriptionAr,
            ItemPrice: ItemPriceEdit,
            IsItemFreePrice: IsStateItemFreePrice,
            RecommendedChoice: ChoiceRecState,
            PopularChoice: ChoicePopulaStater,
            SpicyChoice: ChoiceSpicyState,
            NewChoice: ChoiceNewState,
            VeganChoice: ChoiceVeganState,
            OfferChoice: ChoiceOfferState,
            IsItemVisible: IsStateItemVisible,
        })
    }

    const handleColseAlert = () => {
        setopenAlert(false);
    };

    const insert = async () => {
        return await callbackInsert({
            SubCatId: SelectedSubCategory,
            ItemOrderNbr: OrderNumber,
            ItemNameFirstLang: ItemEnglishNameEdit,
            ItemNameSecondlang: ItemArabicNameEdit,
            ItemDescFirstLang: ItemDescription,
            ItemDescSecondlang: ItemDescriptionAr,
            ItemPrice: ItemPriceEdit,
            IsItemFreePrice: IsStateItemFreePrice,
            RecommendedChoice: ChoiceRecState,
            PopularChoice: ChoicePopulaStater,
            SpicyChoice: ChoiceSpicyState,
            NewChoice: ChoiceNewState,
            VeganChoice: ChoiceVeganState,
            OfferChoice: ChoiceOfferState,
            IsItemVisible: IsStateItemVisible, 
        })

    }
    const ClearFields = () => {
        setItemArabicNameEdit("");
        setItemEnglishNameEdit("");
        setSelectedSubCategory("");
        setItemPriceEdit("");
        setItemDescription("");
        setimages("");
        setOrderNumber("");
        setItemDescriptionAr("");
        setStateIsItemFreePrice(false);
        setIsStateItemVisible(true);
        setChoiceNameState([]);

        setChoiceRecState(false)
        setChoiceSpicyState(false)
        setChoicePopularState(false)
        setChoiceNewState(false)
        setChoiceVeganState(false)
        setChoiceOfferState(false)

    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }; 
    const handleChangeChoiceddl = (event) => {
        const { value } = event.target;

        //alert(value)
        // Update the state based on the selected value
        setChoiceNameState(value);

        // Update the individual checkbox states
        setChoiceRecState(value.includes("Rec 👑"));
        setChoiceSpicyState(value.includes("Spicy 🌶️"));
        setChoicePopularState(value.includes("Popular ⭐"));
        setChoiceNewState(value.includes("New 🆕"));
        setChoiceVeganState(value.includes("Vegan 🌿"));
        setChoiceOfferState(value.includes("Offre 📌"));
    };

    const callbackInsert = async (data) => {
        const InsertRes = await InsertItem(data);
        const CnstErrorMsg = InsertRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to Insert Item, " + InsertRes?.Error?.Message)
            setLoading(false);
        }
        else {
            if (images)
                await uploadImage(images, "Items", InsertRes, JSON.stringify(data))

            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("Item Inserted successfully ");
            ClearFields();
            setLoading(false);
        }
    }

    const callbackEdit = async (data) => {
        const UpdateRes = await UpdateItem(data);
        const CnstErrorMsg = UpdateRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to update item, " + UpdateRes?.Error?.Message)
            setLoading(false);
        }
        else {
            const ImageID = ItemRow.Image?.id;
            //check if user has been upload a image on update mode , so we need to delete the old one and reupload a new image
            if (ImageID && images) {
                await (DeleteImage(({ RelatedId: ImageID, RelatedType: "Items" })))
            }
            if (images)
                await uploadImage(images, "Items", data.ItemId, JSON.stringify(data))
            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("Item Updated successfully ");
            ClearFields();
            setLoading(false);
        }
    }


    const submit = async () => {
        if (SelectedSubCategory === '' || (!ItemEnglishNameEdit && !ItemArabicNameEdit))
            return alert('Please Check mandatory Fields')

        setLoading(true);
        if (isUpdate) {
            await update()
            // setLoading(false);
        }
        else {
            await insert()
            // setLoading(false);
        }
    }

    const onImageChange = event => {
        setimages(event.target.files[0]);
    };

    const handleChangeddl = (event) => {
        setSelectedSubCategory(event.target.value);
        // console.log("selected", event.target.value)
    };

    const getSubCategoriesforddlAPI = async () => {
        const result = await GetSubCategorys({ "RestId": localStorage.getItem("RestaurantId") });
        setSubCategorysddl(result?.Dt);
    }
    return (
        <div>


            <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Item Info</DialogTitle>
                <DialogContent>
                    <form  >

                        <FormControl className={classesddl.formControl} required >
                            <InputLabel id="demo-simple-select-label">Sub-Category</InputLabel>
                            <Select
                                autoFocus
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={SelectedSubCategory}
                                onChange={handleChangeddl}     >
                                {/* {console.log("ddddd",SubCategorysddl)} */}
                                {SubCategorysddl.sort((a, b) => b.SubCatNameFirstLang - a.SubCatNameSecondlang).map(item =>
                                    <MenuItem key={item.SubCatId} value={item.SubCatId}   >
                                        <ListItemText primary={item.SubCatNameFirstLang + "_" + item.SubCatNameSecondlang} />
                                    </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <br />
                        <TextField
                            margin="dense"
                            id="txtItemNameEng"
                            label="Item English Name"
                            value={ItemEnglishNameEdit}
                            onChange={(event) => setItemEnglishNameEdit(event.target.value)}
                            required
                        />
                        <br />
                        <TextField
                            margin="dense"
                            id="txtItemNameAr"
                            label="Item SecondLang Name"
                            value={ItemArabicNameEdit}
                            onChange={(event) => setItemArabicNameEdit(event.target.value)}
                            required
                        />
                        <br />
                        <div style={{ display: 'flex' }}>
                            <label style={{ width: '50%', marginTop: '30px', fontStyle: 'inherit' }} >

                                <FormGroup>
                                    <InputLabel id="demo-multiple-checkbox-labelFreePrice">
                                        Free price
                                        <Checkbox style={{ fontFamily: 'inherit' }}
                                            checked={IsStateItemFreePrice}
                                            onChange={handleCheckboxChange}></Checkbox> 
                                            </InputLabel>
                                </FormGroup>  
                            </label>

                            <TextField
                                style={{ width: '50%', fontFamily: 'inherit' }}
                                className="MultiLinesTextBox"
                                margin="dense"
                                id="txtItemPrice"
                                label="Item Price"
                                multiline
                                rows={2}
                                value={ItemPriceEdit}
                                onChange={(event) => setItemPriceEdit(event.target.value)}
                                required
                            />
                        </div>

                        <br />
                        <TextField
                            className="MultiLinesTextBox"
                            margin="dense"
                            id="txtItemDesc"
                            label="Item Descirption Eng"
                            multiline
                            rows={8}
                            value={ItemDescription}
                            onChange={(event) => setItemDescription(event.target.value)}
                            required
                        />
                        <br />
                        <TextField
                            className="MultiLinesTextBox"
                            margin="dense"
                            id="txtItemDescAt"
                            label="Item Descirption Sec"
                            multiline
                            rows={8}
                            value={ItemDescriptionAr}
                            onChange={(event) => setItemDescriptionAr(event.target.value)}
                            required
                        />
                        <br />
                        <div> 
                                <InputLabel id="demo-multiple-checkbox-label">Top Choices</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={ChoiceNameState}
                                    onChange={handleChangeChoiceddl}
                                    input={<OutlinedInput label="Top Choices" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}  > 

                                <MenuItem key={"Rec 👑"} value={"Rec 👑"}>
                                    <Checkbox checked={ChoiceRecState} />
                                    <ListItemText primary={"Rec 👑"} />
                                </MenuItem>  

                                <MenuItem key={"Spicy 🌶️"} value={"Spicy 🌶️"}>
                                    <Checkbox checked={ChoiceSpicyState} />
                                    <ListItemText primary={"Spicy 🌶️"} />
                                </MenuItem> 

                                <MenuItem key={"Popular ⭐"} value={"Popular ⭐"}>
                                    <Checkbox checked={ChoicePopulaStater} />
                                    <ListItemText primary={"Popular ⭐"} />
                                </MenuItem> 

                                <MenuItem key={"New 🆕"} value={"New 🆕"}>
                                    <Checkbox checked={ChoiceNewState} />
                                    <ListItemText primary={"New 🆕"} />
                                </MenuItem> 

                                <MenuItem key={"Vegan 🌿"} value={"Vegan 🌿"}>
                                    <Checkbox checked={ChoiceVeganState} />
                                    <ListItemText primary={"Vegan 🌿"} />
                                </MenuItem> 

                                <MenuItem key={"Offre 📌"} value={"Offre 📌"}>
                                    <Checkbox checked={ChoiceOfferState} />
                                    <ListItemText primary={"Offre 📌"} />
                                </MenuItem> 

                                </Select> 
                        </div>

                        <br />
                        <TextField
                            type="number"
                            margin="dense"
                            id="txtOrder"
                            label="Order Nbr"
                            value={OrderNumber}
                            onChange={(event) => setOrderNumber(event.target.value)}
                            required
                        />
                        <br /> 
                        <br /> 
                        <FormGroup>
                            <InputLabel id="demo-multiple-checkbox-label">Visible
                                <Checkbox checked={IsStateItemVisible} onChange={() => handleCheckboxChangeVisibleItem()} /> </InputLabel>
                        </FormGroup> 

                        <br />
                        <input
                            accept="image/*"
                            name="file"
                            id="contained-button-file"
                            single
                            type="file"
                            onChange={onImageChange}
                        />
                    </form>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '60%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
                    </div>


                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => submit()} color="primary" type="submit">
                        {isUpdate ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
                <DisplayAlert open={openAlert} message={AlertMessage} close={handleColseAlert} />
            </Dialog>
        </div>
    )
}
