import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@material-ui/data-grid';
import {GetComments,DeleteComments } from '../apis/CommentsAPI';
 
import Button from '@material-ui/core/Button'; 
import DeleteIcon from '@material-ui/icons/Delete';
import { Helmet } from 'react-helmet';
const restructureData = (data) => {
    return data.map((structure) => { 
      structure.delete = structure.CommentId;  
      return structure;
    })
  }

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useToolBarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function BasicTable() {
  const classes = useStyles();
  const ToolBarStylesClasses = useToolBarStyles();

  function CustomToolbar({ ArrayOfIds }) {
    return (
      <GridToolbarContainer className={ToolBarStylesClasses.root}> 
        <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => removeBulkComments(ArrayOfIds)}></Button>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]); 
 
  useEffect(() => {
    getCommentsAPI();
  }, [])

  const getCommentsAPI = async () => {
    const result = await GetComments({ "RestId": localStorage.getItem("RestaurantId")});  
    setData(restructureData(result?.Dt));
  }

  const removecomment = async (id) => { 
    const Confirmation = window.confirm("Do you want to Delete?");
    if (Confirmation == true) {
      await DeleteComments({CommentId:id}); 
      getCommentsAPI(); 
    }
  }
  const CallremovecommentFromBulk = async (id) => {
    await DeleteComments({CommentId:id}); 
    getCommentsAPI();
  }
  function removeBulkComments(id) {
    const Confirmation = window.confirm("Do you want to Delete them?");
    if (Confirmation == true) {
      let FirstId = id.selectionModel;
      if (FirstId) {
        FirstId.forEach(element => CallremovecommentFromBulk(element));
      } 
    }
  } 

  const columns = [
    { field: 'CommentId', headerName: 'ID', width: 70, hide: true },
    { field: 'Rating', headerName: 'Rate', width: 120 },
    { field: 'Description', headerName: 'Comment', width: 300 }, 
    { field: 'CustName', headerName: 'Cust Name', width: 250 },
    { field: 'CustPhone', headerName: 'Cust Phone', width: 250 }, 
    { field: 'CreationDateTime', headerName: 'created_at', width: 250 },
    {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        width: 100,
        renderCell: (params) => {
          return <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />}
  
            onClick={() => { removecomment(params.value) }} >
          </Button>
        }
      }, 
  ];
  const getRowId = (row) => row.CommentId;
  return (
    <div style={{ height: 500, width: '100%' }}>
         <Helmet>
        <title>Comments</title>
      </Helmet>

      <DataGrid
      getRowId={getRowId}
        rows={data}
        columns={columns}
        pageSize={120}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedCategories(newSelectionModel);
        }}
        components={{
          Toolbar: () => <CustomToolbar ArrayOfIds={selectedCategories} />,
        }}
      /> 
    </div>
  );
}