import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { SelectViewsPerDay } from '../apis/DashboardViews';
import { Helmet } from 'react-helmet'; 
import CircularProgress from '@material-ui/core/CircularProgress';

const DashboardViews = () => {
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DDTHH:mm'));
    const [toDate, setToDate] = useState(
        moment().endOf('day').format('YYYY-MM-DDT23:59')
    );

    const [loading, setLoading] = useState(true);

    const [ViewsPerDaysResponse, setViewsPerDaysResponse] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        handleSearch();
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const chartWidth = windowWidth > 768 ? windowWidth - 100 : windowWidth;
    const chartHeight = chartWidth * 0.6;

    const DashboardParams = {
        RestId: localStorage.getItem("RestaurantId"),
        FromDate: fromDate,
        ToDate: toDate
    };

    const fetchData = async () => {
        try {
            setViewsPerDaysResponse([])
            const Rest = await SelectViewsPerDay(DashboardParams);
            setViewsPerDaysResponse(Rest);

            setLoading(false);
        } catch (error) { 
        }
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearch = () => {

        setLoading(true);
        fetchData();
    };

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(2),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        searchButton: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        chartContainer: {
            marginLeft: -theme.spacing(2),
        },
        totalViewsLabel: {
            color: 'rgb(136, 132, 216)',
            textAlign: 'center',
            marginTop: theme.spacing(2),
        }
    }));

    const classes = useStyles();
    const formatDate = (label) => { 
        // Split the label into day, month, and year
        const [day, month, year] = label.split('-');
        // Rearrange to 'yyyy-MM-dd'
        const formattedDate = `${year}-${month}-${day}`;

        // Create a Date object
        const date = new Date(formattedDate);

        // Get the day name
        const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });

        return `${label} (${dayName})`;
    };
    const formatDateNoyear = (label) => {
        // Format date for display on the line chart (dd-MM)
        return label.substring(0, 5); // Extract the first 5 characters (dd-MM)
    };
    // Calculate total views
    const totalViews = ViewsPerDaysResponse?.Dt?.reduce((total, data) => total + data.ViewsPerDay, 0);

    return (
        <div>
            <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {loading && <CircularProgress size={75} thickness={5} style={{ 'color': 'black' }} />}
            </div>  

            <div>
                <Helmet>
                    <title>Dashboard Views</title>
                </Helmet>
                <br />
                <Grid spacing={1}>
                    <Grid item xs={10} md={6}>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="fromDate"
                                label="From Date"
                                type="datetime-local"
                                value={fromDate}
                                className={classes.textField}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleFromDateChange}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={10} md={6}>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="toDate"
                                label="To Date"
                                type="datetime-local"
                                value={toDate}
                                className={classes.textField}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleToDateChange}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={10}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.searchButton}
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <div className={classes.chartContainer}>
                    {/*<h3>Daily Views</h3>*/}
                    <ResponsiveContainer width={chartWidth} height={chartHeight}>
                        <LineChart data={ViewsPerDaysResponse?.Dt}>
                            <XAxis
                                dataKey="ViewDate"
                                label={{ value: 'Date', position: 'insideBottomRight', offset: -5 }}
                                tickFormatter={formatDateNoyear} // Format tick labels on X-axis
                            />
                            <YAxis  />
                            <CartesianGrid stroke="#eee" />
                            <Tooltip
                                formatter={(value) => new Intl.NumberFormat('en').format(value)}
                                labelFormatter={formatDate}
                            />  
                            <Legend />
                            <Line type="monotone" dataKey="ViewsPerDay" stroke="#8884d8" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                {/* Display total views */}
                <br/>
                <Typography variant="h6" className={classes.totalViewsLabel}>
                    Total Views: {totalViews}
                </Typography>
            </div>
        </div>
    );
};

export default DashboardViews;
