import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridCheckboxSelectionColDef,
} from '@material-ui/data-grid';
import { GetCategorys, DeleteCategory, DeleteCategoryImage, DeleteImage } from '../apis/CategoriesApis';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import DialogForCategory from '../components/DialogForCategory';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import AppConfig from '../AppSettings'; 

const restructureData = (data) => { 
  return data?.map((structure) => {
    structure.Image = {
      id: (structure.CatFileName ? structure.CatId : null),
      uri: AppConfig.ImageUrl + "/" + structure.CatFileName
    };
    structure.edit = structure.CatId;
    structure.delete = structure.CatId;
    structure.deleteimage = structure.CatId;
    return structure;
  })
} 
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useToolBarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function BasicTable() {
  const classes = useStyles();
  const ToolBarStylesClasses = useToolBarStyles();

  function CustomToolbar({ ArrayOfIds }) {
    return (
      <GridToolbarContainer className={ToolBarStylesClasses.root}>
        <Button style={{ color: green[500] }} startIcon={<AddIcon />} onClick={handleClickOpen}>Add</Button>
        <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => removeBulkCategory(ArrayOfIds)}></Button>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setopenSnackbar] = useState(false);
  const [CategoryId_Edit, setCategoryId_Edit] = useState("")
  const [Msg, setMsg] = useState("")
  const [ErrMsg, setErrMsg] = useState("")
  const [SnackBarColor, setSnackBarColor] = useState("green")
  const [OpenEditModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true);


  const handleCloseEditModal = () => {
    setOpenModal(false);
  };
  const handleClickOpenEditModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSnackbar = () => {
    setopenSnackbar(false);
  };

  const handleOpenSnackbar = (strMsg) => {
    setopenSnackbar(true);
    setMsg(strMsg)
  };
  useEffect(() => {
    getCategoriesAPI({});
  }, [])

  const getCategoriesAPI = async () => {
    const result = await GetCategorys({
      "RestId": localStorage.getItem("RestaurantId") 
    }); 
    const CnstErrorMsg = result?.Error?.Message;
    if (CnstErrorMsg)
      handleOpenSnackbar("Failed to load data, " + result?.Error?.Message)

    setErrMsg(result?.Error?.Message)
    if(result?.Dt)
    setData(restructureData(result?.Dt));
    // setMsg(result?.Error)
    setLoading(false);
  }

  const removeCategory = async (id) => {
    const Confirmation = window.confirm("Do you want to Delete?");
    if (Confirmation === true) {
      const ResDel = await DeleteCategory({ CatId: id });
      const CnstErrorMsg = ResDel?.Error?.Message;
      if (CnstErrorMsg) {
        handleOpenSnackbar("Failed to Delete cat, " + ResDel?.Error?.Message)
        setLoading(false);
      }
      else {
        setLoading(true);
        // const ImageId = (data.find(o => o.CatId === id)?.Image.id);
        // if (ImageId) {
        //   await (DeleteImage(({ RelatedId: ImageId,RelatedType:"Categories"  })))
        // }
        getCategoriesAPI();
        handleOpenSnackbar("Category Deleted");
      }
    }
  }



  const CallremoveCategoryFromBulk = async (id) => {
    const cnstRes = await DeleteCategory({ CatId: id });
    const CnstErrorMsg = cnstRes?.Error?.Message;
    if (CnstErrorMsg) {
      handleOpenSnackbar("Failed to load data, " + cnstRes?.Error?.Message)
      setLoading(false);
    } else {
      // const ImageId = (data.find(o => o.CatId === id)?.Image.id);
      // if (ImageId) {
      //   await (DeleteImage(({ RelatedId: ImageId ,RelatedType:"Categories" })))
      // }

        setLoading(true);
        handleOpenSnackbar("Category Deleted");
      getCategoriesAPI();
    }
  }
  function removeBulkCategory(id) {
    const Confirmation = window.confirm("Do you want to Delete them?");
    if (Confirmation === true && id.selectionModel?.length > 0) {

      let FirstId = id.selectionModel;
      if (FirstId) {
        FirstId.forEach(element =>

          CallremoveCategoryFromBulk(element));
      }
      setSelectedCategories([]);
    }
  }
  const DeleteCateImageFromGird = async CatId => {
    const Confirmation = window.confirm("Do you want to Delete image?");
    if (Confirmation == true) {
      setLoading(true);
      const ImgId = (data.find(o => o.CatId === CatId)?.Image.id);
      if (ImgId) {
        await DeleteImage({ RelatedId: ImgId,RelatedType:"Categories"  });
        getCategoriesAPI();
        handleOpenSnackbar("Category Image Deleted");
      }
      setLoading(false);
    }
  }
  const ButtonUpdateCategory = async CategorieId => {
    handleClickOpenEditModal();
    setCategoryId_Edit(CategorieId);
  }

  const columns = [
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return <Button size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => ButtonUpdateCategory(params.value)} ></Button>
      }
    },
    { field: 'Catid', headerName: 'Catid', width: 70, hide: true },
    { field: 'CatNameFirstLang', headerName: 'Name English', width: 250 },
    { field: 'CatNameSecondlang', headerName: 'Name SecLang', width: 250 },
      { field: 'OrderNbr', headerName: 'order', width: 110 },
      { field: 'IsCatVisible', headerName: 'Visible', width: 150 },
    {
      field: 'Image',
      headerName: 'Image',
      sortable: false,
      width: 250,
      renderCell: (params) => {
          const divStyle = {
              backgroundColor: 'rgb(36,90,118)', // Set the background color 
          };
          return <div style={divStyle}>
              {params.value?.uri?.includes('null') && !params.value?.uri?.includes('.')
                  ? <></>
                  : <img src={`${params.value.uri}`} alt="No IMG" width={50} height={50} />}
          </div>
      }
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />}

          onClick={() => { removeCategory(params.value) }} >
        </Button>
      }
    },
    {
      field: 'deleteimage',
      headerName: 'Delete Image',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return <Button size="small" style={{ backgroundColor: 'orange', color: 'white' }} startIcon={<DeleteForeverTwoToneIcon />}
          onClick={() => { DeleteCateImageFromGird(params.value) }} >
        </Button>
      }
    }
  ];
  const getRowId = (row) => row.CatId;
  return (
    <div style={{ height: 500, width: '100%' }}>
      <Helmet>
        <title>Categories</title>
      </Helmet>

      <div
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {loading && <CircularProgress size={75} thickness={5} style={{ 'color': 'black' }} />}
      </div>
      <DataGrid
        getRowId={getRowId}
        rows={data}
        columns={columns}
        pageSize={20}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedCategories(newSelectionModel);
        }}
        components={{
          Toolbar: () => <CustomToolbar ArrayOfIds={selectedCategories} />,
        }}
      />


      <DialogForCategory
        openModal={open}
        handleCloseModal={handleClose}
        getData={getCategoriesAPI}
        isUpdate={false}
        OpenSnackBarForMsg={handleOpenSnackbar}
      />

      {data[0] ?
        <DialogForCategory
          openModal={OpenEditModal}
          handleCloseModal={handleCloseEditModal}
          CategorieRow={data.find(o => o.CatId === CategoryId_Edit)}
          getData={getCategoriesAPI}
          isUpdate={true}
          OpenSnackBarForMsg={handleOpenSnackbar}
        /> : <></>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
        message={Msg}
        theme={{ colors: { surface: 'blue', accent: 'red' }, }}
      />


    </div>
  );
}