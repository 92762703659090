
import React, { useEffect, useState } from 'react';
import { GetRestaurantInfobyName } from '../apis/RestaurantAPI';
import { TestCnx } from '../apis/TestEstablishCnx';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MenuItems from './MenuItems';
import SubmitRate from '../components/SubmitRate';
import AddCallWaiter from '../components/AddCallWaiter';
import AboutRest from '../components/AboutRest';
import RestaurantSharpIcon from '@material-ui/icons/RestaurantSharp';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import Footer from '../components/Footer'
import Snackbar from '@material-ui/core/Snackbar';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SyncLoader from 'react-spinners/SyncLoader'
import AppConfig from '../AppSettings';
import moment from 'moment';
import RotateLoader from 'react-spinners/RotateLoader';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ChristmasTree from '../Collection/cute-christmas-tree.jpg';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { InsertDashboard } from '../apis/DashboardViews'; 
export default function Menu() {
    let history = useHistory();
    var { name } = useParams(); 

    // Check the current hostname
    const currentHostname = window.location.hostname;  
    //console.log("MenucurrentHostname", currentHostname)
    //check if URL is Hazeen website then add the name manually instead of getting it from URL.
    if (currentHostname === 'hazeencafe.com') {
        name = 'HazeenCafe';
    }

    const [ChecKRestNameToGiveSpecificStyleForLOGO, setChecKRestNameToGiveSpecificStyleForLOGO] = useState("Restimg-Position");
    const [CurentDate, setCurentDate] = useState("");
    const [RestaurantInfo, setRestaurantInfo] = useState([]);
    const [TabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [ErrMsg, setErrMsg] = useState("");
    const [openSnackbar, setopenSnackbar] = useState(false);
    const [RestName, setRestName] = useState("");
    const [VisibleSeasonThemes, setVisibleSeasonThemes] = useState(false);
    const [RestNameDescription, setRestNameDescription] = useState(name);
    const [LocationInRest, setLocationInRest] = useState(false);

    const [ColorsMenuState, setColorsMenuState] = useState([]);

    const restructureData = (data) => {
        if (data && data.Restaurant) {
            // Assign the first image
            data.Restaurant.Image = {
                uri: `${AppConfig.ImageUrl}/${data.Restaurant.RestFileName}`,
            };

            // Assign the second image (assuming you have the filename for the second image)
            data.Restaurant.Image2 = {
                uri: `${AppConfig.ImageUrl}/${data.Restaurant.RestFileName2}`,
            };
        } else {
            data.Image = null;
        }
        return data;
    };


    const handleOpenSnackbar = (strMsg) => {
        setopenSnackbar(true);
        setErrMsg('Internal Server Error \r\n' + strMsg)
    };
    const handleCloseSnackbar = () => {
        setopenSnackbar(false);
    };

    const TestEstablishConnex = () => {
        return TestCnx();
    } 


    const GetRestAPI = async () => {
        const result = await GetRestaurantInfobyName(name);

        const DashboardParams = {
            RestId: result?.Restaurant?.RestID
        }; 
        if (result?.Restaurant?.RestID>0)
        InsertDashboard(DashboardParams); 


        const CnstErrorMsg = result?.Error?.Message;
        if (CnstErrorMsg)
            handleOpenSnackbar("Failed to load data, " + result?.Error?.Message)

        const ResInfoRest = await restructureData(result) 
        setRestaurantInfo(ResInfoRest);
        setRestName(ResInfoRest?.Restaurant?.Name)
        setColorsMenuState(ResInfoRest?.Restaurant?.EMenuColorCustomization) 
        setLoading(false);
    }
     

    useEffect(() => { 
        //const FullURL = window.location.href;  

        //if (!window.location.search.includes('location')) {
        //    // Redirect only if 'location' is not present
        //    window.location.href = 'http://localhost:3000/Menu/Saj_W_Ma_Saj?location';
        //}

        TestEstablishConnex(); 
      
        const urlParams = new URLSearchParams(window.location.search);
        const locationParam = urlParams.get('location');

        if (locationParam === 'InRest') {
            setLocationInRest(true)
        }


        //set specific css for logo rest
        if (name === "PAZZA") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_PAZZA")
            setRestNameDescription('PAZZA')
        }
        //set specific css for logo rest
        if (name === "Achta-W-Assal") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_AchtawAssal")
            setRestNameDescription('Achta & Asal')
        }
        else if (name === "SkyLine") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_SkyLineRestoCafe")
            setRestNameDescription('SkyLine Resto-Cafe')
        }
        else if (name === "Sewar") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_SewarRestoCafe")
            setRestNameDescription('Sewar Resto-Cafe')
        }
        else if (name === "Saha-W-Hana") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_Saha-W-Hana-FornHara")
            setRestNameDescription('Forn Saha W Hana')
        }
        else if (name === "Saj_W_Ma_Saj") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_SajWmaSaj")
            setRestNameDescription('Saj W Ma Saj Restaurant')
        }
        else if (name === "nostalgie") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_Nostalgie")
            setRestNameDescription('Cafe Nostalgie')
        }
        else if (name === "HazeenCafe") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_HazeenCafe")
            setRestNameDescription('Hazeen Cafe')
        }
        else if (name === "ShoufHighland") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_ShoufHighLand")
        }
        else if (name === "Mhanna") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_MhannaTyr")
        }
        else if (name === "Arena") {
            setChecKRestNameToGiveSpecificStyleForLOGO("Restimg-Position_Arena")
        }
        else if (name === "WakaWaka") {
            setRestNameDescription('WakaWaka Restaurant')
        }
        else if (name === "king'sFood") {
            setRestNameDescription('king Food Restaurant')
        }

        if (VisibleSeasonThemes) {
            const timeoutId = setTimeout(() => {
                setVisibleSeasonThemes(false);
            }, 3000);
            return () => {
                return clearTimeout(timeoutId);
            };
        }

        GetRestAPI();

        //i install pack to a flexible date
        const formattedTodayDate = moment().format('YYYY-MM-DD');
        setCurentDate(formattedTodayDate);
         
      

        // navigator.geolocation.getCurrentPosition(function(position) {
        //   console.log("Latitude is :", position.coords.latitude);
        //   console.log("Longitude is :", position.coords.longitude);
        // });



        //it's mandatory to put VisibleSeasonThemes, cause i need to render and let's react to pass to useeffect when VisibleSeasonThemes changed to false to execute the old code
    }, [VisibleSeasonThemes])

    const handleTabs = (e, val) => {
        setTabValue(val);
    }
    const LoginFunc = () => {
        history.push('/Login');
    }

    const FestiveStyle = {

        fontFamily: 'cursive'
    };

    const containerStyle = {
        opacity: '5.50',
        backgroundColor: '#e22e2e', // Red background
        color: '#ffffff', // White text
        fontFamily: 'Arial, Helvetica, sans-serif', // A festive font
        padding: '20px',
        textAlign: 'center',
    };

    const headerStyle = {
        fontSize: '2em',
        marginBottom: '20px',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
    };


    const StyleWallPaperBGColor = {
        backgroundColor: RestaurantInfo?.Restaurant?.EMenuColorCustomization?.WallpaperBackgroundBgColor || 'rgb(28, 69, 89)',
    }; 


    return (
        <div>
            <div
                style={{
                    position: 'absolute', left: '50%', top: '30%',
                    transform: 'translate(-50%, -50%)'
                }}>
                {loading &&

                    <div>
                        <RotateLoader size={30} loading={loading} margin={10} color={'#36d7b7'}></RotateLoader></div>
                    //<div className="loader-container">
                    //    {loading ? (
                    //        <div className="logo-loader">

                    //            <img src="https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX41658305.jpg" alt="LogoImg" />
                    //            <svg
                    //                xmlns="http://www.w3.org/2000/svg"
                    //                viewBox="0 0 24 24"
                    //                width="64"
                    //                height="64"
                    //                fill="currentColor"
                    //            >
                    //            </svg>
                    //        </div>
                    //    ) : (
                    //            <div>

                    //            <p>Data loaded successfully!</p>
                    //        </div>
                    //    )}
                    //</div>


                }
            </div>
            {VisibleSeasonThemes && (
                <div style={containerStyle}>
                    <header style={headerStyle}>
                        {RestNameDescription} wishes you a Merry Christmas !</header>

                    <p style={FestiveStyle}>Wishing you a joyful and festive holiday season & Happy New Year!</p>
                    <img
                        src={ChristmasTree}
                        alt="Christmas"
                        style={imageStyle}
                    />
                </div>
            )}
            <div>
                {RestName ? <>
                    <Helmet>
                        <title>{RestName + ` Menu`} </title>
                    </Helmet>


                    {CurentDate > RestaurantInfo?.Restaurant?.StopDate_Dev ?
                        <>
                            <h1>there is no info in this site,contact us to renew the license</h1>
                            <p className="AllignFooter" style={{ fontSize: 15 }}> Developed by  <a href={"https://originssoftwares.com/OriginSoft"} rel="noreferrer" target="_blank" >
                                Origin  </a></p>
                        </>
                        :
                        <>
                            <div>
                                {RestaurantInfo.Restaurant ?
                                    <img src={RestaurantInfo.Restaurant.Image?.uri} alt="LogoImg" className={ChecKRestNameToGiveSpecificStyleForLOGO} /> : <><p className="ParagraphTitle">{RestaurantInfo?.Restaurant?.Name}</p></>
                                }
                                {/* Customize text on the image  */}
                                {RestName === "SkyLine" ?
                                    <div class="SkyLineRestoCafe-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri} alt="LogoImg" className="SkyLineRestoCafe-block" />
                                    </div> :
                                    <></>}

                                {RestName === "Sewar" ?
                                    <div class="SewarRestoCafe-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri} alt="LogoImg" className="SewarRestoCafe-block" />
                                    </div> :
                                    <></>}
                               {RestName === "PAZZA" ?
                                    <div class="PAZZA-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri} alt="LogoImg" className="PAZZA-block" />
                                    </div> :
                                    <></>}
                                {RestName === "Saha-W-Hana" ?
                                    <div class="Saha-W-hanaFornhara-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri} alt="LogoImg" className="Saha-W-hanaFornhara-block" />
                                    </div> :
                                    <></>}
                                {RestName === "Achta-W-Assal" ?
                                    <div class="Achta-W-Assal-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri}  alt="LogoImg" className={ChecKRestNameToGiveSpecificStyleForLOGO} />
                                    </div> : 
                                    <></>}
                                {RestName === "HazeenCafe" ?
                                    <div class="HazzenCafe-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri} alt="LogoImg" className={ChecKRestNameToGiveSpecificStyleForLOGO} />
                                    </div> : <></>}
                                {RestName === "nostalgie" ?
                                    <div class="Nostalgie-block">
                                        <img src={RestaurantInfo.Restaurant.Image2?.uri}  alt="LogoImg" className={ChecKRestNameToGiveSpecificStyleForLOGO} />

                                    </div> : <></>}
                                {RestName === "ShoufHighland" ?
                                    <div class="ShoufHighLand-block">
                                        <img alt="LogoImg" src='https://menu-backend-vcvpn.ondigitalocean.app/uploads/' className={ChecKRestNameToGiveSpecificStyleForLOGO} />
                                    </div> : <></>}
                                {RestName === "Mhanna" ?
                                    <div class="Mhannatext-block">
                                        <h4>استراحة مهنا</h4>
                                        <p>شاطئ صور-خيمة رقم 36</p>
                                    </div> : <></>}
                            </div>
                            <Tabs value={TabValue} onChange={handleTabs} className="Tabs">

                                <Tab icon={<RestaurantSharpIcon />} label="Menu"  ></Tab> 

                                {RestaurantInfo?.Restaurant?.RestInfoShow === "True" ?
                                    <Tab icon={<InfoOutlinedIcon />} label="Info"   ></Tab> : <></>} 

                                <Tab icon={<FeedbackOutlinedIcon />} label="Rate"   ></Tab>

                                {LocationInRest && RestaurantInfo?.Restaurant?.CallWaiterShow === "True" ?
                                    <Tab icon={<NotificationsActiveOutlinedIcon   />} label="Waiter" ></Tab>: <></>} 

                                <Tab icon={<PersonIcon />} label="login" onClick={LoginFunc}  ></Tab>
                            </Tabs>
                            <TabPanel value={TabValue} index={0} RestInf={RestaurantInfo} ><MenuItems RestInf={RestaurantInfo} /></TabPanel>
                            <TabPanel value={TabValue} index={1} RestInf={RestaurantInfo}><br /><AboutRest RestInf={RestaurantInfo} /></TabPanel>
                            <TabPanel value={TabValue} index={2} RestInf={RestaurantInfo}><br /><SubmitRate RestInf={RestaurantInfo} /></TabPanel>
                            <TabPanel value={TabValue} index={3} RestInf={RestaurantInfo}><br /><AddCallWaiter RestInf={RestaurantInfo} /></TabPanel>
                        </>
                    }
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={openSnackbar}
                        autoHideDuration={5000}
                        onClose={handleCloseSnackbar}
                        message={ErrMsg}
                        theme={{ colors: { surface: 'blue', accent: 'red' }, }}
                    />
                </> : <></>}
            </div>
        </div>
    );
    function TabPanel(props) {

        const { children, value, index, RestInf } = props;
        return (
            <div>{
                value === index && (
                    <div style={StyleWallPaperBGColor} ><br />{children}<br />
                        <Footer RestaurantInfo={RestInf} />
                    </div>
                )

            }
            </div>
        )
    }

}

