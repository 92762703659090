
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { GetRestInfo } from '../apis/RestInfoAPI';
import React, { useEffect, useState } from 'react';
import AppConfig from '../AppSettings';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { DeleteRestInfoDesc, GetRestInfoDesc, InsertRestInfoDesc } from '../apis/RestInfoDescAPI';
import { GetRestInfoWifi } from '../apis/RestInfoWifiAPI';
import { GetRestInfoDayTime } from '../apis/RestInfoDayTimeAPI';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WifiPassword from '@material-ui/icons/Wifi';


import TodayIcon from '@material-ui/icons/Today';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { GetCodeSymbol } from '../apis/OwnLookUpAPI';
import RotateLoader from 'react-spinners/RotateLoader';
import ModalImage from '../components/ModalImage';

// JavaScript source code
export default function AboutRest(props) {
    const { RestInf } = props;

    const [IsShowTextDescriptionAboutRestVisible, setIsShowTextDescriptionAboutRestVisible] = useState(false);
    const [IsShowImageAboutRestVisible, setIsShowImageAboutRestVisible] = useState(false);
    const [IsShowDaysHoursAboutRestVisible, setIsShowDaysHoursAboutRestVisible] = useState(false);
    const [IsShowWifiInfoAboutRestVisible, setIsShowWifiInfoAboutRestVisible] = useState(false);

    const [imageUrl, setimageUrl] = useState("");
    const [Rows, setRows] = useState([]);
    const [OpenVireOrder, setOpenVireOrder] = React.useState("");
    const [RestInfoDesc, setRestInfoDesc] = useState("")
    const [RestInfoWifi, setRestInfoWifi] = useState("")
    const [RestInfoDayTime, setRestInfoDayTime] = useState([])
    const [RestName, setRestName] = useState("")
    const [loading, setLoading] = useState(true);
    const [isExpanded, setExpanded] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [ImageURLMaximize, setImageURLMaximize] = useState("");
    const [ImageDescMaximize, setImageDescMaximize] = useState("");

    const handleClickOpenOrder = (event) => {
        setOpenVireOrder(true);
    };

    const handleCloseOrder = () => {
        setOpenVireOrder(false);
    };


    useEffect(() => {
        getRestInfoAPI();
        getRestInfoDescAPI();
        getRestInfoDayTimeAPI();
        getRestInfoWifiAPI();
        setRestName(RestInf?.Restaurant?.Name);
        GetSettingsOwnLookUpToShowAndhideComponents();
        setLoading(false);
    }, [])

    const GetSettingsOwnLookUpToShowAndhideComponents = async () => {
        const Res1 = await GetCodeSymbol({
            "RestId": RestInf?.Restaurant?.RestID,
            TABID: "ShowTextDescriptionAboutRest",
        });
        const Res2 = await GetCodeSymbol({
            "RestId": RestInf?.Restaurant?.RestID,
            TABID: "ShowImageAboutRest",
        });

        const Res3 = await GetCodeSymbol({
            "RestId": RestInf?.Restaurant?.RestID,
            TABID: "ShowDaysHoursAboutRest",
        });
        const Res4 = await GetCodeSymbol({
            "RestId": RestInf?.Restaurant?.RestID,
            TABID: "ShowWifiInfoAboutRest",
        });
        setIsShowTextDescriptionAboutRestVisible(Res1);
        setIsShowImageAboutRestVisible(Res2);
        setIsShowDaysHoursAboutRestVisible(Res3);
        setIsShowWifiInfoAboutRestVisible(Res4);
    }

    const getRestInfoAPI = async () => {
        const result = await GetRestInfo({
            "RestId": RestInf?.Restaurant?.RestID,
        });
        setRows(result?.Dt)
    }
    const getRestInfoDescAPI = async () => {
        var result = await GetRestInfoDesc({
            "RestId": RestInf?.Restaurant?.RestID,
        });
        if (result?.Dt && result?.Dt[0]) {
            setRestInfoDesc(result?.Dt[0]?.RestInfoDescText)
        }
    }
    const getRestInfoDayTimeAPI = async () => {
        var result = await GetRestInfoDayTime({
            "RestId": RestInf?.Restaurant?.RestID,
        });
        if (result?.Dt && result?.Dt[0]) {
            setRestInfoDayTime(result?.Dt)
        }
    }
    const getRestInfoWifiAPI = async () => {
        var result = await GetRestInfoWifi({
            "RestId": RestInf?.Restaurant?.RestID,
        });
        if (result?.Dt && result?.Dt[0]) {
            setRestInfoWifi(result?.Dt[0]?.RestInfoWifiText)
        }
    }

    const imageClick = (ImgParamURL, ImgDesc) => {

        setImageDescMaximize(ImgDesc)
        setImageURLMaximize(ImgParamURL)
        setModalOpen(true);

        //handleClickOpenOrder();
        //setIsBigImageVisible(true);
        //setimageUrl(ImgParamURL);
    }

    const ImagePopup = ({ imageUrl, onClose }) => {
        return (
            <div className="image-cover-container">
                <img className="cover-image" alt="Maximized" src={imageUrl} />
            </div>
        );
    };


    const handleExpandClick = () => {
        setExpanded(!isExpanded);

    }

    const cardStyle = {
        border: '1px solid white',
        borderRadius: '8px',
        marginBottom: '20px',
        backgroundColor: '#f9f9f9',
        color: '#333',
        textAlign: 'center', // Set the text alignment (you can use 'left', 'right', 'center', or 'justify') 
        whiteSpace: 'pre-line',
        fontFamily: 'Roboto, sans-serif',
    };

    const StyleTitleAbout = {
        fontFamily: 'Oswald, serif',
        fontSize: '36px', // Adjust the size based on your preference
        fontWeight: 'bold',
        letterSpacing: '1px', // Add letter spacing for emphasis
        color: 'rgb(28, 69, 89)',
        borderRadius: '4px', // Optional: Add rounded corners
    };
    const StyleHeaderTimeTable = {
        textAlign: 'center',
        fontFamily: 'Oswald, serif',
        fontSize: '21px', // Adjust the size based on your preference
        fontWeight: 'bold',
        letterSpacing: '1px', // Add letter spacing for emphasis
        color: 'rgb(28, 69, 89)',
        borderRadius: '4px', // Optional: Add rounded corners
    };
    const StyleBodyTimeTable = { 
        textAlign: 'center' ,
        fontFamily: 'Oswald, serif',
        fontSize: '15px', // Adjust the size based on your preference
        fontWeight: 'bold',
        color: 'rgb(28, 69, 89)',
        borderRadius: '4px', // Optional: Add rounded corners
        maxWidth: '110px'
    };



    const formatTime = (timeString) => {
        if (timeString) {
            const [hours, minutes] = timeString.split(':');
            if (hours && minutes) {
                const time = new Date(2023, 0, 1, parseInt(hours, 10), parseInt(minutes, 10));
                return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            }
        }
        return '--';
    };
    const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });

    const borderColor = 'rgb(28, 69, 89)';
    return (
        <div>

            {/*<div*/}
            {/*    style={{*/}
            {/*        position: 'absolute', left: '50%', top: '30%',*/}
            {/*        transform: 'translate(-50%, -50%)'*/}
            {/*    }}>*/}
            {/*    {loading &&*/}
            {/*        <div>*/}
            {/*            <RotateLoader size={30} loading={loading} margin={10} color={'#36d7b7'}></RotateLoader></div>*/}
            {/*    }*/}
            {/*</div>*/}

            {/*for text*/}
            {IsShowTextDescriptionAboutRestVisible && (
                <Card style={cardStyle}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom style={StyleTitleAbout} >
                            About {RestName}
                        </Typography>
                        {/* Add more content or customize as needed */}
                    </CardContent>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        {/* Additional content that will be collapsed */}
                        <CardContent>
                            <Typography  >
                                <div className="RestInfoDescTextStyle">  {RestInfoDesc}</div>
                            </Typography>
                        </CardContent>
                    </Collapse>
                    <IconButton onClick={handleExpandClick}>
                        <div className='ColorTextLang'>{isExpanded ? 'Collapse' : 'Expand'}  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
                    </IconButton>
                </Card>
            )}
            {/*when user click on the img*/}
            {/*<Menu*/}
            {/*    transformOrigin={{ horizontal: 'center', vertical: 'top' }}*/}
            {/*    id="simple-menu"*/}
            {/*    OpenVireOrder={OpenVireOrder}*/}
            {/*    open={Boolean(OpenVireOrder)}*/}
            {/*    onClose={handleCloseOrder} >*/}
            {/*    <Button className="CatPosonTop" variant="contained" color="secondary" endIcon={<CloseOutlinedIcon />} size="small" onClick={() => handleCloseOrder()}>*/}
            {/*        Close*/}
            {/*    </Button>*/}
            {/*    <ImagePopup imageUrl={imageUrl} />*/}
            {/*</Menu>*/}

            {/*for imgs*/}
            {IsShowImageAboutRestVisible && (
                //style={{ margin: '0px' }} it prevent the scroll to the left and right cause when i opened the css in browser , i see it take -2 px
                <ImageList style={{ margin: '0px' }} sx={{ width: 500, height: 450 }}>
                    {Rows?.map((item) => (
                        <ImageListItem key={item.RestInfoFileName}>
                            <img
                                onClick={() => imageClick(AppConfig.ImageUrl + "/" + `${item.RestInfoFileName}`, item.RestInfoImgDesc)}
                                srcSet={AppConfig.ImageUrl + "/" + `${item.RestInfoFileName}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={AppConfig.ImageUrl + "/" + `${item.RestInfoFileName}?w=248&fit=crop&auto=format`}
                                alt={item.RestInfoImgDesc}
                                loading="eager"
                            />
                            {/*for description*/}
                            {item.RestInfoImgDesc ?
                                <ImageListItemBar
                                    title={item.RestInfoImgDesc}
                                //subtitle={<span>{item.RestInfoImgDesc}</span>}
                                />
                                : <></>}
                        </ImageListItem>
                    ))}
                </ImageList>
            )}
            <br />
            {IsShowDaysHoursAboutRestVisible && (
                <div className="OpeningHoursDiv">
                    <TableContainer component={Paper} style={cardStyle} >
                        <Table >
                            <TableHead >
                                <TableRow  >
                                    <TableCell style={StyleHeaderTimeTable}>
                                        <TodayIcon />
                                        Day</TableCell>
                                    <TableCell style={StyleHeaderTimeTable} >
                                        <AccessTimeIcon />
                                        Opening
                                    </TableCell>
                                    <TableCell style={StyleHeaderTimeTable} >
                                        <AccessTimeIcon />
                                        Closing
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {RestInfoDayTime.map((day, index) => (
                                    <TableRow key={index} style={{ backgroundColor: currentDay === day.Day ? 'rgb(252, 177, 3)' : '' }}>
                                        <TableCell style={StyleBodyTimeTable}>{day.Day}</TableCell>
                                        <TableCell style={StyleBodyTimeTable}>
                                            {formatTime(day.OpeningHour)}
                                        </TableCell>
                                        <TableCell style={StyleBodyTimeTable}>
                                            {formatTime(day.ClosingHour)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
            {IsShowWifiInfoAboutRestVisible && (
                <div className="wifi-password-container">
                    <div className="wifi-password-container1">
                        <WifiPassword className="wifi-icon" style={{ fontSize: 35, marginBottom: '7px', marginRight:'5px' }}>  </WifiPassword>
                        <h4>Wifi Details</h4>
                    </div>
                    <p>
                        <strong>{RestInfoWifi}</strong>
                    </p>
                </div>
            )}

            {isModalOpen && (
                <ModalImage
                    OpenModal={isModalOpen}
                    ImageURL={ImageURLMaximize}
                    ImageDesc={ImageDescMaximize}
                    HandleCloseImage={(event) => {
                        setModalOpen(false);
                    }
                    } ></ModalImage>
            )}
        </div>
    );


}
