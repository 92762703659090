import React, { useEffect, useState } from 'react';
import { fade, withStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Badge,
    MenuItem,
    Menu
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Link, useHistory, useLocation } from "react-router-dom"; 
import { GetNotificationPerUser,UpdateNotification } from '../apis/NotificationAPI';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Popover from '@material-ui/core/Popover'; 
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = (theme) => ({
    AppBarCSS: {
        backgroundImage: `url(${require("../Collection/headerbgmaing2.png").default})`, 

},
    grow: {
        flexGrow: 1 
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        //display: "none",
        //[theme.breakpoints.up("sm")]: {
        //    display: "block"
        //}
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    },
    sectionDesktop: {
        //display: "none",
        //[theme.breakpoints.up("md")]: {
        //    display: "flex"
        //}
    },
    sectionMobile: {
        //display: "flex",
        //[theme.breakpoints.up("md")]: {
        //    display: "none"
        //}
    }
});


const ToolbarComponent = (props) => { 
    const [notificationDBResponse, setnotificationDBResponse] = useState([]);
    const [NotificationUnreadCount, setNotificationUnreadCount] = useState(0);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

    const handleClick = (event) => {
        setNotificationUnreadCount(0);

        const notifIdsString = notificationDBResponse?.Dt?.map(notification => notification.NotifId).join(','); 
        const PayloadUpdatenotif = {
            NotifIds: notifIdsString
        }; 
        if (notificationDBResponse?.Dt.length>0)
        UpdateNotification(PayloadUpdatenotif)

        setNotificationAnchorEl(notificationAnchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setNotificationAnchorEl(null);
    };

    async function GetNotifications() {
        const PayloadGetnotif = {
            //IsRead:'0',
            UserId: localStorage.getItem("UserId"),
            RestId: localStorage.getItem("RestaurantId"),
        };
         

        const CnstNotif = await GetNotificationPerUser(PayloadGetnotif); 
        setNotificationUnreadCount(CnstNotif?.Dt?.filter(notification => notification.IsRead === false).length);
        setnotificationDBResponse(CnstNotif);
    }  


    useEffect(() => {
   
        GetNotifications();
    }, []); 

     
    const history = useHistory(); 

    const [anchorEl, setAnchorEl] = useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);

    const handleProfileMenuOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMobileMenuOpen = (e) => {
        setMobileMoreAnchorEl(e.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
    };
    const RedirectFct = (route) => {
        history.push(route);
    };
    const RedirectFctWithClose = (route) => {
        handleClose();
        history.push(route);
    };

    const { classes } = props;

    const menuId = "primary-search-account-menu";

    function logOut() {
        history.push("login");       
        localStorage.clear();
    }

    const RedirectURL = (route) => {
        handleMenuClose();
        history.push(route); 
    };


    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={anchorEl}
            onClose={handleMenuClose} >
            <MenuItem onClick={() => RedirectURL('ChangePassword')} ><LockOpenIcon></LockOpenIcon>Profile</MenuItem> 
            {/*<MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
            <MenuItem onClick={logOut}><ExitToAppOutlinedIcon></ExitToAppOutlinedIcon>LogOut</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={mobileMoreAnchorEl}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );
    const openNotif = Boolean(notificationAnchorEl);
    const idNotif = openNotif ? 'notification-popover' : undefined;


    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.AppBarCSS}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.openDrawerHandler}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap onClick={() => RedirectFct('/Dashboard')} >
                        {props.RestName}  
                    </Typography>
                    {/*<div className={classes.search}>*/}
                    {/*    <div className={classes.searchIcon}>*/}
                    {/*        <SearchIcon />*/}
                    {/*    </div>*/}
                    {/*    <InputBase*/}
                    {/*        placeholder="Search�"*/}
                    {/*        classes={{*/}
                    {/*            root: classes.inputRoot,*/}
                    {/*            input: classes.inputInput*/}
                    {/*        }}*/}
                    {/*        inputProps={{ "aria-label": "search" }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="secondary">*/}
                        {/*        <MailIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/} 
                        <IconButton aria-label="show  new notifications" color="inherit" onClick={handleClick}>
                        {/*get count of unread msg*/}
                            <Badge badgeContent={NotificationUnreadCount} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <Popover 
                            id={idNotif}
                            open={openNotif}
                            anchorEl={notificationAnchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {/*<Typography sx={{ p: 2 }}> Notifications</Typography>*/}
                            <List style={{ padding: 0 }}>
                                {notificationDBResponse?.Dt?.map((notification, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => RedirectFctWithClose(notification?.OnClickRedirectTo)}
                                        style={{
                                            backgroundColor: notification?.IsRead === false ? '#f9f9f9' : 'transparent', // Highlight unread notifications
                                            borderRadius: '8px', // Rounded corners
                                            margin: '8px 0', // Add vertical margin
                                            padding: '12px 16px', // Add padding
                                            cursor: 'pointer', // Pointer cursor
                                            transition: 'background-color 0.3s', // Smooth transition
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add shadow for depth
                                            borderLeft: notification?.IsRead === false ? '4px solid #ff5722' : 'none', // Add left border for unread notifications
                                        }}
                                    >
                                        <ListItemText
                                            primary={notification?.MessageNotif}
                                            secondary={new Date(notification?.CreationDateTime).toLocaleString()} // Add the date here
                                            primaryTypographyProps={{
                                                style: {
                                                    fontWeight: notification?.IsRead === false ? 'bold' : 'normal', // Bold font weight for unread notifications
                                                    color: notification?.IsRead === false ? '#333' : '#666', // Darker color for unread notifications
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Popover>


                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                    {/*<div className={classes.sectionMobile}>*/}
                    {/*    <IconButton*/}
                    {/*        aria-label="show more"*/}
                    {/*        aria-controls={mobileMenuId}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        onClick={handleMobileMenuOpen}*/}
                    {/*        color="inherit"*/}
                    {/*    >*/}
                    {/*        <MoreIcon />*/}
                    {/*    </IconButton>*/}
                    {/*</div>*/}
                </Toolbar>
            </AppBar>
            {/*{renderMobileMenu}*/}
            {renderMenu}
        </div>
    );
};

export default withStyles(styles)(ToolbarComponent);
