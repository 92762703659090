import axios from 'axios'
import AppConfig from '../AppSettings';

export const GetColorRest = async (data) => {
    try {
        const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl + `/MenuColorCustomization/GetColorCustomization`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const InsertColorRest = async (data) => {
    try { 
        const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl + `/MenuColorCustomization/InsertColorCustomization`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const DeleteColorRest = async (data) => {
    try { 
        const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl + `/MenuColorCustomization/DeleteColorCustomization`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}