import AppConfig from '../AppSettings';

export const TestCnx = async (data) => {
    try {
        const response = await fetch(AppConfig.apiUrl + `/TestEstablishCnx/TestCnx`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result = await response.text(); 
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}