import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { InsertComent } from '../apis/CommentsAPI';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DisplayAlert from '../components/DisplayAlert'
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SubmitRate(RestInf) {
    // console.log("RESTTTTTTETET",RestInf)

    const [isEnglish, setIsEnglish] = useState();

    const [openAlert, setopenAlert] = useState(false)
    const [AlertMessage, setAlertMessage] = useState("")

    const [value, setValue] = useState(4);
    const [Comment, setComment] = useState("");
    const [ClientName, setClientName] = useState("");
    const [ClientPhoneNumber, setClientPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const CheckDefaultLang = async () => {
        if ((RestInf.RestInf?.DefaultLanguage)?.includes('Ar')) {
            setIsEnglish(false)
        }
        else {
            setIsEnglish(true)
        }
    }
    useEffect(() => {
        CheckDefaultLang();
    }, [RestInf.RestInf?.DefaultLanguage])

    const handleColseAlert = () => {
        setopenAlert(false);
    };

    const ClearFields = () => {
        setValue(4);
        setComment("");
        setClientName("");
        setClientPhoneNumber("");
        setLoading(false);
    };

    const TextAreaStyle = {
        width: '73%',
        height: 65,
        maxHeight: 200,
        maxWidth: 500
    }
    const TextAreaStyleReg = {
        width: '50%',  
        maxWidth: 300
    }

    const submit = async (data) => {
        if (value && Comment && ClientName && ClientPhoneNumber) {
            await insert();
        }
        else {
            setopenAlert(true);
            setAlertMessage("Rate,Comment,Name and phone are required");
        }
    }
    const insert = async () => {
        setLoading(true);
        await InsertComent({
            RestId: RestInf?.RestInf?.Restaurant?.RestID,
            Rating: value,
            Description: Comment,
            CustName: ClientName,
            CustPhone: ClientPhoneNumber,
        })
        ClearFields();
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(-2),
            },
        },
    }));
    const classes = useStyles();
    return (
        <div>
            {/* <div className={classes.root} >
                <ButtonGroup size="medium" color="primary" aria-label="large outlined primary button group" className="btnGrpTranslation">
                    <Button className="btnArTrans" onClick={() => setIsEnglish(false)}>العربية</Button>
                    <Button className="btnEngTrans" onClick={() => setIsEnglish(true)}>English</Button>
                </ButtonGroup>
            </div> */}
            {/* <br /> */}
            <form>

                <div className="footerRate">
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '100%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
                    </div>
                    {/* <p ><h5> {isEnglish ? "We appreciate your comments" : "نحن نقدر تعليقاتك"} </h5></p> */}
                    {/* <br/> */}
                    <Box component="fieldset" mb={1} borderColor="transparent" style={{ 'color': 'White' }}>
                        <Typography component="legend" >{isEnglish ? <h5>please rate your overall service experience</h5> : <h5>please rate your overall service experience</h5>}</Typography>
                        <Rating
                            name="simple-controlled"
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                        />
                    </Box>
                    <TextareaAutosize
                        style={TextAreaStyle}
                        aria-label="maximum height"
                        placeholder={isEnglish ? "Comment *" : "Comment *"}
                        required
                        value={Comment}
                        onChange={(event) => setComment(event.target.value)}
                    />
                    <br />
                    <TextareaAutosize
                        style={TextAreaStyleReg}
                        margin="dense"
                        id="txtclientnm"
                        placeholder={isEnglish ? "Name *" : " Name *"}
                        value={ClientName}
                        onChange={(event) => setClientName(event.target.value)}
                        required
                    />
                    <br />
                    <TextareaAutosize
                        style={TextAreaStyleReg}
                        margin="dense"
                        id="txtclientphone"
                        placeholder={isEnglish ? "Phone or Email *" : " Phone or Email *"}
                        value={ClientPhoneNumber}
                        onChange={(event) => setClientPhoneNumber(event.target.value)}
                        required
                    />
                    <br />
                    <br />
                    <Button onClick={(e) => submit()} color="secondary" style={{ 'color': 'White', 'border': '1px solid #ddd' }}>
                        {isEnglish ? "Submit" : "Submit"}
                    </Button>
                    <DisplayAlert open={openAlert} message={AlertMessage} close={handleColseAlert} />
                </div>
            </form>
        </div>
    )
}
