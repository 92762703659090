import IndexNavbar from  '../components/IndexNavbar';
import IndexHeader from '../components/IndexHeader';
import React from 'react';
import { Helmet } from 'react-helmet';

function Index() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("index");
      return function cleanup() {
        document.body.classList.remove("index");
      };
    });
    return ( 
      <div>
        <Helmet>
      <title>Origins Softwares</title>
    </Helmet>
      <>
        <IndexNavbar />
                <IndexHeader />
                <header>
                    <h1>QRMenu Service</h1>
                    <p>Revolutionizing menu access with QR codes</p>
                </header>

                <section>
                    <h2>Key Features</h2>
                    <ul>
                        <li>Seamless Menu Access</li>
                        <li>Customization Options</li>
                        <li>Mobile-Friendly</li>
                    </ul>
                </section>

                <section>
                    <h2>How It Works</h2>
                    <p>
                        1. Scan the QR code with your mobile device.<br />
                        2. Explore the menu options.<br />
                        3. Enjoy a hassle-free experience!
                    </p>
                </section>

                <section>
                    <h2>Benefits for Businesses</h2>
                    <ul>
                        <li>Efficiency and Cost-Effectiveness</li>
                        <li>Improved Customer Experience</li>
                    </ul>
                </section>

                <section>
                    <h2>Benefits for Users</h2>
                    <ul>
                        <li>Quick Access to Menus</li>
                        <li>Reduced Physical Contact</li>
                        <li>Convenience</li>
                    </ul>
                </section>

                <section>
                    <h2>Frequently Asked Questions</h2>
                    <ul>
                        <li>How do I get started with QRMenu?</li>
                        <li>Can I customize the appearance of my QRMenu?</li>
                        {/* Add more FAQs as needed */}
                    </ul>
                </section>

                <section>
                    <p>Ready to enhance your menu experience with QRMenu?</p>

                </section>
       
      </>
      </div>
    );
  }
  
  export default Index;
  