import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Grid, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { FormattingPrice } from '../components/GeneralFunctions';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        height: 'auto',
        width: '800px',
        backgroundColor: '#F5F5F5',
    },
    totalPriceViewOrder: {
        padding: theme.spacing(1),
        textAlign: 'right',
        fontWeight: 'bold',
    },
    button: {
        minWidth: 'auto',
        padding: theme.spacing(1),
    },
}));

export default function ViewOrder(props) {
    const classes = useStyles();
    const [ItemsToDisp, setItemsToDisp] = useState([]);
    const [TotalPrice, setTotalPrice] = useState(0);

    const {
        Language: LangisEng,
        ItemsIds: Items,
        RestInformation: RestInfo,
        ItemsWithQty,
        Delimiters,
        NbrAfterDelimiters,
        Curr,
        onItemClickWithoutClose,
    } = props;

    const handleCloseOrderWithoutClose = (array) => {
        onItemClickWithoutClose(array);
    };

    const columns = [
        { field: 'ItemId', headerName: 'ID', hide: true },
        {
            field: LangisEng ? "ItemNameFirstLang" : "ItemNameSecondlang",
            headerName: 'Item Name',
            width: 130,
            editable: false,
        },
        {
            field: 'Minus',
            align: "left",
            headerName: ' ',
            sortable: false,
            width: 30,
            renderCell: (params) => (
                <Button size="small" className={classes.button} startIcon={<RemoveCircleOutlineIcon />} onClick={() => BtnMinus(params.row)} />
            ),
        },
        {
            field: 'Qty',
            headerName: 'Qty',
            sortable: false,
            width: 25,
        },
        {
            field: 'Plus',
            align: "right",
            headerName: ' ',
            sortable: false,
            width: 35,
            renderCell: (params) => (
                <Button size="small" className={classes.button} startIcon={<AddCircleOutlineIcon />} onClick={() => BtnPlus(params.row)} />
            ),
        },
        {
            field: 'Pricing',
            headerName: 'Pricing',
            sortable: false,
            width: 75,
        },
        {
            field: 'Description',
            headerName: 'Description',
            sortable: false,
            width: 250,
            editable: true,
        },
    ];

    const restructureData = (data) => {
        return data.map((structure) => {
            if (ItemsWithQty.length === 0 || typeof structure.Qty === "undefined") {
                structure.Qty = 1;
            }
            structure.Plus = structure;
            structure.Minus = structure;
            structure.Unit = structure.ItemPrice;
            structure.Pricing = FormattingPrice(structure.Qty * parseFloat(structure.ItemPrice.toString().replace(/,/g, '').replace(" ", "")), Delimiters, NbrAfterDelimiters);
            structure.Description = structure.Description || "Add Your Instructions ";
            return structure;
        });
    };

    const BtnPlus = (Item) => {
        const NewTable = ItemsToDisp.map((structure) => {
            if (Item === structure) {
                structure.Qty = Item.Qty + 1;
                structure.Pricing = FormattingPrice(structure.Qty * parseFloat(structure.ItemPrice.toString().replace(/,/g, '').replace(" ", "")), Delimiters, NbrAfterDelimiters);
            }
            return structure;
        });
        setItemsToDisp(NewTable);
        handleCloseOrderWithoutClose(NewTable);
    };

    const BtnMinus = (Item) => {
        if (ItemsToDisp.find(row => row === Item).Qty > 1) {
            const NewTable = ItemsToDisp.map((structure) => {
                if (Item === structure) {
                    structure.Qty = Item.Qty - 1;
                    structure.Pricing = FormattingPrice(structure.Qty * parseFloat(structure.ItemPrice.toString().replace(/,/g, '').replace(" ", "")), Delimiters, NbrAfterDelimiters);
                }
                return structure;
            });
            setItemsToDisp(NewTable);
            handleCloseOrderWithoutClose(NewTable);
        } else {
            const arr = ItemsToDisp.filter(row => row !== Item);
            setItemsToDisp(arr);
            handleCloseOrderWithoutClose(arr);
        }
    };

    useEffect(() => {
        const rows = Items?.map(oneitem => RestInfo?.find(o => o.ItemId === oneitem)).filter(row => row !== null);
        setItemsToDisp(restructureData(rows));
    }, [Items, RestInfo, ItemsWithQty, Delimiters, NbrAfterDelimiters]);

    const CalculateSum = () => {
        let Summation = 0;
        ItemsToDisp.forEach((oneitem) => {
            Summation += parseFloat(oneitem.Pricing.toString().replace(/,/g, '').replace(" ", ""));
        });
        Summation = FormattingPrice(Summation, Delimiters, NbrAfterDelimiters);
        setTotalPrice(Summation);
        return Summation;
    };

    const CustomFooter = () => (
        <div className='totalPriceViewOrder'>
            Total:  {CalculateSum()}  {Curr}
        </div>
    );

    const handleEditCellChangeCommitted = (params) => {
        const { id, field } = params;
        const value = params.props.value;
        if (field === 'Description') {
            const updatedItems = ItemsToDisp.map((item) => {
                if (item.ItemId === id) {
                    item.Description = value;
                }
                return item;
            });
            setItemsToDisp(updatedItems);
            handleCloseOrderWithoutClose(updatedItems);
        }
    };
    const myStyle = {
        height: '400px',
        width: '450px',

    }; 
    const GridStyle = { 
    }; 
    return (  
        <div style={myStyle} className='custom-font-size'>
            <DataGrid
                componentsProps={{
                    cell: {
                        style: { fontSize: '1px' }, // Adjust the font size as needed
                    },
                    columnHeader: {
                        style: { fontSize: '1px' }, // Adjust the font size as needed
                    },
                }}
                     item xs={12} 
                    getRowId={(row) => row.ItemId}
                    autoHeight
                    headerHeight={0}
                    hideFooterPagination={false}
                    rows={ItemsToDisp}
                    columns={columns}
                    pageSize={900}
                    disableSelectionOnClick
                    components={{
                        Footer: CustomFooter,
                    }}
                    onEditCellChangeCommitted={handleEditCellChangeCommitted}
            /> 
            </div>
    );
}
