import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default function DislpayAlert(params) {

    return (
        <div>

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
                open={params.open}
                autoHideDuration={1500}
                onClose={params.close} 
            >
                <Alert   variant="filled" 
                severity="error">
                    {params.message}
                </Alert>

            </Snackbar>

        </div>
    )
}