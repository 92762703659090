import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
//apis
import {uploadImage, DeleteImage } from '../apis/CategoriesApis';
import { InsertRestInfo, UpdateRestInfo } from '../apis/RestInfoAPI';

export default function DialogForRestInfo({ isUpdate, getData, RestInfoRow, openModal, handleCloseModal, OpenSnackBarForMsg }) { 
    const [RestInfoDesc, setRestInfoDesc] = useState("")
    const [OrderNumber, setOrderNumber] = useState("")
    const [images, setimages] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
        // console.log(CategorieRow)
        if (isUpdate && RestInfoRow) {
            setRestInfoDesc(RestInfoRow.RestInfoImgDesc); 
            setOrderNumber(RestInfoRow.RestInfoOrderNbr);
        }
    }, [RestInfoRow, getData])

    const update = async () => { 
        return await callbackEdit({
            RestInfoId: RestInfoRow.RestInfoId, 
            RestInfoImgDesc: RestInfoDesc,
            RestInfoOrderNbr: OrderNumber,
        })
    }

    const insert = async () => {
        return await callbackInsert({
            RestId: localStorage.getItem("RestaurantId"), 
            RestInfoImgDesc: RestInfoDesc, 
            RestInfoOrderNbr: OrderNumber,
        })

    }
    const ClearFields = () => { 
        setRestInfoDesc("");
        setOrderNumber("");
        setimages("");
    };

    const callbackInsert = async (data) => {
        const InsertRes = await InsertRestInfo(data);
        const CnstErrorMsg = InsertRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to insert RestInfo, " + InsertRes?.Error?.Message)
            setLoading(false);
        }
        else {
            if (images)
                await uploadImage(images, "RestInfo", InsertRes, JSON.stringify(data))
                 
            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("RestInfo Inserted successfully ");
            ClearFields();
            setLoading(false);
        }
    }

    const callbackEdit = async (data) => { 
        const UpdateRes = await UpdateRestInfo(data); 
        const CnstErrorMsg = UpdateRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to update cat, " + UpdateRes?.Error?.Message)
            setLoading(false);
        }
        else {
            const ImageID = RestInfoRow.Image?.id;
            //console.log("ImageID", ImageID)
            //check if user has been upload a image on update mode and id of image is not null, so we need to delete the old one and reupload a new image
            if (ImageID && images) { 
                await (DeleteImage(({ RelatedId: ImageID, RelatedType: "RestInfo" })))
            }
            if (images)
                await uploadImage(images, "RestInfo", data.RestInfoId, JSON.stringify(data))
            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("RestInfo Updated successfully ");
            ClearFields();
            setLoading(false);
        }
    }

    const submit = async () => {

        if (!RestInfoDesc && !images)
            return alert('Please Check mandatory Fields')

        setLoading(true);
        if (isUpdate)
            await update()
        else
            await insert()
    }


    const onImageChange = event => {
        // console.log(event.target.files);
        setimages(event.target.files[0]);

    };
    return (
        <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Rest Info</DialogTitle>
            <DialogContent> 
                <form> 
                    <TextField
                        className="MultiLinesTextBox"
                        margin="dense"
                        id="txtItemDesc"
                        label="Image Descirption"
                        multiline
                        rows={5}
                        value={RestInfoDesc}
                        onChange={(event) => setRestInfoDesc(event.target.value)}
                        required
                    />
                     

                    <br />
                    <TextField
                        type="number"
                        margin="dense"
                        id="txtOrder"
                        label="Order Nbr"
                        value={OrderNumber}
                        onChange={(event) => setOrderNumber(event.target.value)}
                    />
                    <br />
                    <br />
                    <input
                        accept="image/*"
                        name="file"
                        id="contained-button-file"
                        single
                        type="file"
                        onChange={onImageChange}
                    />
                    <br /> 
                </form>
                <br></br>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        position: 'absolute', left: '50%', top: '60%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
                </div>
                <Button onClick={handleCloseModal} color="secondary">
                    Cancel
                </Button>
                <Button onClick={(e) => submit()} color="primary" type="submit">
                    {isUpdate ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
