import AppConfig from '../AppSettings';
 

export const InsertCallWaiter = async (StrValRestId, StrValTableNbr) => {
    try { 
        const response = await fetch(AppConfig.apiUrl + `/CallWaiter/CallWaiterSend`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                StrRestId: StrValRestId,
                StrTableNbr: StrValTableNbr
            })
        });

        const result = await response.text();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        console.log("error", error)
        return result;
    }
}
