 
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("../Collection/originecode.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Origin Softwares</h1>
              <div className="fog-low">
                <img
                  alt="..."
                  src={require("../Collection/fog-low.png").default}
                />
              </div>
              <div className="fog-low right">
                <img
                  alt="..."
                  src={require("../Collection/fog-low.png").default}
                />
              </div>
            </div>
            <h1 className="presentation-subtitle text-center">
            Who is Origins?
    Origins is a CA/LB-based softwares development company specializing in providing businesses worldwide with custom technology solutions. As a mobile and web-based application development company  , Origin combines technological expertise, specific domain experience, and a passion for excellence to deliver enterprise-grade solutions.
  
            </h1>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("../Collection/clouds.png").default + ")", 
          }}
        />
        <h6 className="category category-absolute">
           
        </h6>
      </div>
    </>
  );
}

export default IndexHeader;
