import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Grid, MenuItem, Switch, FormControlLabel, InputAdornment, IconButton, Typography } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { UpdateMyRestaurant, GetMyRestaurant } from '../apis/RestaurantAPI'; // Ensure the correct path to the API file
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { uploadImage, DeleteImage } from '../apis/CategoriesApis';
import AppConfig from '../AppSettings'; 


const useStyles = makeStyles((theme) => ({
    body: {
        backgroundColor: '#f0f2f5',
        fontFamily: 'Roboto, sans-serif',
    },
    appContainer: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        //padding: theme.spacing(2),
        //backgroundColor: '#fff',
        //borderRadius: theme.spacing(1),
        //boxShadow: theme.shadows[1],
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    sectionTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
    actionButtons: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    actionButton: {
        minWidth: 150,
    },
    iconLink: {
        margin: theme.spacing(1),
    },
    imgPreview: {
        maxWidth: '100%',
        maxHeight: '150px',
        margin: theme.spacing(1, 0),
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid black'
    },
    imgActions: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const MyRestaurant = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        RestID: localStorage.getItem("RestaurantId"),
        Name: '',
        Location: '',
        Phone1: '',
        Phone2: '',
        FacebookLink: '',
        InstaLink: '',
        TikTokLink: '',
        Curr: '',
        DefaultLanguage: 'Eng',
        Locationlink: '',
        RestFileName: '',
        RestFileName2: '',
        SecondLanguage: '',
        SecondLanguageDir: '',
        VisibleLanguageBar: false,
        Delimiters: '',
        NbrAfterDelimiters: '',
        WhatsapNbr: '',
        RestInfoShow: false,
        RestInfoShowInMenuTab: false,
        ShowCategoriesBtn: false,
        ExpandAllSubCatDiv: false,
    });
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [images, setImages] = useState({ backG: null, front: null });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage('');
        setErrorMessage('');
    };

    useEffect(() => { 
        setLoading(true);

       

        const fetchData = async () => {
            try {
                const data = await GetMyRestaurant({ RestID: localStorage.getItem("RestaurantId") }); 
                // Extract image URLs from the fetched data and update state
                const backGImageUrl = data?.Dt[0]?.RestFileName ? AppConfig.ImageUrl + "/" + data?.Dt[0]?.RestFileName:"";
                const frontImageUrl = data?.Dt[0]?.RestFileName2 ?AppConfig.ImageUrl + "/" + data?.Dt[0]?.RestFileName2:"";
                setImages({
                    backG: backGImageUrl ? { preview: backGImageUrl } : null,
                    front: frontImageUrl ? { preview: frontImageUrl } : null
                });

                setFormData(data?.Dt[0]); 
            } catch (error) {
                setErrorMessage(error) 
            }
        };

        fetchData();

        setLoading(false);
          

    }, []);

    const handleImageChange = (event, key) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImages((prevState) => ({
                    ...prevState,
                    [key]: { file, preview: reader.result },
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteImage = (key) => {
        setImages((prevState) => ({
            ...prevState,
            [key]: null,
        }));
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await UpdateMyRestaurant(formData);
             
            if (images?.backG?.file === undefined && !images?.backG?.preview) {  
                await (DeleteImage(({ RelatedId: localStorage.getItem("RestaurantId"), RelatedType: "Restaurants" })))
            }
            if (images?.front?.file === undefined && !images?.front?.preview) { 
                await (DeleteImage(({ RelatedId: localStorage.getItem("RestaurantId"), RelatedType: "RestaurantsRestFileName2" })))
            }
             
            if (!response?.Error?.Message) {
                if (images?.backG?.file) {
                    await uploadImage(images?.backG?.file, "Restaurants", localStorage.getItem("RestaurantId"), formData);
                }
                if (images?.front?.file)
                    await uploadImage(images?.front?.file, "RestaurantsRestFileName2", localStorage.getItem("RestaurantId"), formData);

                setSuccessMessage('Restaurant Fields Updated Successfully.');
            } else {
                setErrorMessage('Failed To Change Restaurant Fields: ' + response?.Error?.Message);
            }
            setLoading(false);
        } catch (error) {
            // Handle error (e.g., show an error message)
            setLoading(false);
        }
    };

    const containerStyles = {
        position: 'absolute',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    };

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        pointerEvents: loading ? 'auto' : 'none',
    };

    return (
        <div>
            <div>
                {loading && <div style={overlayStyles}></div>}
                <div style={containerStyles}>
                    {loading && (
                        <CircularProgress
                            size={75}
                            thickness={5}
                            style={{ color: 'white' }}
                            aria-label="Loading"
                        />
                    )}
                </div>
            </div>

            <Container maxWidth="md" className={classes.appContainer}>
                <Helmet>
                    <title>My Restaurant</title>
                </Helmet>

                <Box className={classes.formContainer}>
                    <Typography variant="h6" className={classes.sectionTitle}>Basic Information</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Name" variant="outlined" name="Name" value={formData.Name} onChange={handleChange} />

                            <Typography variant="body2" color="textSecondary">
                                Note: Changing the name will require generating a new QR code.
                            </Typography>
                        </Grid>  
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Location" variant="outlined" name="Location" value={formData.Location} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Phone 1" variant="outlined" name="Phone1" value={formData.Phone1} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Phone 2" variant="outlined" name="Phone2" value={formData.Phone2} onChange={handleChange} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.sectionTitle}>Social Links</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="WhatsApp Number"
                                variant="outlined"
                                name="WhatsapNbr"
                                value={formData.WhatsapNbr}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <WhatsAppIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Facebook Link" variant="outlined" name="FacebookLink" value={formData.FacebookLink} onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <FacebookIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Instagram Link" variant="outlined" name="InstaLink" value={formData.InstaLink} onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <InstagramIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Location Link" variant="outlined" name="LocationLink" value={formData.Locationlink} onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <LocationOnIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.sectionTitle}>Currency Settings</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Currency" variant="outlined" name="Curr" value={formData.Curr} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Delimiters" variant="outlined" name="Delimiters" value={formData.Delimiters} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Numbers After Delimiters" variant="outlined" name="NbrAfterDelimiters" value={formData.NbrAfterDelimiters} onChange={handleChange} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.sectionTitle}>Language Settings</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Second Language" variant="outlined" name="SecondLanguage" value={formData.SecondLanguage} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="Second Language Direction"
                                variant="outlined"
                                name="SecondLanguageDir"
                                value={formData.SecondLanguageDir}
                                onChange={handleChange}
                            >
                                <MenuItem value="RTL">Right To Left</MenuItem>
                                <MenuItem value="LTR">Left To Right</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="Default Language"
                                variant="outlined"
                                name="DefaultLanguage"
                                value={formData.DefaultLanguage}
                                onChange={handleChange}
                            >
                                <MenuItem value="Eng">English</MenuItem>
                                <MenuItem value="Ar">Other Lang</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.sectionTitle}>Logo Images</Typography>
                    <Grid container spacing={2} >
                        <Grid item xs={10} sm={6}>
                            <div className={classes.imgContainer}>
                                <label htmlFor="backGLogoInput" >BackG Logo</label>
                                <input
                                    accept="image/*"
                                    id="backGLogoInput"
                                    type="file"
                                    onChange={(e) => handleImageChange(e, 'backG')}
                                    style={{ display: 'none' }}
                                />
                                <Button variant="contained" component="span" onClick={() => document.getElementById('backGLogoInput').click()}>
                                    Upload
                                </Button>
                            </div>
                            {images.backG?.preview && (
                                <div className={classes.imgPreview}>
                                    <img src={images.backG?.preview} alt="BackG Logo Preview" width={70} height={70} style={{ marginRight: '10px' }} />
                                    <Button variant="contained" color="secondary" onClick={() => handleDeleteImage('backG')}>
                                        Delete
                                    </Button>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={6}>
                            <div className={classes.imgContainer}>
                                <label htmlFor="frontLogoInput">Front Logo</label>
                                <input
                                    accept="image/*"
                                    id="frontLogoInput"
                                    type="file"
                                    onChange={(e) => handleImageChange(e, 'front')}
                                    style={{ display: 'none' }}
                                />
                                <Button variant="contained" component="span" onClick={() => document.getElementById('frontLogoInput').click()}>
                                    Upload
                                </Button>
                            </div>
                            {images.front?.preview && (
                                <div className={classes.imgPreview}>
                                    <img src={images.front?.preview} alt="Front Logo Preview" width={70} height={70} style={{ marginRight: '10px' }} />
                                    <Button variant="contained" color="secondary" onClick={() => handleDeleteImage('front')}>
                                        Delete
                                    </Button>
                                </div>
                            )}
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.sectionTitle}>Additional Settings</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.VisibleLanguageBar}
                                        onChange={handleChange}
                                        name="VisibleLanguageBar"
                                    />
                                }
                                label="Visible Language Bar"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.RestInfoShow}
                                        onChange={handleChange}
                                        name="RestInfoShow"
                                    />
                                }
                                label="Rest Info Show"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.RestInfoShowInMenuTab}
                                        onChange={handleChange}
                                        name="RestInfoShowInMenuTab"
                                    />
                                }
                                label="Rest Info Show In Menu Tab"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.ShowCategoriesBtn}
                                        onChange={handleChange}
                                        name="ShowCategoriesBtn"
                                    />
                                }
                                label="Show Categories Button"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.ExpandAllSubCatDiv}
                                        onChange={handleChange}
                                        name="ExpandAllSubCatDiv"
                                    />
                                }
                                label="Expand All SubCat Rows"
                            />
                        </Grid>
                    </Grid> 
                    <Box className={classes.actionButtons}> 
                        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary" fullWidth disabled={loading}>Save</Button> 
                    </Box>
                    <br/>
                </Box>
            </Container>
            <Snackbar open={!!successMessage || !!errorMessage} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity={successMessage ? 'success' : 'error'} elevation={6} variant="filled">
                    {successMessage || errorMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default MyRestaurant;
