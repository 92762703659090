import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles'; 
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';  
import DrawerComponent from "./drawer";
import ToolbarComponent from "./toolbar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Select_Cat_SubCat_Item_COUNT, GetRestName } from '../apis/DashboardAPI'; 

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));



export function Appbar() {
    const [Cat_SubCat_Item_COUNT, setCat_SubCat_Item_COUNT] = useState([])
    const classes = useStyles();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [RestnameResponse, setRestnameResponse] = useState([]);
    const toggleDrawer = () => {
        setDrawerOpen(false);
    };
    const openDrawer = () => {
        setDrawerOpen(true);
    };

   

    useEffect(() => {
        async function fetchData() {
            const data = await Select_Cat_SubCat_Item_COUNT(localStorage.getItem("RestaurantId"));
            setCat_SubCat_Item_COUNT(data);
        }
        fetchData();

        async function GetRestname() {
            const CnstRestnameResponse = await GetRestName(localStorage.getItem("RestaurantId"));
            setRestnameResponse(CnstRestnameResponse); 
        } 
        GetRestname();
    }, [isDrawerOpen]); // Empty dependency array ensures this effect runs only once isDrawerOpen changed
     

    return (
        
        <div className="App"> 
            <ToolbarComponent openDrawerHandler={openDrawer} RestName={RestnameResponse} />
            <DrawerComponent open={isDrawerOpen} toggleDrawerHandler={toggleDrawer} PassCounts={Cat_SubCat_Item_COUNT} RestName={RestnameResponse} />
        </div>

        //<div className="Navbar">
        //    <div className="leftSide">
        //        <div className="links">
        //            <Link to="/" ><u>Categories </u>  |  </Link>
        //            <Link to="/sub-categories"><u>Sub-Categories   </u>|  </Link>
        //            <Link to="/ItemsUI"><u>Items </u>  |  </Link>
        //            <Link to="/DisplayComments"><u>Comments</u>  |  </Link>
        //            <Link to="/RestInfo"><u>Info</u>  |  </Link> 
        //        </div>
        //    </div>
      
        //    <div className="rightSide">
        //    <div className="links">
        //    <a href="/login" onClick={logOut} >LogOut</a>
        //    </div>
        //    </div>
        //</div>
    );
}

export default Appbar;
