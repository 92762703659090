import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@material-ui/data-grid';

import { GetItems, DeleteItems } from '../apis/ItemsAPI';
import {DeleteImage } from '../apis/CategoriesApis';

import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import DialogForItem from '../components/DialogForItem';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {  UpdateItem } from '../apis/ItemsAPI';
import ReactDOM from "react-dom";
import AppConfig from '../AppSettings';
 


const restructureData = async (data) => {
  const result = await data.map(async (structure) => {
    structure.Image = {
      id:(structure.ItemFileName ? structure.ItemId : null),
      uri: AppConfig.ImageUrl + "/" + structure.ItemFileName
    };
    structure.edit = structure.ItemId;
    structure.delete = structure.ItemId;
      structure.deleteimage = structure.ItemId; 

      structure.SubCat =
          (structure?.SubCatNameFirstLang ? structure.SubCatNameFirstLang : "") +
          (structure?.SubCatNameFirstLang && structure?.SubCatNameSecondlang ? "_" : "") +
          (structure?.SubCatNameSecondlang ? structure.SubCatNameSecondlang : "");


    return structure;
  })
  return await Promise.all(result);
}

  

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useToolBarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function BasicTable() {
  const classes = useStyles();
  const ToolBarStylesClasses = useToolBarStyles();

  function CustomToolbar({ ArrayOfIds }) {
    return (
      <GridToolbarContainer className={ToolBarStylesClasses.root}   >
         <div style={{ flexGrow: '2' }}> 
        <Button style={{ color: green[500] }} startIcon={<AddIcon />} onClick={handleClickOpen} >Add</Button>
        <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => removeBulkItem(ArrayOfIds)}></Button>
        </div>
        {/* <div style={{ flexGrow: '1' }}> </div>
        <div style={{ flexGrow: '2' }}>

          <TextField  
            className="TEXTPriceBulk"
            InputLabelProps={{
              shrink: true,
            }}  
            autoFocus 
            type='number'
            style={{ width: '85%' }}
            label="Price+-"
            defaultValue="Small"
            size="small" 
            onChange={handleChangePrice}
            value={Price} ></TextField> 
           
          <Button  size="small" variant="contained"   color="primary"  onClick={() => UpdatePrice(ArrayOfIds)}  >Update Prices</Button>
          </div> */}
        <div style={{ flexGrow: '2' }}>
        <GridToolbarFilterButton />
          
    
        <GridToolbarExport />
        </div>
      </GridToolbarContainer>
    );
  }
  var [Price, setPrice] = useState("");
  const [data, setData] = useState([]);
  const [selectedItem, setselectedItem] = useState([]);
  const [open, setOpen] = useState(false);
  const [ErrMsg, setErrMsg] = useState("")
  const [openSnackbar, setopenSnackbar] = useState(false);


  const [ItemId_Edit, setItemId_Edit] = useState("")
  const [OpenEditModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true);
  const [Msg, setMsg] = useState("")


  const handleCloseEditModal = () => {
    setOpenModal(false);
  };
  const handleClickOpenEditModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseSnackbar = () => {
    setopenSnackbar(false);
  };

  const handleOpenSnackbar = (strMsg) => {
    setopenSnackbar(true);
    setMsg(strMsg)
  };
  useEffect(() => {
    getItemsAPI(); 
  }, [])

   function handleChangePrice(event) { 
     setPrice(event.target.value);
  }
  const getItemsAPI = async () => { 
    const result = await GetItems({
      "RestId": localStorage.getItem("RestaurantId") 
    }); 
    const CnstErrorMsg = result?.Error?.Message;
    if (CnstErrorMsg)
      handleOpenSnackbar("Failed to load data, " + result?.Error?.Message)

    setErrMsg(result?.Error?.Message)
    if(result?.Dt)
    setData(await restructureData(result?.Dt));
    // setMsg(result?.Error)
    setLoading(false);
 
 
  }
  
  
  const removeItem = async (id) => {
    const Confirmation = window.confirm("Do you want to Delete?");
    if (Confirmation == true) {
      const ResDel = await DeleteItems({ ItemId: id }); 
      const CnstErrorMsg = ResDel?.Error?.Message;
      if (CnstErrorMsg) {
        handleOpenSnackbar("Failed to Delete Item, " + ResDel?.Error?.Message)
        setLoading(false);
      }
      else {
        setLoading(true);
        // const ImageId = (data.find(o => o.ItemId === id)?.Image.id);
        // if (ImageId) {
        //   await (DeleteImage(({ RelatedId: ImageId ,RelatedType:"Categories" })))
        // }
        getItemsAPI();
        handleOpenSnackbar("Item Deleted");
      }
    }
  }

 
  const DeleteItemImageFromGird = async argItemId => {
    const Confirmation = window.confirm("Do you want to Delete image?");
    if (Confirmation == true) {
      setLoading(true);
      const ImgId = (data.find(o => o.ItemId === argItemId)?.Image.id);
      if (ImgId) {
        await DeleteImage({ RelatedId: ImgId,RelatedType:"Items"  });
        getItemsAPI();
        handleOpenSnackbar("Item Image Deleted");
      }
      setLoading(false);
    }
  } 

  const CallremoveItemFromBulk = async (id) => {
    const cnstRes = await DeleteItems({ ItemId: id });
    const CnstErrorMsg = cnstRes?.Error?.Message;
    if (CnstErrorMsg) {
      handleOpenSnackbar("Failed to load data, " + cnstRes?.Error?.Message)
      setLoading(false);
    } else {

      // const ImageId = (data.find(o => o.ItemId === id)?.Image.id);
      // if (ImageId) {
      //   await (DeleteImage(({ RelatedId: ImageId,RelatedType:"Categories"  })))
      // }
      getItemsAPI();
    }
  } 

  function removeBulkItem(id) {
    const Confirmation = window.confirm("Do you want to Delete them?");
    if (Confirmation == true && id.selectionModel?.length > 0) {

      setLoading(true);
      handleOpenSnackbar("Item Deleted");
      let FirstId = id.selectionModel;
      if (FirstId) {
        FirstId.forEach(element =>

          CallremoveItemFromBulk(element));
      }
      setselectedItem([]);
    }
  }  
   
  
  const ButtonUpdateSubCategory = async Id => {
    handleClickOpenEditModal();
    setItemId_Edit(Id);

  }

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'SubCat',
      sort: 'asc',
    },
  ]);

  const columns = [
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return <Button size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => ButtonUpdateSubCategory(params.value)} ></Button>
      }
    },
    { field: 'CatId', headerName: 'ID', width: 70, hide: true },
    { field: 'SubCat', headerName: 'Sub_Category', width: 250 }, 
    { field: 'ItemNameFirstLang', headerName: 'Name English', width: 250 },
    { field: 'ItemNameSecondlang', headerName: 'Name Sec Lang', width: 250 },
    { field: 'IsItemFreePrice', headerName: 'Free Price', width: 150 },
    { field: 'ItemPrice', headerName: 'Price', width: 250 },
    { field: 'ItemDescFirstLang', headerName: 'Description Eng', width: 250 },
    { field: 'ItemDescSecondlang', headerName: 'Description SecLang', width: 250 },
    { field: 'ItemOrderNbr', headerName: 'order', width: 110 },
    { field: 'IsItemVisible', headerName: 'Visible', width: 150 },
    {
      field: 'Image',
      headerName: 'Image',
      sortable: false,
      width: 250,
        renderCell: (params) => {
            const divStyle = {
                backgroundColor: 'rgb(36,90,118)', // Set the background color 
            };
            return <div   style={divStyle}> 
                {params.value?.uri?.includes('null') && !params.value?.uri?.includes('.')
                    ? <></>
                    : <img src={`${params.value.uri}`} alt="No IMG" width={50} height={50} />}
          </div>
      }
    },
   
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />}

          onClick={() => { removeItem(params.value) }} >
        </Button>
      }
    },
    {
      field: 'deleteimage',
      headerName: 'Delete Image',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return <Button size="small" style={{ backgroundColor: 'orange', color: 'white' }} startIcon={<DeleteForeverTwoToneIcon />}
        onClick={() => { DeleteItemImageFromGird(params.value) }} >
      </Button> 
      }
    }
  ];
  const getRowId = (row) => row.ItemId;
  return (
    <div style={{ height: 600, width: '100%' }}>
      <Helmet>
        <title>Items</title>
      </Helmet>

      <div
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        {loading && <CircularProgress size={75} thickness={5} style={{ 'color': 'black' }} />}
          </div>  
          <DataGrid 
        getRowId={getRowId}
        sortModel={sortModel}
        rows={data}
        columns={columns}
        pageSize={50}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          // console.log("newSelectionModel", newSelectionModel);
          setselectedItem(newSelectionModel);
        }}
        components={{
          Toolbar: () => <CustomToolbar ArrayOfIds={selectedItem} />, 
        }}
              /> 


      <DialogForItem
        openModal={open}
        handleCloseModal={handleClose}
        getData={getItemsAPI}
        isUpdate={false}
        OpenSnackBarForMsg={handleOpenSnackbar}
      />
      {data[0] ?
        <DialogForItem
          openModal={OpenEditModal}
          handleCloseModal={handleCloseEditModal}
          ItemRow={data.find(o => o.ItemId === ItemId_Edit)}
          getData={getItemsAPI}
          isUpdate={true}
          OpenSnackBarForMsg={handleOpenSnackbar}  
        /> : <></>
      }
      <Snackbar theme={{ colors: { surface: 'blue', accent: 'red' }, }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={Msg}
      />
          </div>
           
  );
}