import React, { useState, useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import { GetRestName } from '../apis/DashboardAPI';

const templates = {
    default: '#ffffff',
    template1: '#ffcccc',
    template2: '#ccffcc',
    template3: '#ccccff',
};

const QRCodeBuilder = () => {
    const [qrData, setQRData] = useState('');
    const [qrSize, setQRSize] = useState(200);
    const [qrColor, setQRColor] = useState('#000000');
    const [qrBgColor, setQRBgColor] = useState('#ffffff');
    const [qrLogo, setQRLogo] = useState('');
    const [qrTemplate, setQRTemplate] = useState('default');
    const [qrStyle, setQRStyle] = useState('square');

    const [RestNameState, setRestNameState] = useState('');
    const qrRef = useRef(null);

    const qrCode = useRef(
        new QRCodeStyling({
            width: 200,
            height: 200,
            data: '',
            dotsOptions: {
                color: '#000000',
                type: 'square',
            },
            backgroundOptions: {
                color: '#ffffff',
            },
            imageOptions: {
                //crossOrigin: 'anonymous',
                //margin: 20,
                //imageSize: 10
            },
        })
    );

    const updateQRCode = () => {
        const imageOptions = qrLogo ? {
            crossOrigin: "anonymous",
            margin: 20,
            imageSize: qrSize * 0.2,
        } : {};

        qrCode.current.update({
            width: qrSize,
            height: qrSize,
            data: qrData,
            dotsOptions: {
                color: qrColor,
                type: qrStyle,
            },
            backgroundOptions: {
                color: qrBgColor,
            },
            image: qrLogo,
            //imageOptions
        });
    };

    const handleDataChange = (e) => {
        setQRData(e.target.value);
    };

    const handleSizeChange = (e) => {
        setQRSize(parseInt(e.target.value, 10));
    };

    const handleColorChange = (e) => {
        setQRColor(e.target.value);
    };

    const handleBgColorChange = (e) => {
        setQRBgColor(e.target.value);
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setQRLogo(URL.createObjectURL(file));
    };

    const handleTemplateChange = (e) => {
        setQRTemplate(e.target.value);
    };

    const handleStyleChange = (e) => {
        setQRStyle(e.target.value);
    };

    const handleDownloadClick = () => {
        qrCode.current.download({
            extension: 'png',
            name: 'QR_code-' + RestNameState,
            backgroundColor: templates[qrTemplate] || qrBgColor,
        });
    };

    const getRestaurantName = async () => {
        const restName = await GetRestName(localStorage.getItem("RestaurantId"));
        setRestNameState(restName);
        return restName;
    };

    useEffect(() => {
        const fetchRestName = async () => {
            const restName = await getRestaurantName();
            setQRData('https://originssoftwares.com/Menu/' + restName);
        };
        fetchRestName();
    }, []);

    useEffect(() => {
        updateQRCode();
    }, [qrData, qrSize, qrColor, qrBgColor, qrLogo, qrStyle, qrTemplate]);

    useEffect(() => {
        qrCode.current.append(qrRef.current);
    }, []);

    return (
        <div className="qr-code-builder">
            <Helmet>
                <title>QR Code Builder</title>
            </Helmet>
            <div className="options">
                <div className="option">
                    <label htmlFor="sizeInput">Size:</label>
                    <input
                        type="range"
                        id="sizeInput"
                        value={qrSize}
                        onChange={handleSizeChange}
                        min="100"
                        max="400"
                    />
                    <span style={{ marginLeft: '10px' }}>{qrSize}px</span>
                </div>
                <div className="option">
                    <label htmlFor="colorInput">Foreground Color:</label>
                    <input
                        type="color"
                        id="colorInput"
                        value={qrColor}
                        onChange={handleColorChange}
                    />
                </div>
                <div className="option">
                    <label htmlFor="bgColorInput">Background Color:</label>
                    <input
                        type="color"
                        id="bgColorInput"
                        value={qrBgColor}
                        onChange={handleBgColorChange}
                    />
                </div>
                <div className="option">
                    <label htmlFor="logoInput">Logo:</label>
                    <input
                        type="file"
                        id="logoInput"
                        accept="image/*"
                        onChange={handleLogoChange}
                    />
                </div>
                {/*<div className="option">*/}
                {/*    <label htmlFor="templateInput">Template:</label>*/}
                {/*    <select id="templateInput" value={qrTemplate} onChange={handleTemplateChange}>*/}
                {/*        <option value="default">Default</option>*/}
                {/*        <option value="template1">Template 1</option>*/}
                {/*        <option value="template2">Template 2</option>*/}
                {/*        <option value="template3">Template 3</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                <div className="option">
                    <label htmlFor="styleInput">Style:</label>
                    <select id="styleInput" value={qrStyle} onChange={handleStyleChange}>
                        <option value="square">Square</option>
                        <option value="dots">Dots</option>
                        <option value="rounded">Rounded</option>
                        <option value="classy">Classy</option>
                        <option value="classy-rounded">Classy Rounded</option>
                        <option value="extra-rounded">Extra Rounded</option>
                    </select>
                </div>
                <div className="option" style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={handleDownloadClick} type="submit" variant="contained" color="primary">Download QR Code</Button>
                </div>
            </div>
            <div className="qr-code-preview" style={{ textAlign: 'center', marginTop: '40px' }}>
                <div
                    className={`qr-code-container ${qrTemplate}`}
                    ref={qrRef}
                    style={{ backgroundColor: templates[qrTemplate], display: 'inline-block', padding: '10px' }}
                />
            </div>
        </div>
    );
};

export default QRCodeBuilder;
