import React, { useEffect, useState, useRef, memo, forwardRef } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { GetAllSubCatFromCat, GetSubCatofCat } from '../apis/SubCategoriesApis';
import { GetAllItemsByCategories, GetItemsByCategories } from '../apis/CategoriesApis'
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ViewOrder from '../components/ViewOrder';
import Menu from '@material-ui/core/Menu';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useCallback } from 'react';
import { FormattingPrice, hexToRgba } from '../components/GeneralFunctions';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import AppConfig from '../AppSettings';
import { red } from '@material-ui/core/colors';
import AboutRest from '../components/AboutRest';
import ModalImage from '../components/ModalImage';
import { stringToBoolTrueValue } from '../components/GeneralFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess'; 

export default function MenuItems(MenuInfo) {

    const [UpdatedRowsFromChild, setUpdatedRowsFromChild] = React.useState([]);
    const [ContactDevAsPriceFormat, setContactDevAsPriceFormat] = React.useState("");
    const [SearchValue, setSearchValue] = React.useState("");
    const [OpenVireOrder, setOpenVireOrder] = React.useState("");
    const [SelectedDivColor, setSelectedDivColor] = useState([]);

    const [SelectedCategorietoggle, setSelectedCategorietoggle] = useState("");
    const [SubCatbyCat, setSubCatbyCat] = useState([]);
    const [ItemsByCate, setItemsByCate] = useState([]);
    const [isEnglish, setIsEnglish] = useState("");
    const [SecondLangauge, setSecondLangauge] = useState("Second Lang");
    const [SecondLangaugeDirection, setSecondLangaugeDirection] = useState("RTL");
    const [VisibleLanguageBar, setVisibleLanguageBar] = useState("TRUE");
    const [Delimiters, setDelimiters] = useState(",");
    const [NbrAfterDelimiters, setNbrAfterDelimiters] = useState("3");
    const [IsRestInfoShowInMenuTab, setIsRestInfoShowInMenuTab] = useState(false);
    const [IsShowCategoriesBtn, setIsShowCategoriesBtn] = useState(true);

    const [isModalOpen, setModalOpen] = useState(false);
    const [ImageURLMaximize, setImageURLMaximize] = useState("");
    const [ImageDescMaximize, setImageDescMaximize] = useState("");
    const [openCategories, setOpenCategories] = useState({});
    const [ColorsMenuState, setColorsMenuState] = useState([]);
    const [ExpandAllSubCatDivState, setExpandAllSubCatDivState] = useState(true);
    // Initialize openCategories state 


    const CheckDefaultLang = async () => {
        if ((MenuInfo?.RestInf?.Restaurant?.DefaultLanguage)?.includes('Ar')) {
            setIsEnglish(false)
        }
        else {
            setIsEnglish(true)
        }
    }
    useEffect(() => {

        CheckDefaultLang();
        setSecondLangauge(MenuInfo?.RestInf?.Restaurant?.SecondLanguage)
        setSecondLangaugeDirection(MenuInfo?.RestInf?.Restaurant?.SecondLanguageDir)
        setVisibleLanguageBar(MenuInfo?.RestInf?.Restaurant?.VisibleLanguageBar)
        setDelimiters(MenuInfo?.RestInf?.Restaurant?.Delimiters)
        setNbrAfterDelimiters(MenuInfo?.RestInf?.Restaurant?.NbrAfterDelimiters)
        setIsRestInfoShowInMenuTab(stringToBoolTrueValue(MenuInfo?.RestInf?.Restaurant?.RestInfoShowInMenuTab))
        setIsShowCategoriesBtn(stringToBoolTrueValue(MenuInfo?.RestInf?.Restaurant?.ShowCategoriesBtn)) 
         
        const AllCat = MenuInfo?.RestInf?.Category;
        if (AllCat) {
            const FirstCalToselect = AllCat[0];
            setSelectedCategorietoggle(FirstCalToselect?.CatId)
            ChangeToggleCatBtn('', FirstCalToselect?.CatId)
        }
        setColorsMenuState(MenuInfo?.RestInf?.Restaurant?.EMenuColorCustomization)

    }, [MenuInfo])

    //async function GetColorsMenu() {
    //    const ConstColorsMenu = await GetColorRest({ RestId: MenuInfo?.RestInf?.Restaurant?.RestID });
    //    setColorsMenuState(ConstColorsMenu?.Dt[0])
    //};

    //called this function at page load to select default cat and on click on cat
    const ChangeToggleCatBtn = async (event, CatId) => {
        await setSubCatbyCat(null)
        setSearchValue('');

        setSelectedCategorietoggle(CatId);
        //setSubCatbyCat('');
        if (CatId) {
            //get cats  

            const SubCatFromRestToCache = MenuInfo?.RestInf.Sub_Categories.filter(Categorie => Categorie.CatId === CatId);
            setSubCatbyCat(SubCatFromRestToCache);
             
            // to open or close all divv by default
            const initialOpenCategories = Object.fromEntries(SubCatFromRestToCache?.map((SubCat) => [SubCat.SubCatId, MenuInfo?.RestInf?.Restaurant?.ExpandAllSubCatDiv]));
            setOpenCategories(initialOpenCategories);

            //get items by cats  
            const ItemsValues = MenuInfo?.RestInf?.Items;
            const ItemsByCategorys = ItemsValues?.filter(Item => Item.CatId === CatId);
            setItemsByCate(ItemsByCategorys)
        }
    };
    const ChangeSubCatBtn = async (SubCatId, SubCatFileName) => {



        // const titleElement = document.getElementById(SubCatId) 
        // titleElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});


        //i used this method cause when i used the previus, the scroll is not exacly correct
        //in case sub cat contain image, then we need to scroll it in addition a littlw bit
        var yOffset = -95;
        if (SubCatFileName)
            yOffset = -105;

        const element = document.getElementById(SubCatId);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });

        // console.log("SubCatId", SubCatId)
    }

    const useStylesLink = makeStyles((theme) => ({
        root: {
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    }));

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }));
    const useStylesToggleDiv = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    }));
    // i called this function from design
    function CreateToggleButtonsforCat() {
        const RestInfoCategor = [MenuInfo.RestInf.Category][0];
        return RestInfoCategor?.sort((a, b) => a.OrderNbr - b.OrderNbr).map(Cat =>
            <ToggleButton exclusive value={Cat.CatId} className="ForEachCatBtn">
                <div  >
                    {Cat.CatFileName ? <> <img src={AppConfig.ImageUrl + "/" + Cat.CatFileName} width={50} height={50} alt="CatImg" /> <br /></> : <><br /> </>}

                    <b style={StyleCategoryNameColor} className="CatFont">{isEnglish ? Cat.CatNameFirstLang : Cat.CatNameSecondlang}</b>
                </div>
            </ToggleButton>
        )
    }; 
    // i called this function from design
    function CreateSubCatbyCatUI() {

        return SubCatbyCat?.sort((a, b) => a.SubCatOrderNbr - b.SubCatOrderNbr).map(SubCat =>
            <div className="SubCatDivInsideRow" style={StyleSubCategorySquareBGWithColors}>
                <ToggleButton exclusive value={SubCat.SubCatId} className="ForEachSubCatBtn" onClick={() => {
                    { isEnglish ? ChangeSubCatBtn(SubCat.SubCatId, SubCat.SubCatFileName) : ChangeSubCatBtn(SubCat.SubCatId, SubCat.SubCatFileName) }
                }} >
                    <div>
                        {SubCat.SubCatFileName ? <> <img src={AppConfig.ImageUrl + "/" + SubCat.SubCatFileName} alt="SubCatImg" className="SubCatImg">
                        </img> </> : <><br /> </>}
                        <a
                            style={StyleSubCategoryNameColor}
                            className="LinkKatibeinButtons"
                            onClick={() => {
                                { isEnglish ? ChangeSubCatBtn(SubCat.SubCatId, SubCat.SubCatFileName) : ChangeSubCatBtn(SubCat.SubCatId, SubCat.SubCatFileName) }
                            }}  >
                            {isEnglish ? SubCat.SubCatNameFirstLang : SubCat.SubCatNameSecondlang}
                        </a>

                        {/* <Link className="LinkKatibeinButtons"  
                        component="button"
                        onClick={() => {
                            { isEnglish ? ChangeSubCatBtn(SubCat.SubCatId) : ChangeSubCatBtn(SubCat.SubCatId) }
                        }}  > 
                        {isEnglish ? SubCat.SubCatNameFirstLang : SubCat.SubCatNameSecondlang}
                    </Link> */}
                    </div>

                </ToggleButton>
            </div>

        )
    }

    function ItemClick(ItemId) {
        if (SelectedDivColor?.find(o => o === ItemId)) {
            let newarray = SelectedDivColor?.filter(element => element !== ItemId);
            setSelectedDivColor(newarray)
        }
        else {
            var newState = [ItemId].concat(SelectedDivColor);
            setSelectedDivColor(newState);
        }
    };


    const imageClickSubCat = (event, ImgURL, ImgDesc) => {

        //event.stopPropagation();

        setImageDescMaximize(ImgDesc)
        setImageURLMaximize(ImgURL)
        setModalOpen(true);

    }

    const imageClickItem = (event, ImgURL, ImgDesc) => {
        setImageURLMaximize(ImgURL)
        setImageDescMaximize(ImgDesc)
        setModalOpen(true);
        event.stopPropagation();

    }
    const ExpandDIV = (subCatId, event) => {

        event.stopPropagation();

        setOpenCategories((prevOpenCategories) => ({
            ...prevOpenCategories,
            [subCatId]: !prevOpenCategories[subCatId],
        }));
    };
    //const SplitFreePrice = (FreePrice) => {
    //    const items = [];
    //    const itemsArray = FreePrice.split(/\s+/);
    //    for (let i = 0; i < itemsArray.length; i += 2) {
    //        const size = itemsArray[i];
    //        const price = itemsArray[i + 1].replace('$', ''); // Remove the dollar sign
    //        items.push({ size, price });
    //    }
    //    return (
    //        <div> 
    //            <div>
    //                {items.map((item, index) => (
    //                    <ul key={index} style={{ marginRight: '20px' }}>
    //                        <li>{item.size}</li>
    //                        <li>{item.price}</li>
    //                    </ul>
    //                ))}
    //            </div>
    //        </div>
    //    )

    //}


    function CreateItemsFromSubCat() {
        return <div className="ItemsRoot" >
            {SubCatbyCat?.sort((a, b) => a.OrderNumber - b.OrderNumber)?.map(SubCat =>
                <div id={SubCat.SubCatId} >
                    <Divider variant="middle" style={{ background: "rgb(233, 236, 239)" }} />
                    <br />
                    <h5 className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "CatTitleEnglish" : "CatTitleArabic"}`} style={StyleSubCategorySquareBGWithColors}>
                        {isEnglish ? SubCat.SubCatNameFirstLang : SubCat.SubCatNameSecondlang}
                        {SubCat?.SubCatFileName ? <> <img onClick={(event) => imageClickSubCat(event, AppConfig.ImageUrl + "/" + SubCat.SubCatFileName, isEnglish ? `${SubCat.SubCatNameFirstLang}\r\n${SubCat.SubCatDescFirstLang}` : `${SubCat.SubCatNameSecondlang}\r\n${SubCat.SubCatDescSecondlang}`)}
                            alt="SubCatImg" src={AppConfig.ImageUrl + "/" + SubCat.SubCatFileName} className="SubCatImg1" />
                        </> : <> </>}
                        <IconButton onClick={(event) => ExpandDIV(SubCat.SubCatId, event)}  >
                            <div className='ExpandDivMenuItems'>{openCategories[SubCat.SubCatId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
                        </IconButton>
                        {(SubCat.SubCatDescFirstLang || SubCat.SubCatDescSecondlang) && (
                            <p style={EnterStringStyle} className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "DescriptionItem" : "ArabicDescriptionItem"}`}>
                                {isEnglish ? SubCat.SubCatDescFirstLang : SubCat.SubCatDescSecondlang}
                            </p>
                        )}

                    </h5>

                    <hr class="space-1" />
                    {openCategories[SubCat.SubCatId] && (
                        <>
                            {ItemsByCate?.filter(x => String(isEnglish ? x.ItemNameFirstLang.concat(x.ItemDescFirstLang).toLowerCase() : x.ItemNameSecondlang.concat(x.ItemDescSecondlang).toLowerCase())
                                .includes(SearchValue.toLowerCase()))?.sort((a, b) => a.ItemOrderNbr - b.ItemOrderNbr).filter(o => o.SubCatId === SubCat.SubCatId)?.map(item =>
                                    <div className="item-container">
                                        {/*<Divider variant="inset" lighter />*/}
                                        {/*<div className={`item-wrapper ${item?.ItemFileName ? '' : 'DivBetweenItems'}`}>*/}
                                        <div className={`item-wrapper ${item?.ItemFileName ? '' : ''}`}
                                            style={{
                                                // Use inline styles to set the box-shadow color
                                                boxShadow: `0 2px 1px ${BoxShadowColor},
                                                            0 -1px 1px ${BoxShadowColor},
                                                            -1px 0 1px ${BoxShadowColor},
                                                            1px 0 1px ${BoxShadowColor}` 
                                                                                    }}
                                        >
                                            <ListItem primary={item.ItemNameFirstLang} style={{ background: SelectedDivColor?.find(o => o === item.ItemId) === item.ItemId ? StyleSelectedItemsBG : StyleItemsBG }} 
                                                button id={item.ItemId} onClick={() => ItemClick(item.ItemId)} className="LisItemItemRow">
                                                <Grid item xs={12} className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "EnglishItemRow" : "ArabicItemRow"}`}
                                                    justifspayContent="ce-between"
                                                    alignItems="flex-start" >
                                                    <Grid item xs={8} direction="row" className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "EnglishmageandItemName" : "ArabicImageandItemName"}`}>
                                                        <div>{item?.ItemFileName && (
                                                            <img onClick={(event) => imageClickItem(event, AppConfig.ImageUrl + "/" + item.ItemFileName, isEnglish ? `${item.ItemNameFirstLang}\r\n${item.ItemDescFirstLang}` : `${item.ItemNameSecondlang}\r\n${item.ItemDescSecondlang}`)} alt="itemImg" src={AppConfig.ImageUrl + "/" + item?.ItemFileName} className="ItemsImage" />
                                                        )}
                                                        </div>
                                                        &nbsp;
                                                        <div style={StyleItemNameColor} className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "EnglishFontItem" : "ArabicFontItem"}`}>{isEnglish ? item.ItemNameFirstLang : item.ItemNameSecondlang}
                                                            {item.ItemDescFirstLang.trim() || item.ItemDescSecondlang.trim() ?
                                                                <>
                                                                    <p style={StyleItemDescriptionNameColor} className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "DescriptionItem" : "ArabicDescriptionItem"}`}>
                                                                        {isEnglish ? item.ItemDescFirstLang : item.ItemDescSecondlang}
                                                                    </p>
                                                                </>
                                                                :
                                                               <></>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <p className={`${isEnglish || SecondLangaugeDirection === "LTR" ? "PricePosEng" : "PricePosAr"}`} style={EnterStringStyle}>
                                                        {item?.IsItemFreePrice ? (
                                                            <span style={StylePriceColor} className="FontPrice">{item?.ItemPrice}</span>
                                                        ) : (
                                                            <>
                                                                <span style={StylePriceColor} className="FontPrice"> {FormattingPrice(item?.ItemPrice, Delimiters, NbrAfterDelimiters)}</span>
                                                                {item.ItemPrice && (
                                                                    <span style={StyleCURRColor} className="FontCurr">  {" " + MenuInfo?.RestInf?.Restaurant.Curr}</span>
                                                                )}
                                                            </>
                                                        )}
                                                        {item?.RecommendedChoice && (
                                                            <ItemDescription status="Rec" emoji="👑" />
                                                        )}
                                                        {item?.PopularChoice && (
                                                            <ItemDescription status="Popular" emoji="🔥" />
                                                        )}
                                                        {item?.NewChoice && (
                                                            <ItemDescription status="New" emoji="🆕" />
                                                        )}
                                                        {item?.SpicyChoice && (
                                                            <ItemDescription status="Spicy" emoji="🌶️" />
                                                        )}
                                                        {item?.VeganChoice && (
                                                            <ItemDescription status="Vegan" emoji="🌿" />
                                                        )}
                                                        {item?.OfferChoice && (
                                                            <ItemDescription status="Offre" emoji="📌" />
                                                        )}
                                                    </p>
                                                </Grid>
                                            </ListItem>
                                        </div>
                                    </div>
                                )}
                        </>

                    )}
                </div>
            )}
        </div>
    }


    const ItemDescription = ({ item, status, emoji }) => {
        const getStatusStyle = () => {
            switch (status) {
                case 'Popular':
                    return { color: 'white', backgroundColor: '#ad0dde', padding: '2px 10px', borderRadius: '5px', display: 'inline-block' };
                case 'New':
                    return { color: 'white', backgroundColor: '#007bff', padding: '2px 10px', borderRadius: '5px', display: 'inline-block' };
                case 'Spicy':
                    return { color: 'white', backgroundColor: '#ff6347', padding: '2px 10px', borderRadius: '5px', display: 'inline-block' };
                case 'Rec':
                    return { color: 'white', backgroundColor: '#28a745', padding: '2px 10px', borderRadius: '5px', display: 'inline-block' };
                case 'Vegan':
                    return { color: 'white', backgroundColor: '#4CAF50', padding: '2px 10px', borderRadius: '5px', display: 'inline-block' };
                case 'Offre':
                    return { color: 'white', backgroundColor: '#7c57f7', padding: '2px 10px', borderRadius: '5px', display: 'inline-block' };


                // Add more cases for other statuses if needed
                default:
                    return {};
            }
        };

        return (
            <>
                {status && (
                    <div className="itemdesc" id={`${status.toLowerCase()}icon`} style={{ marginTop: '3px', fontSize: '14px', width: '100%' }}>
                        <span style={getStatusStyle()}>{status}{emoji}</span>
                    </div>
                )}
            </>
        );
    };

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 0.5,
                width: '70%'

            }}
        />
    );
    const handleClickOpenOrder = (event) => {
        setOpenVireOrder(true);
    };

    const handleCloseOrder = () => {
        setOpenVireOrder(false);
    };

    const classes = useStyles();
    const classesTogleCat = useStylesToggleDiv();


    const ClearSearch = () => {
        setSearchValue('')
    };
    const ChangeToAr = () => {
        setIsEnglish(false);
        setSearchValue('')
    };

    const ChangeToEng = () => {
        setIsEnglish(true);
        setSearchValue('')
    };


    const onItemClickWithoutClose = useCallback(GetUpdatedRowsFromChild => {

        let ItemIdWithQty = GetUpdatedRowsFromChild?.map(function (item) { return item.ItemId + "~" + item.Qty });
        setUpdatedRowsFromChild(ItemIdWithQty)

        //this line ktr raya7ne u can extract array to object 
        let extractedValue = GetUpdatedRowsFromChild?.map(function (item) { return item.ItemId })
        if (extractedValue.length == 0)
            setOpenVireOrder(false);

        setSelectedDivColor(extractedValue);
    });
    const labelStyles = {
        color: 'white',
    };
    const ThreeDbuttonStyle = {
        backgroundColor: 'white', // Background color
        border: '1px solid  #1C4559', // Border color
        color: 'white', // Font color
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgb(28, 69,89)', // Box shadow for the 3D effect
        transition: 'box-shadow 0.3s ease', // Smooth transition for the effect
        margin: '2px 0.5px',
    };

    //for backgroubfs custo
    const StyleCategoryBG = {
        backgroundColor: ColorsMenuState?.CategoryBgColor ||  'rgb(28, 69, 89)' ,
    };
    const StyleSubCategoryBG = {
        backgroundColor: ColorsMenuState?.SubCategoryBgColor || 'rgb(28, 69, 89)',
    };
    const StyleSubCategorySquareBGWithColors = {
        backgroundColor: ColorsMenuState?.SubCategorySquareBgColor || 'rgb(36,90,118)',
        color: ColorsMenuState?.SubCategoryNameTextColorRow || 'rgb(252, 171, 31)',
    };
    const StyleItemsBG =  
        //without backgroundColor cause it's added on calling
        ColorsMenuState?.ItemBgColor || 'rgb(28, 69, 89)';

    const StyleSelectedItemsBG =
        //without backgroundColor cause it's added on calling
        ColorsMenuState?.SelectedItemBgColor || 'rgb(36,90,118)';



        //for text color
    const StyleCategoryNameColor = {
        color: ColorsMenuState?.CategoryNameTextColor || 'white',
    };
        //for text color
    const StyleSubCategoryNameColor = {
        color: ColorsMenuState?.SubCategoryNameTextColor || 'white',
    };
     
        //for text color
    const StyleItemNameColor = {
        color: ColorsMenuState?.ItemNameTextColor || 'white',
    }; 

    const StyleItemDescriptionNameColor = { whiteSpace: 'pre-line', color: ColorsMenuState?.ItemDescriptionTextColor || 'white' };


    const StylePriceColor = {
        color: ColorsMenuState?.PriceTextColor || 'white',
    };
    const StyleCURRColor = {
        color: ColorsMenuState?.CurrencyTextColor || 'white',
    };



    //hexToRgba i added this function to convert the hexadecimal in the db to rgba cause the color picker html doesn't support RGBA it support only rgb , and i need the rgba to control the oacity of color....
    const BoxShadowColor = ColorsMenuState?.BoxItemShadowColor
        ? hexToRgba(ColorsMenuState.BoxItemShadowColor, 0.2)
        : 'rgba(255, 255, 255, 0.1)';

    
    const EnterStringStyle = { whiteSpace: 'pre-line' };
     

    return (
        <div>
            {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7668596467649569"
     crossorigin="anonymous"></script>  */}
            {VisibleLanguageBar?.toString().toUpperCase() === 'TRUE' ? <>  <div className={classes.root} >
                <ButtonGroup size="medium" aria-label="large outlined primary button group" className="btnGrpTranslation">
                    <Button className="btnArTrans" onClick={() => ChangeToAr()}
                        style={ThreeDbuttonStyle}>
                        <span className='ColorTextLang'> {SecondLangauge}</span>
                    </Button>
                    <Button className="btnEngTrans" onClick={() => ChangeToEng()}
                        style={ThreeDbuttonStyle}>
                        <span className='ColorTextLang'> English</span>
                    </Button>
                </ButtonGroup>
            </div>
                <br /></> : <></>}

            {IsShowCategoriesBtn && (
                <div className="CatPosonTopDiv">
                    <Tabs className={`${isEnglish ? "EnglishCat" : "ArabicCat"}`}
                        value={SelectedCategorietoggle}
                        onChange={ChangeToggleCatBtn}
                        indicatorColor="secondary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable"
                        style={StyleCategoryBG} // Applying the style here
                    >
                        {CreateToggleButtonsforCat()}
                    </Tabs>
                </div>
            )}
            <br />
            <div className="CatPosonTop">
                {SubCatbyCat?.length === 0 ? <></>
                    : <>
                        <Tabs
                            variant="scrollable"
                            scrollButtons="on"
                            aria-label="scrollable"
                            className={`${isEnglish ? "EnglishSubCat" : "ArabicSubCat"}`}
                            style={StyleSubCategoryBG} // Applying the style here
                        >
                            {CreateSubCatbyCatUI()}
                        </Tabs>
                    </>}
            </div>
            <br />
            <div className="divsearch">
                <TextField
                    InputLabelProps={{
                        style: labelStyles,

                    }}
                    className='TxtSearch'
                    value={SearchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    label={isEnglish ? "search" : "search"}
                    InputProps={{
                        endAdornment: <IconButton color="secondary" id="ClearBtn" onClick={ClearSearch}>
                            {SearchValue ? <ClearTwoToneIcon position="end"   ></ClearTwoToneIcon> : <></>}
                        </IconButton>,
                        style: {
                            color: 'white',
                            border: 'none', // Removes all borders
                            borderBottom: '1px solid white' // Applies a bottom border only
                        },
                    }}
                />
            </div>
            <br />
            {/*Guide Size For Achta w Asal Kfarroumen*/}
            {MenuInfo?.RestInf?.Restaurant.Name === 'Achta-W-Assal' && (
                <>
                    <Divider variant="middle" style={{ background: "rgb(233, 236, 239)" }} />
                    <div className="DivSizeGuide">
                        <div className="SizeGuide1Unit">S: Small</div>
                        <div className="SizeGuide1Unit">M: Medium</div>
                        <div className="SizeGuide1Unit">L: Large</div>
                        <div className="SizeGuide1Unit">1L: 1 Litre</div>
                    </div>
                </>
            )}

            <div>
                {CreateItemsFromSubCat()}
            </div>
            {/*Display Info tab in the bottom of menu tab*/}
            {IsRestInfoShowInMenuTab && (
                <AboutRest RestInf={MenuInfo.RestInf} />
            )}

            {SelectedDivColor.length === 0 ? <></>
                : <> <div className="ViewOrderDiv">
                    <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} badgeContent={SelectedDivColor.length} color="secondary">
                        <Button variant="contained" size='large' color="primary" startIcon={<ShoppingCartOutlinedIcon />} onClick={handleClickOpenOrder} />
                    </Badge>
                    <Menu
                        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        elevation={10000}
                        id="simple-menu"
                        OpenVireOrder={OpenVireOrder}
                        open={Boolean(OpenVireOrder)}
                        onClose={handleCloseOrder}>
                        <Button className="CatPosonTop" variant="contained" color="secondary" endIcon={<CloseOutlinedIcon />} size="small" onClick={() => handleCloseOrder()}>
                            Close
                        </Button>
                        <ViewOrder ItemsIds={SelectedDivColor} ItemsWithQty={UpdatedRowsFromChild} RestInformation={MenuInfo?.RestInf?.Items} Language={isEnglish} InitialPrice={MenuInfo?.RestInf?.Items} Delimiters={MenuInfo?.RestInf?.Restaurant?.Delimiters} NbrAfterDelimiters={MenuInfo?.RestInf?.Restaurant?.NbrAfterDelimiters} onItemClickWithoutClose={onItemClickWithoutClose} Curr={MenuInfo?.RestInf?.Restaurant.Curr} />
                    </Menu>
                </div>
                </>}
            {isModalOpen && (
                <ModalImage
                    OpenModal={isModalOpen}
                    ImageURL={ImageURLMaximize}
                    ImageDesc={ImageDescMaximize}
                    HandleCloseImage={(event) => {
                        setModalOpen(false);
                    }
                    } ></ModalImage>
            )}
        </div>
    )
}