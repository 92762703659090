import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import { GetCatsForddl, UpdateSubCategorys, InsertSubCategory } from '../apis/SubCategoriesApis';
import { GetCategorys } from '../apis/CategoriesApis';
import DisplayAlert from '../components/DisplayAlert'
import CircularProgress from '@material-ui/core/CircularProgress';
import { uploadImage, DeleteImage } from '../apis/CategoriesApis';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

const useStylesddl = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function DialogForSubCategory({ isUpdate, getData, SubCategorieRow, openModal, handleCloseModal, OpenSnackBarForMsg, CategorieIdfromGrid }) {
    const [loading, setLoading] = useState(false);
    const [openAlert, setopenAlert] = useState(false)
    const [AlertMessage, setAlertMessage] = useState("")

    const [Categorysddl, setCategorysddl] = useState([])
    const [SelectedCategory, setSelectedCategory] = useState("")

    const [SubCategoryArabicNameEdit, setSubCategoryArabicNameEdit] = useState("")
    const [SubCategoryEnglishNameEdit, setSubCategoryEnglishNameEdit] = useState("")
    const [SubCatDescriptionAr, setSubCatDescriptionAr] = useState("")
    const [SubCatDescription, setSubCatDescription] = useState("")
    const [OrderNumber, setOrderNumber] = useState("")
    const [images, setimages] = useState()
    const [IsStateSubCatVisible, setIsStateSubCatVisible] = useState(true);
    const classesddl = useStylesddl();


    useEffect(() => {
        getSubCategoriesforddlAPI();
        if (isUpdate && SubCategorieRow) {
            setSelectedCategory(CategorieIdfromGrid);
            setSubCategoryArabicNameEdit(SubCategorieRow.SubCatNameSecondlang);
            setSubCategoryEnglishNameEdit(SubCategorieRow.SubCatNameFirstLang)
            setSubCatDescription(SubCategorieRow.SubCatDescFirstLang)
            setSubCatDescriptionAr(SubCategorieRow.SubCatDescSecondlang)
            setOrderNumber(SubCategorieRow.SubCatOrderNbr);
            setIsStateSubCatVisible(SubCategorieRow?.IsSubCatVisible)
        }
    }, [SubCategorieRow, getData])

    const handleCheckboxChangeVisibleItem = () => {
        setIsStateSubCatVisible(!IsStateSubCatVisible);
    };

    const handleColseAlert = () => {
        setopenAlert(false);
    };
    const update = async () => {
        return await callbackEdit({
            SubCatId: SubCategorieRow.SubCatId,
            CatId: SelectedCategory,
            SubCatNameSecondlang: SubCategoryArabicNameEdit,
            SubCatNameFirstLang: SubCategoryEnglishNameEdit,
            SubCatDescFirstLang: SubCatDescription,
            SubCatDescSecondlang: SubCatDescriptionAr,
            SubCatOrderNbr: OrderNumber,
            IsSubCatVisible: IsStateSubCatVisible,
        })
    } 

    const insert = async () => {
        return await callbackInsert({
            RestId: localStorage.getItem("RestaurantId"),
            CatId: SelectedCategory,
            SubCatNameFirstLang: SubCategoryEnglishNameEdit,
            SubCatNameSecondlang: SubCategoryArabicNameEdit,
            SubCatDescFirstLang: SubCatDescription,
            SubCatDescSecondlang: SubCatDescriptionAr,
            SubCatOrderNbr: OrderNumber,
            IsSubCatVisible: IsStateSubCatVisible, 
        })

    }
    const ClearFields = () => {
        setSubCategoryArabicNameEdit("");
        setSubCategoryEnglishNameEdit("");
        setSelectedCategory("");
        setimages("");
        setOrderNumber("");
        setIsStateSubCatVisible(true);
    };

    const callbackInsert = async (data) => {
        const InsertRes = await InsertSubCategory(data);
        const CnstErrorMsg = InsertRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to Insert Sub-Cat, " + InsertRes?.Error?.Message)
            setLoading(false);
        }
        else {
            if (images)
                await uploadImage(images, "Sub-Categories", InsertRes)
            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("Sub-Categoty Inserted successfully ");
            ClearFields();
            setLoading(false);
        }
    }

    const callbackEdit = async (data) => {
        const UpdateRes = await UpdateSubCategorys(data);
        const CnstErrorMsg = UpdateRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to update cat, " + UpdateRes?.Error?.Message)
            setLoading(false);
        }
        else {
            const ImageID = SubCategorieRow.Image?.id;
            //check if user has been upload a image on update mode , so we need to delete the old one and reupload a new image
            if (ImageID && images) {
                await (DeleteImage(({ RelatedId: ImageID, RelatedType: "Sub-Categories" })))
            }
            if (images)
                await uploadImage(images, "Sub-Categories", data.SubCatId)
            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("Sub-Categoty Updated successfully ");
            ClearFields();
            setLoading(false);
        }
    }


    const submit = async () => {
        if (SelectedCategory == '' || (!SubCategoryArabicNameEdit && !SubCategoryEnglishNameEdit))
            return alert('Please Check mandatory Fields')

        if (SelectedCategory !== '') {
            setLoading(true);

            if (isUpdate) {
                await update()
            } else {
                await insert()
            }
        }
        else {
            setopenAlert(true);
            setAlertMessage("Please select a category");
        }
    }

    const onImageChange = event => {
        setimages(event.target.files[0]);
    };

    const handleChangeddl = (event) => {
        setSelectedCategory(event.target.value);
        // console.log("selected", event.target.value)
    };

    const getSubCategoriesforddlAPI = async () => {
        const result = await GetCategorys({ "RestId": localStorage.getItem("RestaurantId") });
        setCategorysddl(result?.Dt);

    }
    return (
        <div>
            <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Sub-Categories Info</DialogTitle>
                <DialogContent>
                    <form  >
                        <FormControl className={classesddl.formControl} required >
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                autoFocus
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={SelectedCategory}
                                onChange={handleChangeddl} >

                                {Categorysddl.map(item =>
                                    <MenuItem key={item.CatId} value={item.CatId}   >
                                        <ListItemText primary={item.CatNameFirstLang + "_" + item.CatNameSecondlang} />
                                    </MenuItem>)
                                }

                            </Select>
                        </FormControl>

                        <br />
                        <TextField
                            margin="dense"
                            id="txtCatNameEng"
                            label="Sub-Cat English Name"
                            value={SubCategoryEnglishNameEdit}
                            onChange={(event) => setSubCategoryEnglishNameEdit(event.target.value)}
                            required
                        />
                        <br />
                        <TextField
                            margin="dense"
                            id="txtCatNameAr"
                            label="Sub-Cat SecLang Name"
                            value={SubCategoryArabicNameEdit}
                            onChange={(event) => setSubCategoryArabicNameEdit(event.target.value)}
                            required
                        />
                        <br />
                        <TextField
                            className="MultiLinesTextBox"
                            margin="dense"
                            id="txtSubCatDesc"
                            label="Sub-Cat Descirption Eng"
                            multiline
                            rows={4}
                            value={SubCatDescription}
                            onChange={(event) => setSubCatDescription(event.target.value)}
                            required
                        />
                        <br />
                        <TextField
                            className="MultiLinesTextBox"
                            margin="dense"
                            id="txtSubCatDescAt"
                            label="Sub-Cat Descirption Sec"
                            multiline
                            rows={4}
                            value={SubCatDescriptionAr}
                            onChange={(event) => setSubCatDescriptionAr(event.target.value)}
                            required
                        />
                        <br />
                        <TextField
                            type="number"
                            margin="dense"
                            id="txtOrder"
                            label="Order Nbr"
                            value={OrderNumber}
                            onChange={(event) => setOrderNumber(event.target.value)}
                            required
                        />
                        <br />
                        <br />
                        <FormGroup>
                            <InputLabel id="demo-multiple-checkbox-label">Visible
                                <Checkbox checked={IsStateSubCatVisible} onChange={() => handleCheckboxChangeVisibleItem()} /> </InputLabel>
                        </FormGroup> 

                        <br />
                        <input
                            accept="image/*"
                            name="file"
                            id="contained-button-file"
                            single
                            type="file"
                            onChange={onImageChange}
                        />
                    </form>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '60%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
                    </div>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => submit()} color="primary" type="submit">
                        {isUpdate ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
                <DisplayAlert open={openAlert} message={AlertMessage} close={handleColseAlert} />
            </Dialog>

        </div>
    )
}
