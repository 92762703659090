import React, { useState, useEffect } from 'react';
import { InsertColorRest, DeleteColorRest, GetColorRest } from '../apis/ColorCustomizationAPI';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'; 
import Button from '@material-ui/core/Button'; 
import { Helmet } from 'react-helmet';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
function App() {


    const [categoryNameColor, setCategoryNameColor] = useState('#FFFFFF');
    const [subCategoryNameColorRow, setSubCategoryNameColorRow] = useState('#FCAB1F');
    const [subCategoryNameColorSquare, setSubCategoryNameColorSquare] = useState('#FFFFFF');
    const [itemNameColor, setItemNameColor] = useState('#FFFFFF');
    const [itemDescriptionColor, setItemDescriptionColor] = useState('#FFFFFF');
    const [priceColor, setPriceColor] = useState('#FFFFFF');
    const [CurrencyColor, setCurrencyColor] = useState('#FFFFFF');
    const [categoryBackgroundColor, setCategoryBackgroundColor] = useState('#1C4559');
    const [subCategoryBackgroundColor, setSubCategoryBackgroundColor] = useState('#1C4559');
    const [subCategorySquareBackgroundColor, setsubCategorySquareBackgroundColor] = useState('#245A76');
    const [itemBackgroundColor, setItemBackgroundColor] = useState('#1C4559');
    const [ItemSelectedBackgroundColor, setItemSelectedBackgroundColor] = useState('#245A76');
    const [WallpaperBackgroundBackgroundColor, setWallpaperBackgroundBackgroundColor] = useState('#1C4559');
    const [BoxItemShadowColor, setBoxItemShadowColor] = useState('#ffffff');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleColorChange = (setState) => (e) => {
        setState(e.target.value);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage('');
        setErrorMessage('');
    };


    const useStyles = makeStyles((theme) => ({
        body: {
            backgroundColor: '#f0f2f5',
            fontFamily: 'Roboto, sans-serif',
        },
        appContainer: {
            marginTop: theme.spacing(2),
        },
        formContainer: {
            padding: theme.spacing(2),
            backgroundColor: '#fff',
            borderRadius: theme.spacing(1),
            boxShadow: theme.shadows[1],
        },
        title: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.main,
        },
        sectionTitle: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            color: theme.palette.secondary.main,
        },
        actionButtons: {
            textAlign: 'center',
            marginTop: theme.spacing(3),
        },
        actionButton: {
            minWidth: 150,
        },
        iconLink: {
            margin: theme.spacing(1),
        },
    }));

    const classes = useStyles();
    async function DeleteDataPerRestIdBeforeInsert() {
        setLoading(true);
        const payload = {
            RestId: localStorage.getItem("RestaurantId"),
        };
        const CnstRestnameResponse = await DeleteColorRest(payload);
        setLoading(false);
    }

    async function InsertDataColor() {
        setLoading(true);
        await DeleteDataPerRestIdBeforeInsert();

        const payload = {
            RestId: localStorage.getItem("RestaurantId"),
            CategoryNameTextColor: categoryNameColor,
            SubCategoryNameTextColor: subCategoryNameColorSquare,
            SubCategoryNameTextColorRow: subCategoryNameColorRow,
            ItemNameTextColor: itemNameColor,
            ItemDescriptionTextColor: itemDescriptionColor,
            PriceTextColor: priceColor,
            CurrencyTextColor: CurrencyColor,
            CategoryBgColor: categoryBackgroundColor,
            SubCategoryBgColor: subCategoryBackgroundColor,
            SubCategorySquareBgColor: subCategorySquareBackgroundColor,
            ItemBgColor: itemBackgroundColor,
            SelectedItemBgColor: ItemSelectedBackgroundColor,
            WallpaperBackgroundBgColor: WallpaperBackgroundBackgroundColor,
            BoxItemShadowColor: BoxItemShadowColor,
        };
        const CnstRestnameResponse = await InsertColorRest(payload);
        if (!CnstRestnameResponse?.Error?.Message) {
            setSuccessMessage('Color changed successfully.');
        } else {
            setErrorMessage('Failed to change color: ' + CnstRestnameResponse?.Error?.Message);
        }
        setLoading(false);
    }

    async function GetDataColor() {
        setLoading(true);
        const payload = {
            RestId: localStorage.getItem("RestaurantId"),
        };

        try {
            const ColorDBResp = await GetColorRest(payload);
            if (ColorDBResp?.Error?.Message) {
                setErrorMessage('Failed to Get Colors: ' + ColorDBResp?.Error?.Message);
            } else if (ColorDBResp?.Dt[0]) {  
                // Assuming the response contains the color settings 
                setCategoryNameColor(ColorDBResp?.Dt[0]?.CategoryNameTextColor);
                setSubCategoryNameColorSquare(ColorDBResp?.Dt[0]?.SubCategoryNameTextColor);
                setSubCategoryNameColorRow(ColorDBResp?.Dt[0]?.SubCategoryNameTextColorRow);
                setItemNameColor(ColorDBResp?.Dt[0]?.ItemNameTextColor);
                setItemDescriptionColor(ColorDBResp?.Dt[0]?.ItemDescriptionTextColor);
                setCurrencyColor(ColorDBResp?.Dt[0]?.CurrencyTextColor);
                setPriceColor(ColorDBResp?.Dt[0]?.PriceTextColor);
                setCategoryBackgroundColor(ColorDBResp?.Dt[0]?.CategoryBgColor);
                setSubCategoryBackgroundColor(ColorDBResp?.Dt[0]?.SubCategoryBgColor);
                setsubCategorySquareBackgroundColor(ColorDBResp?.Dt[0]?.SubCategorySquareBgColor);
                setItemBackgroundColor(ColorDBResp?.Dt[0]?.ItemBgColor);
                setItemSelectedBackgroundColor(ColorDBResp?.Dt[0]?.SelectedItemBgColor);
                setWallpaperBackgroundBackgroundColor(ColorDBResp?.Dt[0]?.WallpaperBackgroundBgColor);
                setBoxItemShadowColor(ColorDBResp?.Dt[0]?.BoxItemShadowColor); 
            }
        } catch (error) {
            setErrorMessage('Failed to Get Colors: ' + error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        GetDataColor();
    }, []);

    const containerStyles = {
        position: 'absolute',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    };

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        pointerEvents: loading ? 'auto' : 'none',
    };

    return (
        <div className="container">
            <Helmet>
                <title>Color Customization</title>
            </Helmet>

            <div>
                {loading && <div style={overlayStyles}></div>}
                <div style={containerStyles}>
                    {loading && (
                        <CircularProgress
                            size={75}
                            thickness={5}
                            style={{ color: 'white' }}
                            aria-label="Loading"
                        />
                    )}
                </div>
            </div>

            <section className="group"> 

                <Typography variant="h6" className={classes.sectionTitle}>Menu Text Colors</Typography>
                <div className="section">
                    <label>Category Name</label>
                    <input type="color" value={categoryNameColor} onChange={handleColorChange(setCategoryNameColor)} />
                </div>

                <div className="section">
                    <label>Sub Category Name Row</label>
                    <input type="color" value={subCategoryNameColorRow} onChange={handleColorChange(setSubCategoryNameColorRow)} />
                </div>

                <div className="section">
                    <label>Sub Category Name Square</label>
                    <input type="color" value={subCategoryNameColorSquare} onChange={handleColorChange(setSubCategoryNameColorSquare)} />
                </div>

                <div className="section">
                    <label>Item Name</label>
                    <input type="color" value={itemNameColor} onChange={handleColorChange(setItemNameColor)} />
                </div>

                <div className="section">
                    <label>Item Description</label>
                    <input type="color" value={itemDescriptionColor} onChange={handleColorChange(setItemDescriptionColor)} />
                </div>

                <div className="section">
                    <label>Price</label>
                    <input type="color" value={priceColor} onChange={handleColorChange(setPriceColor)} />
                </div>

                <div className="section">
                    <label>Currency</label>
                    <input type="color" value={CurrencyColor} onChange={handleColorChange(setCurrencyColor)} />
                </div>
            </section>

            <section className="group"> 

                <Typography variant="h6" className={classes.sectionTitle}>Menu Background Colors</Typography>
                <div className="section">
                    <label>Category Background</label>
                    <input type="color" value={categoryBackgroundColor} onChange={handleColorChange(setCategoryBackgroundColor)} />
                </div>

                <div className="section">
                    <label>Sub Category Row Background</label>
                    <input type="color" value={subCategoryBackgroundColor} onChange={handleColorChange(setSubCategoryBackgroundColor)} />
                </div>

                <div className="section">
                    <label>Sub Category Square Background</label>
                    <input type="color" value={subCategorySquareBackgroundColor} onChange={handleColorChange(setsubCategorySquareBackgroundColor)} />
                </div>

                <div className="section">
                    <label>Item Background </label>
                    <input type="color" value={itemBackgroundColor} onChange={handleColorChange(setItemBackgroundColor)} />
                </div>

                <div className="section">
                    <label>Selected Item Background </label>
                    <input type="color" value={ItemSelectedBackgroundColor} onChange={handleColorChange(setItemSelectedBackgroundColor)} />
                </div> 
                <div className="section">
                    <label>Box Item Shadow Color</label>
                    <input type="color" value={BoxItemShadowColor} onChange={handleColorChange(setBoxItemShadowColor)} />
                </div>
            </section>

            <section className="group">  
                <Typography variant="h6" className={classes.sectionTitle}>Menu Wallpaper Colors</Typography>
                <div className="section">
                    <label>Wallpaper Background</label>
                    <input type="color" value={WallpaperBackgroundBackgroundColor} onChange={handleColorChange(setWallpaperBackgroundBackgroundColor)} />
                </div>
 
            </section>
             
            <Button onClick={InsertDataColor} type="submit" variant="contained" color="primary" fullWidth disabled={loading}>Save Colors</Button>

            <Snackbar open={!!successMessage || !!errorMessage} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity={successMessage ? 'success' : 'error'} elevation={6} variant="filled">
                    {successMessage || errorMessage}
                </MuiAlert>
            </Snackbar>

            <style jsx>{`
                .container {
                max-width: 600px;
                margin: auto;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .group {
                    margin-bottom: 20px;
                }
                .group h2 {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                .section {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
                .section label {
                    flex: 1;
                    margin-right: 10px;
                } 
                .section input[type="color"] { 
                    border-radius: 5px;
                    border: 1px solid #ddd; 
                }


                input[type="color"] {
                    vertical-align: middle;
                }
                button {
                    display: block;
                    margin: 20px auto;
                    padding: 10px 20px;
                    font-size: 16px;
                    background-color: #4CAF50;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }
                button:hover {
                    background-color: #45a049;
                }
            `}</style>
        </div>
    );
}

export default App;
