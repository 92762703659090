import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress'; 
//apis
import { CheckDayTimeIsExist,GetRestInfoDayTime, InsertRestInfoDayTime, UpdateRestInfoDayTime, DeleteRestInfoDayTime } from '../apis/RestInfoDayTimeAPI';
export default function DialogForRestInfoDayTime({ isUpdate, getData, RestInfoDayTimeRow, openModal, handleCloseModal, OpenSnackBarForMsg }) {
   
    const [loading, setLoading] = useState(false);
    const [day, setDay] = useState('');
    const [openingHour, setOpeningHour] = useState('');
    const [closingHour, setClosingHour] = useState('');

    const [DayBeforeUpdate, setDayBeforeUpdate] = useState('');
    useEffect(() => {
        // console.log(CategorieRow)
        if (isUpdate && RestInfoDayTimeRow) {

            setDayBeforeUpdate(RestInfoDayTimeRow.Day);

            setDay(RestInfoDayTimeRow.Day);
            setOpeningHour(RestInfoDayTimeRow.OpeningHour)
            setClosingHour(RestInfoDayTimeRow.ClosingHour);
        }
    }, [RestInfoDayTimeRow, getData])

    const update = async () => {
        if (DayBeforeUpdate !== day) {

           const IsExist= await CheckDayTimeIsExist({
               RestId: localStorage.getItem("RestaurantId"),
                Day: day
           })
            console.log("IsExist", IsExist?.Error)
            if (IsExist?.Error) {
                OpenSnackBarForMsg("Failed to Update Day/Hour, " + IsExist?.Error?.Message)
                setLoading(false);
            }
            else {
                return await callbackEdit({
                    RestInfoDayTimeId: RestInfoDayTimeRow.RestInfoDayTimeId,
                    Day: day,
                    OpeningHour: openingHour,
                    ClosingHour: closingHour,
                })
            }
        }
        else {
            return await callbackEdit({
                RestInfoDayTimeId: RestInfoDayTimeRow.RestInfoDayTimeId,
                Day: day,
                OpeningHour: openingHour,
                ClosingHour: closingHour,
            })
        }
    }

    const insert = async () => {

        return await callbackInsert({
            RestId: localStorage.getItem("RestaurantId"),
            Day: day,
            OpeningHour: openingHour,
            ClosingHour: closingHour, 
        })

    }
    const ClearFields = () => {
        setDay("");
        setOpeningHour("");
        setClosingHour(""); 
    };

    const callbackInsert = async (data) => {
        const InsertRes = await InsertRestInfoDayTime(data);
        const CnstErrorMsg = InsertRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to insert Day/Hour, " + InsertRes?.Error?.Message)
            setLoading(false);
        }
        else {
            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("Day/Hour Inserted successfully ");
            ClearFields();
            setLoading(false);
        }
    }

    const callbackEdit = async (data) => {
        const UpdateRes = await UpdateRestInfoDayTime(data);
        const CnstErrorMsg = UpdateRes?.Error?.Message;
        if (CnstErrorMsg) {
            OpenSnackBarForMsg("Failed to update Day/Hour, " + UpdateRes?.Error?.Message)
            setLoading(false);
        }
        else { 
            //check if user has been upload a image on update mode and id of image is not null, so we need to delete the old one and reupload a new image


            await getData()
            handleCloseModal();
            OpenSnackBarForMsg("Day/Hour Updated successfully ");
            ClearFields();
            setLoading(false);
        }
    }

    const submit = async () => {

        if (!day && !setOpeningHour && !setClosingHour)
            return alert('Please Check mandatory Fields')

        setLoading(true);
        if (isUpdate)
            await update()
        else
            await insert()
    }


    const onImageChange = event => {
        // console.log(event.target.files); 

    };
    const handleClosingHourChange = (event) => {
        setClosingHour(event.target.value);
    };
    const handleOpeningHourChange = (event) => { 
        setOpeningHour(event.target.value);
    };
    const handleDayChange = (event) => {
        setDay(event.target.value);
    };
     

    return (
        <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Opening Days/Hours</DialogTitle>
            <DialogContent>
                <form>
                    <label>
                        Day: 

                        <select value={day} onChange={handleDayChange} required>
                            <option value="" disabled>Select a day</option>
                            <option value="Every Day">Every Day</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                            <option value="Every Day Except Monday">Every Day Except Monday</option>
                            <option value="Every Day Except Tuesday">Every Day Except Tuesday</option>
                            <option value="Every Day Except Wednesday">Every Day Except Wednesday</option>
                            <option value="Every Day Except Thursday">Every Day Except Thursday</option>
                            <option value="Every Day Except Friday">Every Day Except Friday</option>
                            <option value="Every Day Except Saturday">Every Day Except Saturday</option>
                            <option value="Every Day Except Sunday">Every Day Except Sunday</option>
                            <option value="كل يوم">كل يوم</option>
                            <option value="الاثنين">الاثنين</option>
                            <option value="الثلاثاء">الثلاثاء</option>
                            <option value="الأربعاء">الأربعاء</option>
                            <option value="الخميس">الخميس</option>
                            <option value="الجمعة">الجمعة</option>
                            <option value="السبت">السبت</option>
                            <option value="الأحد">الأحد</option>
                            <option value="كل يوم ما عدا الاثنين">كل يوم ما عدا الاثنين</option>
                            <option value="كل يوم ما عدا الثلاثاء">كل يوم ما عدا الثلاثاء</option>
                            <option value="كل يوم ما عدا الأربعاء">كل يوم ما عدا الأربعاء</option>
                            <option value="كل يوم ما عدا الخميس">كل يوم ما عدا الخميس</option>
                            <option value="كل يوم ما عدا الجمعة">كل يوم ما عدا الجمعة</option>
                            <option value="كل يوم ما عدا السبت">كل يوم ما عدا السبت</option>
                            <option value="كل يوم ما عدا الأحد">كل يوم ما عدا الأحد</option>
                        </select>
                    </label>
                    <br />
                    <label>
                        Opening Hour:
                        <input type="time" value={openingHour} onChange={handleOpeningHourChange} required />
                    </label>
                    <br />
                    <label>
                        Closing Hour:
                        <input type="time" value={closingHour} onChange={handleClosingHourChange} required />
                    </label>
                    <br />


                </form>
                <br></br>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        position: 'absolute', left: '50%', top: '60%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
                </div>
                <Button onClick={handleCloseModal} color="secondary">
                    Cancel
                </Button>
                <Button onClick={(e) => submit()} color="primary" type="submit">
                    {isUpdate ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
