import React, { useEffect, useState } from 'react';
import AppConfig from '../AppSettings';
import CryptoJS from 'crypto-js';

export const FormattingPrice = (Price, Delimiters, NbrAfterDelimiters) => {

    Price = Price.toString().replace(/,/g, '').replace(" ", "");
    var Delemiter = Delimiters;
    var Fraction = NbrAfterDelimiters;
    //if currency like lebanese //keep it == cause Converts types before comparing
    if (Delemiter === "," && Fraction == 3) {

        if (Price.length === 4) {
            var FirstNumber = Price.substring(0, 1);
            var RestOfNumber = Price.substring(1, Price.length);
            Price = FirstNumber + Delemiter + RestOfNumber;
        }
        else if (Price.length === 5) {
            FirstNumber = Price.substring(0, 2);
            RestOfNumber = Price.substring(2, Price.length);
            Price = FirstNumber + Delemiter + RestOfNumber;
        }
        else if (Price.length === 6) {
            FirstNumber = Price.substring(0, 3);
            RestOfNumber = Price.substring(3, Price.length);
            Price = FirstNumber + Delemiter + RestOfNumber;
        }
        else if (Price.length === 7) {
            FirstNumber = Price.substring(0, 1);
            var SecondthreeNumb = Price.substring(1, 4);
            RestOfNumber = Price.substring(4, Price.length);
            Price = FirstNumber + Delemiter + SecondthreeNumb + Delemiter + RestOfNumber;
        }
    }
    //    else if (Delemiter == "." && Fraction == 2) {

    //    }
    return Price;
}
export const EncryptString = async (StrStringToEncrypt) => {
    var key = CryptoJS.enc.Utf8.parse(AppConfig.KeyEncryptoJS);
    var Myiv = CryptoJS.enc.Utf8.parse(AppConfig.IVEncryptoJS);

    var encryptedStr = await CryptoJS.AES.encrypt((StrStringToEncrypt), key,
        {
            iv: Myiv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
    return encryptedStr;
}
export const DecryptString = async (encryptedStr) => {
    try {
        var key = CryptoJS.enc.Utf8.parse(AppConfig.KeyEncryptoJS);
        var Myiv = CryptoJS.enc.Utf8.parse(AppConfig.IVEncryptoJS);

        var decryptedBytes = CryptoJS.AES.decrypt(encryptedStr, key, {
            iv: Myiv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Convert decrypted bytes to a Base64-encoded string
        var decryptedStr = decryptedBytes.toString(CryptoJS.enc.Utf8);
           // You can return the Base64-encoded string or convert it to any other format as needed
        return decryptedStr;
    } catch (error) {
        // Handle decryption errors
        console.error("Error decrypting string:", error);
        return null;
    }
};


export const stringToBoolTrueValue = (str) => { 
    if (str !== '' && str !== undefined) {
        if (str.toString().toLowerCase() === 'true' || str.toString().toLowerCase() === 'yes') {
            return true;
        }
        return false;
    }
};

export const hexToRgba =  (hex, opacity) => {
    //console.log("hexhex", hex)
    // Check if hex is defined and is a string
    if (typeof hex !== 'string') {
        throw new TypeError('Expected a string for the hex color');
    }

    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Check if the hex code is valid (3 or 6 characters long)
    if (hex.length === 3) {
        // Convert shorthand hex code to full form, e.g., 'abc' -> 'aabbcc'
        hex = hex.split('').map(char => char + char).join('');
    } else if (hex.length !== 6) {
        throw new Error('Invalid hex color code');
    }

    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Ensure the opacity is between 0 and 1
    if (opacity < 0) {
        opacity = 0;
    } else if (opacity > 1) {
        opacity = 1;
    }

    // Combine them into an rgba string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
