import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';

//apis
import { UpdateCategorys, InsertCategory, uploadImage, DeleteImage } from '../apis/CategoriesApis';

export default function DialogForCategory({ isUpdate, getData, CategorieRow, openModal, handleCloseModal, OpenSnackBarForMsg }) {
  const [CategoryArabicNameEdit, setCategoryArabicNameEdit] = useState("")
  const [CategoryEnglishNameEdit, setCategoryEnglishNameEdit] = useState("")
  const [OrderNumber, setOrderNumber] = useState("")
  const [images, setimages] = useState()
    const [loading, setLoading] = useState(false);
    const [IsStateCatVisible, setIsStateCatVisible] = useState(true);

  useEffect(() => {
    // console.log(CategorieRow)
    if (isUpdate && CategorieRow) {
      setCategoryArabicNameEdit(CategorieRow.CatNameSecondlang);
      setCategoryEnglishNameEdit(CategorieRow.CatNameFirstLang)
        setOrderNumber(CategorieRow.OrderNbr);
        setIsStateCatVisible(CategorieRow?.IsCatVisible)
    }
  }, [CategorieRow, getData])

    const handleCheckboxChangeVisibleItem = () => {
        setIsStateCatVisible(!IsStateCatVisible);
    };

  const update = async () => {
    return await callbackEdit({
      CatId: CategorieRow.CatId,
      CatNameSecondlang: CategoryArabicNameEdit,
      CatNameFirstLang: CategoryEnglishNameEdit,
        OrderNbr: OrderNumber,
        IsCatVisible: IsStateCatVisible,
    })
  }

  const insert = async () => {
    return await callbackInsert({
      RestId: localStorage.getItem("RestaurantId"),
      CatNameFirstLang: CategoryEnglishNameEdit,
      CatNameSecondlang: CategoryArabicNameEdit,
        OrderNbr: OrderNumber,
        IsCatVisible: IsStateCatVisible,
    })

  }
  const ClearFields = () => {
    setCategoryArabicNameEdit("");
    setCategoryEnglishNameEdit("");
    setOrderNumber("");
      setimages("");
      setIsStateCatVisible(true);
  };

  const callbackInsert = async (data) => {
    const InsertRes = await InsertCategory(data);
    const CnstErrorMsg = InsertRes?.Error?.Message;
    if (CnstErrorMsg) {
      OpenSnackBarForMsg("Failed to insert cat, " + InsertRes?.Error?.Message)
      setLoading(false);
    }
    else {
      if (images)
        await uploadImage(images, "Categories", InsertRes,JSON.stringify(data))
      await getData()
      handleCloseModal();
      OpenSnackBarForMsg("categoty Inserted successfully ");
      ClearFields();
      setLoading(false);
    }
  }

  const callbackEdit = async (data) => {
    const UpdateRes = await UpdateCategorys(data);
    const CnstErrorMsg = UpdateRes?.Error?.Message;
    if (CnstErrorMsg) {
      OpenSnackBarForMsg("Failed to update cat, " + UpdateRes?.Error?.Message)
      setLoading(false);
    }
    else {
      const ImageID = CategorieRow.Image?.id;
      //check if user has been upload a image on update mode and id of image is not null, so we need to delete the old one and reupload a new image
      if (ImageID && images) {
        await (DeleteImage(({ RelatedId: ImageID,RelatedType:"Categories" })))
      }
      if (images)
        await uploadImage(images, "Categories", data.CatId, JSON.stringify(data))
      await getData()
      handleCloseModal();
      OpenSnackBarForMsg("categoty Updated successfully ");
      ClearFields();
      setLoading(false);
    }
  }

  const submit = async () => {

    if (!CategoryEnglishNameEdit && !CategoryArabicNameEdit)
      return alert('Please Check mandatory Fields')

    setLoading(true);
    if (isUpdate)
      await update()
    else
      await insert()
  }


  const onImageChange = event => {
    // console.log(event.target.files);
    setimages(event.target.files[0]);

  };
  return (
    <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Categories Info</DialogTitle>
      <DialogContent>
        <form>

                  <TextField  
            margin="dense"
            autoFocus
            id="txtCatNameEng"
            label="Category English Name"
            value={CategoryEnglishNameEdit}
            onChange={(event) => setCategoryEnglishNameEdit(event.target.value)}
            required
          />          <br />
          <TextField
            margin="dense"
            id="txtCatNameAr"
            label="Category SecLang Name"
            value={CategoryArabicNameEdit}
            onChange={(event) => setCategoryArabicNameEdit(event.target.value)}
            required
          />

          <br />
          <TextField
            type="number"
            margin="dense"
            id="txtOrder"
            label="Order Nbr"
            value={OrderNumber}
            onChange={(event) => setOrderNumber(event.target.value)}
          />
          <br />
                  <br />
                  <FormGroup>
                      <InputLabel id="demo-multiple-checkbox-label">Visible
                          <Checkbox checked={IsStateCatVisible} onChange={() => handleCheckboxChangeVisibleItem()} /> </InputLabel>
                  </FormGroup>

                  <br />
          <input
            accept="image/*"
            name="file"
            id="contained-button-file"
            single
            type="file"
            onChange={onImageChange}
          />
          <br />


        </form>
        <br></br>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            position: 'absolute', left: '50%', top: '60%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          {loading && <CircularProgress size={70} thickness={3} style={{ 'color': 'red' }} />}
        </div>
        <Button onClick={handleCloseModal} color="secondary">
          Cancel
        </Button>
        <Button onClick={(e) => submit()} color="primary" type="submit">
          {isUpdate ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
