import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@material-ui/data-grid';
import { GetSubCategorys, DeleteSubCategory, InsertSubCategory, DeleteSubCategoryImage } from '../apis/SubCategoriesApis';
import { GetCategorys, GetAllCategoryWithoutExceptions, GetCategoryByID, DeleteImage } from '../apis/CategoriesApis';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import DialogForSubCategory from '../components/DialogForSubCategory';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppConfig from '../AppSettings';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useToolBarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function BasicTable() {
  const classes = useStyles();
  const ToolBarStylesClasses = useToolBarStyles();

  function CustomToolbar({ ArrayOfIds }) {
    return (
      <GridToolbarContainer className={ToolBarStylesClasses.root}>
        <Button style={{ color: green[500] }} startIcon={<AddIcon />} onClick={handleClickOpen}>Add</Button>
        <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => removeBulkSubCategory(ArrayOfIds)}></Button>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const [ErrMsg, setErrMsg] = useState("")
  const [data, setData] = useState([]);
  const [selectedSubCategories, setselectedSubCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setopenSnackbar] = useState(false);
  const [SubCategoryId_Edit, setSubCategoryId_Edit] = useState("")
  const [OpenEditModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true);
  const [Msg, setMsg] = useState("")

  const handleCloseEditModal = () => {
    setOpenModal(false);
  };
  const handleClickOpenEditModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSnackbar = () => {
    setopenSnackbar(false);
  };

  const handleOpenSnackbar = (strMsg) => {
    setopenSnackbar(true);
    setMsg(strMsg)
  };

  const restructureData = async (data) => {
    const result = await data.map(async (structure) => {
      structure.Image = {
        id: (structure.SubCatFileName ? structure.SubCatId : null),
        uri: AppConfig.ImageUrl + "/" + structure.SubCatFileName
      };
      structure.edit = structure.SubCatId;
      structure.delete = structure.SubCatId;
        structure.deleteimage = structure.SubCatId;

        structure.Category =
            (structure?.CatNameFirstLang ? structure.CatNameFirstLang : "") +
            (structure?.CatNameFirstLang && structure?.CatNameSecondlang ? "_" : "") +
            (structure?.CatNameSecondlang ? structure.CatNameSecondlang : "");

      return structure;
    })
    return await Promise.all(result);
  }

  useEffect(() => {
    getSubCategoriesAPI();
  }, [])

  const getSubCategoriesAPI = async () => {  
    const result = await GetSubCategorys({
      "RestId": localStorage.getItem("RestaurantId") 
    }); 
    const CnstErrorMsg = result?.Error?.Message;
    if (CnstErrorMsg)
      handleOpenSnackbar("Failed to load data, " + result?.Error?.Message)

    setErrMsg(result?.Error?.Message)
    if(result?.Dt)
    setData(await restructureData(result?.Dt));
    // setMsg(result?.Error)
    setLoading(false);
 
  }


  const removeSubCategory = async (id) => {
    const Confirmation = window.confirm("Do you want to Delete?");
    if (Confirmation == true) {
      const ResDel = await DeleteSubCategory({ SubCatId: id });
      const CnstErrorMsg = ResDel?.Error?.Message; 
      if (CnstErrorMsg) {
        handleOpenSnackbar("Failed to Delete Sub-Cat, " + ResDel?.Error?.Message)
        setLoading(false);
      }
      else { 
        setLoading(true);
        // const ImageId = (data.find(o => o.SubCatId === id)?.Image.id);
        // if (ImageId) {
        //   await (DeleteImage(({ RelatedId: ImageId ,RelatedType:"Categories" })))
        // } 
        getSubCategoriesAPI();
        handleOpenSnackbar("Sub-Category Deleted");
      }
    }
  }


  const CallremoveSubCategoryFromBulk = async (id) => {
    const cnstRes = await DeleteSubCategory({ SubCatId: id });
    const CnstErrorMsg = cnstRes?.Error?.Message;
    if (CnstErrorMsg) {
      handleOpenSnackbar("Failed to load data, " + cnstRes?.Error?.Message)
      setLoading(false);
    } else {

      // const ImageId = (data.find(o => o.SubCatId === id)?.Image.id);
      // if (ImageId) {
      //   await (DeleteImage(({ RelatedId: ImageId ,RelatedType:"Categories" })))
      // }
      getSubCategoriesAPI();
    }
  }
  function removeBulkSubCategory(id) {
    const Confirmation = window.confirm("Do you want to Delete them?");
    if (Confirmation == true && id.selectionModel?.length > 0) {

      setLoading(true);
      handleOpenSnackbar("Sub-Category Deleted");
      let FirstId = id.selectionModel;
      if (FirstId) {
        FirstId.forEach(element =>

          CallremoveSubCategoryFromBulk(element));
      }
      setselectedSubCategories([]);
    }
  }
  const DeleteSubCateImageFromGird = async SubCatId => {
    const Confirmation = window.confirm("Do you want to Delete image?");
    if (Confirmation === true) {
      setLoading(true);
      const ImgId = (data.find(o => o.SubCatId === SubCatId)?.Image.id);
      if (ImgId) {
        await DeleteImage({ RelatedId: ImgId ,RelatedType:"Sub-Categories" });
        getSubCategoriesAPI();
        handleOpenSnackbar("Sub-Categories Image Deleted");
      }
      setLoading(false);
    }
  }
  const ButtonUpdateSubCategory = async Id => {
    handleClickOpenEditModal();
    setSubCategoryId_Edit(Id);
  }

  const columns = [
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 80,
      renderCell: (params) => { 
        return <Button size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => ButtonUpdateSubCategory(params?.value)} ></Button>
      }
    },
    { field: 'SubCatId', headerName: 'ID', width: 70, hide: true },
    { field: 'Category', headerName: 'Category', width: 250 },
    { field: 'SubCatNameFirstLang', headerName: 'Name English', width: 250 },
    { field: 'SubCatNameSecondlang', headerName: 'Name Sec Lang', width: 250 },
    { field: 'SubCatDescFirstLang', headerName: 'Description Eng', width: 250 },
    { field: 'SubCatDescSecondlang', headerName: 'Description SecLang', width: 250 },
      { field: 'SubCatOrderNbr', headerName: 'order', width: 110 },
      { field: 'IsSubCatVisible', headerName: 'Visible', width: 150 },
    {
      field: 'Image',
      headerName: 'Image',
      sortable: false,
      width: 250,
      renderCell: (params) => {
          const divStyle = {
              backgroundColor: 'rgb(36,90,118)', // Set the background color 
          };
          return <div style={divStyle}>
              {params.value?.uri?.includes('null') && !params.value?.uri?.includes('.')
                  ? <></>
                  : <img src={`${params.value.uri}`} alt="No IMG" width={50} height={50} />}
          </div>
      }
    },

    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <Button size="small" variant="contained" color="secondary" startIcon={<DeleteIcon />}

          onClick={() => { removeSubCategory(params.value) }} >
        </Button>
      }
    },
    {
      field: 'deleteimage',
      headerName: 'Delete Image',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return <Button size="small" style={{ backgroundColor: 'orange', color: 'white' }} startIcon={<DeleteForeverTwoToneIcon />}
              onClick={() => { DeleteSubCateImageFromGird(params.value) }} >
            </Button>
      }
    }
  ];
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'Category',
      sort: 'asc',
    },
  ]);

  const getRowId = (row) => row.SubCatId;
  return (
    <div style={{ height: 600, width: '100%' }}>
      <Helmet>
        <title>sub-Categories</title>
      </Helmet>

      <div
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        {loading && <CircularProgress size={75} thickness={5} style={{ 'color': 'black' }} />}
      </div>

      <DataGrid
        getRowId={getRowId}
        sortModel={sortModel}
        rows={data}
        columns={columns}
        pageSize={20}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          setselectedSubCategories(newSelectionModel);
        }}

        components={{
          Toolbar: () => <CustomToolbar ArrayOfIds={selectedSubCategories} />,
        }}
      />


      <DialogForSubCategory
        openModal={open}
        handleCloseModal={handleClose}
        getData={getSubCategoriesAPI}
        isUpdate={false}
        OpenSnackBarForMsg={handleOpenSnackbar}

      /> 
      {data[0] ?
        <DialogForSubCategory
          openModal={OpenEditModal}
          handleCloseModal={handleCloseEditModal}
          SubCategorieRow={data.find(o => o.SubCatId === SubCategoryId_Edit)}
          getData={getSubCategoriesAPI}
          isUpdate={true}
          OpenSnackBarForMsg={handleOpenSnackbar}
          CategorieIdfromGrid={data.find(o => o.SubCatId === SubCategoryId_Edit)?.CatId}
        /> : <></>
      }
      <Snackbar theme={{ colors: { surface: 'blue', accent: 'red' }, }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={Msg}
      />

    </div>
  );
}