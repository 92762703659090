import axios from 'axios'
import AppConfig from '../AppSettings';

export const GetRestInfoCMS = async (data) => {
    try { 

        const response = await fetch(AppConfig.apiUrl + `/RestInfo/GetRestInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json(); 

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const GetRestInfo = async (data) => {
    try {
        // Create a unique cache key based on the request parameters
        const cacheKey = `GetRestInfo_${JSON.stringify(data)}`;

        // Check if the data is already in the cache
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
            // Return cached data if available
            return JSON.parse(cachedData);
        }


        const response = await fetch(AppConfig.apiUrl + `/RestInfo/GetRestInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // Store the result in the sessionStorage cache
        sessionStorage.setItem(cacheKey, JSON.stringify(result));

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const InsertRestInfo = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/RestInfo/InsertRestInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const UpdateRestInfo = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/RestInfo/UpdateRestInfo`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    }
    catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const DeleteRestInfo = async (data) => {

    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/RestInfo/DeleteRestInfo`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}